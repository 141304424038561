import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Select, Space, Tag, Tooltip } from 'antd'
import EmptyContent from '../../components/EmptyContent'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import { ASSIGNEE_TYPE } from '../../constants'
import SelectPeoplePopup from '../../components/SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../../components/SelectGroupPopup/SelectGroupPopup'
import { blue, yellowPrimary } from '../../color'
import { BankOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { SelectList } from '../MissionCreatePage/MissionCreatePageStyled'
import { AvatarHasName } from '../../components/Common/Avatar'
import CheckboxTaskTagPicker from '../../components/Shared/CheckboxTaskTagPicker'

const { Option } = Select

const Search = props => {
  const {
    taskStore,
    taskLevelList,
    authenticationStore,
    dispatchUserFilter,
    selectPeopleStore,
    isVisibleCreateMissionPopup,
  } = props

  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
    assigneePopupType,
  } = selectPeopleStore

  const [leaderList, setLeaderList] = useState([])
  const [handlerList, setHandlerList] = useState([])
  const [combinerList, setCombinerList] = useState([])
  const [isVisibleSelectGroupPopup, setIsVisibleSelectGroupPopup] = useState(
    false
  )
  const [
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
  ] = useState(false)

  useEffect(() => {
    setLeaderList([
      ...selectUserData[ASSIGNEE_TYPE.LEADER],
      ...selectDepartmentData[ASSIGNEE_TYPE.LEADER],
      ...selectGroupData[ASSIGNEE_TYPE.LEADER],
    ])
    setHandlerList([
      ...selectUserData[ASSIGNEE_TYPE.HANDLER],
      ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER],
      ...selectGroupData[ASSIGNEE_TYPE.HANDLER],
    ])
    setCombinerList([
      ...selectUserData[ASSIGNEE_TYPE.COMBINER],
      ...selectDepartmentData[ASSIGNEE_TYPE.COMBINER],
      ...selectGroupData[ASSIGNEE_TYPE.COMBINER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const { missionQueryParams } = taskStore
  const { currentUser } = authenticationStore

  const location = useLocation()
  const history = useHistory()
  const queryStringParse = queryString.parse(location.search, {
    arrayFormat: 'index',
  })

  const onSelectCapGiao = async value => {
    taskStore.setMissionQueryParams({
      ...missionQueryParams,
      id_task_level: value,
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        id_task_level: value ? value : null,
      },
      {
        skipNull: true,
        arrayFormat: 'index',
      }
    )
    history.replace(`/mission?${queryStringStringify}`)
  }

  const onSelectTienDo = async value => {
    taskStore.setMissionQueryParams({
      ...missionQueryParams,
      tien_do: value,
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        tien_do: value ? value : null,
      },
      {
        skipNull: true,
        arrayFormat: 'index',
      }
    )
    history.replace(`/mission?${queryStringStringify}`)
  }

  const onSelectLoaiNhiemvu = value => {
    taskStore.setMissionQueryParams({
      ...missionQueryParams,
      loai_nhiem_vu: value,
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        loai_nhiem_vu: value ? value : null,
      },
      {
        skipNull: true,
        arrayFormat: 'index',
      }
    )
    history.replace(`/mission?${queryStringStringify}`)
  }

  const onChangeFilter = param => paramValue => {
    taskStore.setMissionQueryParams({
      ...missionQueryParams,
      [param]: paramValue,
    })

    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        [param]: paramValue ? paramValue : null,
      },
      {
        skipNull: true,
        arrayFormat: 'index',
      }
    )
    history.replace(`/mission?${queryStringStringify}`)
  }

  const handleSubmitSelect = () => {
    setIsVisibleSelectGroupPopup(false)
    setIsVisibleSelectUserDepartmentPopup(false)
    if (selectGroupData[assigneePopupType]?.length > 0) {
      dispatchUserFilter({
        type: assigneePopupType,
        value: selectGroupData[assigneePopupType][0].id,
        fullName: selectGroupData[assigneePopupType][0].full_name,
        assigneeType: ASSIGNEE_TYPE.GROUP,
      })
      return
    }
    if (selectUserData[assigneePopupType]?.length > 0) {
      dispatchUserFilter({
        type: assigneePopupType,
        value: selectUserData[assigneePopupType][0].id,
        fullName: selectUserData[assigneePopupType][0].full_name,
        assigneeType: ASSIGNEE_TYPE.USER,
      })
      return
    }
    if (selectDepartmentData[assigneePopupType]?.length > 0) {
      dispatchUserFilter({
        type: assigneePopupType,
        value: selectDepartmentData[assigneePopupType][0].id,
        fullName: selectDepartmentData[assigneePopupType][0].full_name,
        assigneeType: ASSIGNEE_TYPE.DEPARTMENT,
      })
      return
    }
    dispatchUserFilter({
      type: assigneePopupType,
      value: null,
      fullName: null,
      assigneeType: ASSIGNEE_TYPE.USER,
    })
  }

  return (
    <div style={{ marginBottom: 16 }}>
      <Row type={'flex'} gutter={[15, 5]}>
        <Col xs={24} sm={24} md={8} xl={8} xxl={3}>
          <div
            style={{
              marginBottom: 8,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <span>Lãnh đạo giao việc:</span>
            <Space>
              <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                <Button
                  icon={<TeamOutlined />}
                  style={{ color: yellowPrimary }}
                  onClick={() => {
                    setIsVisibleSelectGroupPopup(true)
                    handleOpenSelectGroupPopup(ASSIGNEE_TYPE.LEADER)
                  }}
                />
              </Tooltip>
            </Space>
          </div>
          {!isVisibleCreateMissionPopup && (
            <SelectList>
              <div style={{ marginBottom: 8 }}>
                {leaderList.map(el => (
                  <Tag
                    className={'tag-selected'}
                    color={'blue'}
                    key={el.id}
                    closable
                    onClose={() => {
                      dispatchUserFilter({
                        type: el.permission,
                        value: null,
                        fullName: null,
                        assigneeType: null,
                      })
                      selectPeopleStore.handleRemoveSelect(el)
                    }}>
                    <AvatarHasName
                      imgId={el.image_id}
                      size={22}
                      name={el.full_name}
                      icon={
                        (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                          <BankOutlined />
                        )) ||
                        (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                          <TeamOutlined />
                        ))
                      }
                    />
                  </Tag>
                ))}
              </div>
            </SelectList>
          )}
        </Col>
        {(queryStringParse.PIC || queryStringParse.COOR) ===
        currentUser?.username ? null : (
          <>
            <Col xs={24} sm={24} md={8} xl={8} xxl={3}>
              <div
                style={{
                  marginBottom: 8,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <span>Xử lý chính:</span>
                <Space>
                  <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                    <Button
                      icon={<TeamOutlined />}
                      style={{ color: yellowPrimary }}
                      onClick={() => {
                        setIsVisibleSelectGroupPopup(true)
                        handleOpenSelectGroupPopup(ASSIGNEE_TYPE.HANDLER)
                      }}
                    />
                  </Tooltip>
                </Space>
              </div>
              {!isVisibleCreateMissionPopup && (
                <SelectList>
                  <div style={{ marginBottom: 8 }}>
                    {handlerList.map(el => (
                      <Tag
                        className={'tag-selected'}
                        color={'blue'}
                        key={el.id}
                        closable
                        onClose={() => {
                          dispatchUserFilter({
                            type: el.permission,
                            value: null,
                            fullName: null,
                            assigneeType: null,
                          })
                          selectPeopleStore.handleRemoveSelect(el)
                        }}>
                        <AvatarHasName
                          imgId={el.image_id}
                          size={22}
                          name={el.full_name}
                          icon={
                            (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                              <BankOutlined />
                            )) ||
                            (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                              <TeamOutlined />
                            ))
                          }
                        />
                      </Tag>
                    ))}
                  </div>
                </SelectList>
              )}
            </Col>
            <Col xs={24} sm={24} md={8} xl={8} xxl={4}>
              <div
                style={{
                  marginBottom: 8,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <span>Phối hợp xử lý:</span>
                <Space>
                  <Tooltip title="Chọn nhóm" color={'#ffc069'}>
                    <Button
                      icon={<TeamOutlined />}
                      style={{ color: yellowPrimary }}
                      onClick={() => {
                        setIsVisibleSelectGroupPopup(true)
                        handleOpenSelectGroupPopup(ASSIGNEE_TYPE.COMBINER)
                      }}
                    />
                  </Tooltip>
                </Space>
              </div>
              {!isVisibleCreateMissionPopup && (
                <SelectList>
                  <div style={{ marginBottom: 8 }}>
                    {combinerList.map(el => (
                      <Tag
                        className={'tag-selected'}
                        color={'blue'}
                        key={el.id}
                        closable
                        onClose={() => {
                          dispatchUserFilter({
                            type: el.permission,
                            value: null,
                            fullName: null,
                            assigneeType: null,
                          })
                          selectPeopleStore.handleRemoveSelect(el)
                        }}>
                        <AvatarHasName
                          imgId={el.image_id}
                          size={22}
                          name={el.full_name}
                          icon={
                            (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                              <BankOutlined />
                            )) ||
                            (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                              <TeamOutlined />
                            ))
                          }
                        />
                      </Tag>
                    ))}
                  </div>
                </SelectList>
              )}
            </Col>
          </>
        )}
        <Col xs={24} sm={24} md={6} xl={6} xxl={5}>
          <div
            style={{
              width: '100%',
              marginBottom: 10,
              display: 'flex',
              alignItems: 'center',
            }}>
            {/* <div style={{ width: 80 }}>Tiến độ:</div> */}
            <Select
              placeholder={'-- Lọc theo tiến độ --'}
              style={{ width: '100%', flexGrow: 1 }}
              allowClear
              defaultValue={queryStringParse.tien_do}
              onChange={onSelectTienDo}>
              <Option value="001">Hoàn thành</Option>
              <Option value="002">Chưa hoàn thành</Option>
            </Select>
          </div>
        </Col>
        <Col xs={24} sm={24} md={6} xl={6} xxl={5}>
          <div
            style={{
              width: '100%',
              marginBottom: 10,
              display: 'flex',
              alignItems: 'center',
            }}>
            {/* <div style={{ width: 80 }}>Cấp giao:</div> */}
            <Select
              style={{ width: '100%', flexGrow: 1 }}
              notFoundContent={<EmptyContent />}
              defaultValue={queryStringParse.id_task_level}
              placeholder={'-- Chọn cấp giao nhiệm vụ --'}
              allowClear
              onChange={onSelectCapGiao}>
              {taskLevelList?.map(taskLevel => (
                <Option key={taskLevel.id_level} value={taskLevel.id_level}>
                  {taskLevel.ten_cap}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        {/* <Col xs={24} sm={24} md={6} xl={6} xxl={4}>
          <div
            style={{
              width: '100%',
              marginBottom: 10,
              display: 'flex',
              alignItems: 'center',
            }}> */}
        {/* <span style={{ width: 80 }}>Loại:</span> */}
        {/* <MissionTypePicker
                defaultValue={queryStringParse.loai_nhiem_vu}
                onChangePicker={onChangeFilter('loai_nhiem_vu')}
              />
          </div>
        </Col> */}

        {/*<Col xs={24} sm={24} md={6} xl={6} xxl={4}>*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      width: '100%',*/}
        {/*      marginBottom: 10,*/}
        {/*      display: 'flex',*/}
        {/*      alignItems: 'center',*/}
        {/*    }}>*/}
        {/*    <TaskTagPicker*/}
        {/*        triggerChangeOnBlur*/}
        {/*        defaultValue={queryStringParse.tag}*/}
        {/*        onChangePicker={onChangeFilter('tag')}*/}
        {/*      />*/}
        {/*  </div>*/}
        {/*</Col>*/}
      </Row>

      <Row>
        <CheckboxTaskTagPicker
          defaultValue={queryStringParse.tag}
          onChangePicker={onChangeFilter('tag')}
        />
      </Row>

      <SelectPeoplePopup
        onlySelectOne
        handleCancelSelectUser={() => {
          setIsVisibleSelectUserDepartmentPopup(false)
          // handleCancelSelectUserDepartment()
        }}
        handleSubmitSelectUser={handleSubmitSelect}
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />

      <SelectGroupPopup
        onlySelectOne
        groupType={'nhiem_vu'}
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={() => {
          setIsVisibleSelectGroupPopup(false)
          // handleCancelSelectGroup()
        }}
        handleSubmitSelectGroup={handleSubmitSelect}
      />
    </div>
  )
}

Search.propTypes = {}

export default inject(
  'taskStore',
  'departmentStore',
  'loadingAnimationStore',
  'authenticationStore',
  'selectPeopleStore',
)(observer(Search))
