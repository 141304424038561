import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { LoadingOutlined } from '@ant-design/icons'

import { ListHistoryLogModalWrapper } from './ListHistoryLogModalStyled'
import { inject, observer } from 'mobx-react'
import common_history_log from '../../enum_utils'
import date_format from '../../date_format'
import DiffTable from './DiffTable/DiffTable'
import utils from '../../utils'

const ListHistoryLogModal = props => {
  const {
    type,
    taskInfo,
    isVisible,
    handleCloseHistory,
    listHistoryLogStore,
    riskDetail
  } = props

  const { list, isLoading, message } = listHistoryLogStore

  useEffect(() => {
    if (!taskInfo.code) {
      return
    }

    try {
      listHistoryLogStore.getList(taskInfo.code)
    } catch (error) {
      console.log(error)
      message.error(error?.vi || 'Đã có lỗi xảy ra')
    }

    return () => {
      listHistoryLogStore.clear()
    }
  }, [taskInfo])

  return (
    <>
      <ListHistoryLogModalWrapper
        title={'Lịch sử'}
        width={1300}
        style={{ top: 40 }}
        visible={isVisible}
        closable={true}
        maskClosable={true}
        onCancel={handleCloseHistory}
        footer={null}>
        <div>
          {isLoading && (
            <LoadingOutlined style={{ marginLeft: 10 }} spin={true} />
          )}
          {message && <p>{message}</p>}
          {
            <ul>
              {list &&
                list?.map(item => {
                  return (
                    <li key={item.id} style={{ marginBottom: 16 }}>
                      <span style={{ marginBottom: 8, display: 'block' }}>
                        <b>{common_history_log.enumToText(item.action)}</b> lúc
                        <span>
                          {' '}
                          {date_format.HH_mm_DD_MM_YYYY(item.processed_date)}
                        </span>{' '}
                        bởi
                        <b> {utils.getNameInCapitalize(item.user.name_uppercase)}</b>
                      </span>
                      <DiffTable
                        riskDetail={riskDetail}
                        key={item.id}
                        dataBefore={item.data_before}
                        dataAfter={item.data_after}
                        type={type}
                      />
                    </li>
                  )
                })}
            </ul>
          }
        </div>
      </ListHistoryLogModalWrapper>
    </>
  )
}

ListHistoryLogModal.propTypes = {
  type: PropTypes.string,
  isVisible: PropTypes.bool,
  handleCloseHistory: PropTypes.func,
  taskInfo: PropTypes.any,
}

export default inject('listHistoryLogStore')(observer(ListHistoryLogModal))
