import { Button, Checkbox, Divider } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { CheckboxWrapper, RiskActionStyle } from './RiskActionDetailsStyled'
import queryString from 'query-string'
import { RISK_TYPE } from '../../constants'
import utils from '../../utils'
import RichText from '../RichEditor/RichText'

const labelBold = { fontWeight: 600 }
const textValue = { fontStyle: 'italic', marginLeft: 8 }

const ActionLabel = ({ label = '', text = '', labelStyle = {} }) => {
  return (
    <p>
      <span style={labelStyle}>{label}</span>
      <span style={textValue}>{text}</span>
    </p>
  )
}
const actionBoldLabel = (label, content) => {
  return (
    <p>
      <span style={labelBold}>{label}&nbsp;</span>
      <span style={textValue}>{content}</span>
    </p>
  )
}

const TableHasCheckbox = ({ title, checked }) => {
  return (
    <table style={{ margin: '0 ' }}>
      <tr style={{ backgroundColor: 'white', margin: '0 ' }}>
        <td
          style={{
            border: 'none',
            margin: '0 ',
            backgroundColor: 'white',
            textAlign: 'center',
          }}>
          <div>{title}</div>
        </td>
        <td
          style={{
            border: 'none',
            margin: '0 ',
            backgroundColor: 'white',
            textAlign: 'center',
          }}>
          {checked ? (
            <image
              id="logo"
              style={{ width: '20px', height: '16px' }}
              src={`${window.location.protocol}//${window.location.host}/assets/icons/checkedbox.PNG`}
            />
          ) : (
            <image
              id="logo"
              style={{ width: '20px', height: '16px' }}
              src={`${window.location.protocol}//${window.location.host}/assets/icons/uncheckedbox.PNG`}
            />
          )}
        </td>
      </tr>
    </table>
  )
}

const showAgree = data => {
  return data === true ? 'Đồng ý' : data === false ? 'Không đồng ý' : ''
}

const displayRiskAssessment = value => {
  return value === '1'
    ? '1 -> 5 - Nguy cơ rủi ro thấp (Được phép thực hiện)'
    : value === '2'
    ? ' 6 -> 15 - Nguy cơ rủi ro trung bình (Hoạt động chỉ được phép tiến hành với sự quản lý, kiểm soát thích hợp)'
    : value === '3'
    ? ' 16 -> 25 - Nguy cơ rủi ro cao (Hoạt động không được phép tiến hành. Cần phải thiết lập các biện pháp kiểm soát chặt chẽ hơn để giảm thiểu rủi ro)'
    : ''
}

const displayResult = value => {
  return value === '1'
    ? 'a/ Rủi ro ở mức không cần xử lý (theo dõi)'
    : value === '2'
    ? 'b/ Rủi ro đã được xử lý'
    : value === '3'
    ? 'c/ Rủi ro đã xảy ra chưa được xử lý'
    : ''
}

const RiskActionDetails = ({
  data,
  tier,
  isPartOfPdf = false,
  openEditPopUp = () => {},
  actionStore,
}) => {
  const { setIndexDetailAction } = actionStore
  const queryStringParse = queryString.parse(location.search)
  const labelBold = { fontWeight: 600 }
  const textValue = { fontStyle: 'italic', marginLeft: 8 }
  const styleTable = isPartOfPdf
    ? {
        boder: 'none',
        backgroundColor: 'white',
        margin: '16px 0 50px',
      }
    : {
        margin: 0,
        width: '100%',
      }

  const webTableCellStyle = totalColumn => {
    return {
      width: `${100 / totalColumn}%`,
      textAlign: 'center',
    }
  }

  const riskActionDetailsTier1 = () => {
    return (
      <RiskActionStyle>
        <h6
          style={
            (isPartOfPdf && {
              marginLeft: 24,
              fontStyle: 'italic ',
              marginTop: 8,
            }) ||
            {}
          }>
          Đối tượng thực hiện:&nbsp;
        </h6>
        {utils.checkRichType(
          queryStringParse.type,
          <ActionLabel
            label="(1) NĐDPV của VIMC tại DNTV (trong trường hợp giao NĐDPV thực hiện công việc):&nbsp;"
            text={data[0]?.doi_tuong_thuc_hien?.nddpv_cua_vimc_tai_dntv}
            labelStyle={labelBold}
          />,
          <ActionLabel
            label="(2) ĐVPT của VIMC (trong trường hợp ĐVPT thực hiện công việc):&nbsp;"
            text={data[0]?.doi_tuong_thuc_hien?.dvpt_cua_vimc}
            labelStyle={labelBold}
          />,
          actionBoldLabel(
            '(3) Các Đơn vị thuộc cơ quan Văn phòng của VIMC (trong trường hợp Văn phòng cơ quan/ các Ban CMNV/TT CNTT thực hiện công việc):',
            data[0]?.doi_tuong_thuc_hien?.cac_don_vi_thuoc_cq_vp_cua_vimc
          )
        )}
        <Divider />
        <h6>I. NHẬN DIỆN RỦI RO VÀ ĐÁNH GIÁ RỦI RO</h6>
        <h6>1. Công việc/lĩnh vực hoạt động</h6>
        {isPartOfPdf ? (
          <>
            <TableHasCheckbox
              title="a/ Công việc thuộc chức năng, nhiệm vụ:"
              checked={
                data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                  ?.cong_viec_linh_vuc_hoat_dong.cong_viec_theo ===
                'cong_viec_thuoc_chuc_nang_nhiem_vu'
              }
            />
            <TableHasCheckbox
              title="b/ Công việc theo KPI/KRI:"
              checked={
                data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                  ?.cong_viec_linh_vuc_hoat_dong.cong_viec_theo ===
                'cong_viec_theo_kpi_kri'
              }
            />
            <TableHasCheckbox
              title="c/ Công việc theo mục tiêu, chiến lược:"
              checked={
                data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                  ?.cong_viec_linh_vuc_hoat_dong.cong_viec_theo ===
                'cong_viec_theo_muc_tieu_chien_luoc'
              }
            />
          </>
        ) : (
          <>
            {' '}
            <p>
              <span style={{ marginRight: 8 }}>
                a/ Công việc thuộc chức năng, nhiệm vụ:
              </span>

              <CheckboxWrapper>
                <Checkbox
                  checked={
                    data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                      ?.cong_viec_linh_vuc_hoat_dong.cong_viec_theo ===
                    'cong_viec_thuoc_chuc_nang_nhiem_vu'
                  }></Checkbox>
              </CheckboxWrapper>
            </p>
            <p>
              <span style={{ marginRight: 8 }}>b/ Công việc theo KPI/KRI:</span>

              <CheckboxWrapper>
                <Checkbox
                  checked={
                    data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                      ?.cong_viec_linh_vuc_hoat_dong.cong_viec_theo ===
                    'cong_viec_theo_kpi_kri'
                  }></Checkbox>
              </CheckboxWrapper>
            </p>
            <p>
              <span style={{ marginRight: 8 }}>
                c/ Công việc theo mục tiêu, chiến lược:
              </span>
              <CheckboxWrapper>
                <Checkbox
                  checked={
                    data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                      ?.cong_viec_linh_vuc_hoat_dong.cong_viec_theo ===
                    'cong_viec_theo_muc_tieu_chien_luoc'
                  }></Checkbox>
              </CheckboxWrapper>
            </p>
          </>
        )}
        <p style={{ marginLeft: isPartOfPdf ? '6px' : 0 }}>
          <span style={{ marginRight: 8 }}>
            d/ Nội dung công việc khác không thuộc các nhóm trên:&nbsp;
          </span>
          <span style={textValue}>
            {
              data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                ?.cong_viec_linh_vuc_hoat_dong
                ?.noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren
            }
          </span>
        </p>

        <h6>2. Công việc thực hiện</h6>
        <p>
          <span>a/ Nội dung công việc:</span>
          <span>
            <RichText
              htmlText={
                data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                  ?.cong_viec_thuc_hien?.noi_dung_cong_viec || ''
              }></RichText>
          </span>
        </p>
        <ActionLabel
          label="b/ Người thực hiện:&nbsp;"
          text={
            data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.cong_viec_thuc_hien
              ?.nguoi_thuc_hien
          }
        />
        <ActionLabel
          label="c/ Thời gian hoàn thành:&nbsp;"
          text={
            data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro?.cong_viec_thuc_hien
              ?.thoi_gian_hoan_thanh
          }
        />

        <h6>3. Nhận diện rủi ro và đánh giá rủi ro</h6>
        <p>
          <span>a/ Nhận diện rủi ro:</span>
          <span>
            <RichText
              htmlText={
                data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                  ?.nhan_dien_rui_ro_va_danh_gia_rui_ro?.nhan_dien_rui_ro || ''
              }></RichText>
          </span>
        </p>
        <p>
          <span>b/ Xây dựng giải pháp giảm thiểu, phòng ngừa:</span>
          <span>
            <RichText
              htmlText={
                data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                  ?.nhan_dien_rui_ro_va_danh_gia_rui_ro
                  ?.xay_dung_giai_phap_giam_thieu_phong_ngua || ''
              }></RichText>
          </span>
        </p>
        <ActionLabel
          label="c/ Đánh giá rủi ro:&nbsp;"
          text={displayRiskAssessment(
            data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
              ?.nhan_dien_rui_ro_va_danh_gia_rui_ro?.r || ''
          )}
        />

        {isPartOfPdf ? (
          <table style={styleTable}>
            <tr style={{ backgroundColor: 'white' }}>
              <td
                style={{
                  border: 'none',
                  margin: '0 50px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                <div>
                  <b>Người thực hiện </b>
                </div>
              </td>
              <td
                style={{
                  border: 'none',
                  margin: '0 50px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                <div>
                  <b>Lãnh đạo Đơn vị/NĐDPV phụ trách chung </b>
                </div>
              </td>
            </tr>
            <tr style={{ backgroundColor: 'white' }}>
              <td
                style={{
                  border: 'none',
                  margin: '0 50px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                {
                  data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                    ?.nguoi_thuc_hien
                }
              </td>
              <td
                style={{
                  border: 'none',
                  margin: '0 50px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                {utils.getNameInCapitalize(
                  data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                    ?.lanh_dao_don_vi_nddpv_phu_trach_chung[0]?.full_name
                )}
              </td>
            </tr>
          </table>
        ) : (
          <table style={styleTable}>
            <tr style={{ backgroundColor: 'white' }}>
              <td style={webTableCellStyle(2)}>
                <div>
                  <b>Người thực hiện </b>
                </div>
              </td>
              <td style={webTableCellStyle(2)}>
                <div>
                  <b>Lãnh đạo Đơn vị/NĐDPV phụ trách chung </b>
                </div>
              </td>
            </tr>
            <tr style={{ backgroundColor: 'white' }}>
              <td style={webTableCellStyle(2)}>
                {
                  data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                    ?.nguoi_thuc_hien
                }
              </td>
              <td style={webTableCellStyle(2)}>
                {utils.getNameInCapitalize(
                  data[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                    ?.lanh_dao_don_vi_nddpv_phu_trach_chung[0]?.full_name
                )}
              </td>
            </tr>
          </table>
        )}

        <Divider />

        <h6>II. XỬ LÝ RỦI RO</h6>
        <h6>1. Đề xuất loại rủi ro cần xử lý </h6>
        <ActionLabel
          label="a/ Loại rủi ro đề xuất:&nbsp;"
          text={displayRiskAssessment(
            data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
              ?.loai_rui_ro_de_xuat
          )}
          labelStyle={labelBold}
        />
        <ActionLabel
          label="b/ Bộ phận đề xuất:&nbsp;"
          text={
            data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
              ?.bo_phan_de_xuat
          }
          labelStyle={labelBold}
        />
        <ActionLabel
          label="c/ Biện pháp xử lý:&nbsp;"
          text={
            <RichText
              htmlText={
                data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
                  ?.bien_phap_xu_ly || ''
              }
            />
          }
          labelStyle={labelBold}
        />
        <ActionLabel
          label="d/ Người thực hiện:&nbsp;"
          text={
            data[0]?.II_xu_ly_rui_ro?.de_xuat_loai_rui_ro_can_xu_ly
              ?.nguoi_thuc_hien
          }
          labelStyle={labelBold}
        />

        <Divider />

        <h6>2. Lãnh đạo quyết định biện pháp xử lý</h6>

        <ActionLabel
          label="Lãnh đạo:&nbsp;"
          text={data[0]?.II_xu_ly_rui_ro?.lanh_dao_quyet_dinh_bien_phap_xu_ly}
        />

        <Divider />
        {isPartOfPdf ? (
          <table style={styleTable}>
            <tr style={{ backgroundColor: 'white' }}>
              <td
                style={{
                  border: 'none',
                  margin: '0 50px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                <div>
                  <b>ĐƠN VỊ THỰC HIỆN XỬ LÝ RỦI RO</b>
                  <div style={{ marginTop: 10 }}>
                    {data[0]?.II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro}
                  </div>
                </div>
              </td>
              <td
                style={{
                  border: 'none',
                  margin: '0 50px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                <div>
                  <b>
                    LÃNH ĐẠO VIMC
                    <br />
                    (theo phân công trách nhiệm quản lý rủi ro)
                  </b>
                  <div style={{ marginTop: 10 }}>
                    {utils.getNameInCapitalize(
                      data[0]?.II_xu_ly_rui_ro?.lanh_dao_vimc[0]?.full_name
                    )}
                  </div>
                </div>
              </td>
            </tr>
          </table>
        ) : (
          <table style={styleTable}>
            <tr style={{ backgroundColor: 'white' }}>
              <td style={webTableCellStyle(2)}>
                <b>ĐƠN VỊ THỰC HIỆN XỬ LÝ RỦI RO</b>
              </td>
              <td style={webTableCellStyle(2)}>
                <b>
                  LÃNH ĐẠO VIMC
                  <br />
                  (theo phân công trách nhiệm quản lý rủi ro)
                </b>
              </td>
            </tr>
            <tr style={{ backgroundColor: 'white' }}>
              <td style={webTableCellStyle(2)}>
                {data[0]?.II_xu_ly_rui_ro?.don_vi_thuc_hien_xu_ly_rui_ro}
              </td>
              <td style={webTableCellStyle(2)}>
                {utils.getNameInCapitalize(
                  data[0]?.II_xu_ly_rui_ro?.lanh_dao_vimc[0]?.full_name
                )}
              </td>
            </tr>
          </table>
        )}
      </RiskActionStyle>
    )
  }

  const riskActionDetailsTier2 = () => {
    return (
      <RiskActionStyle>
        <h6
          style={
            (isPartOfPdf && {
              marginLeft: 24,
              fontStyle: 'italic ',
              marginTop: 8,
            }) ||
            {}
          }>
          Đối tượng thực hiện:&nbsp;
        </h6>
        <p>
          <span style={labelBold}>
            (1) Ban PC&QTRR VIMC (trong trường hợp các Ban CMNV thực hiện công
            việc):&nbsp;
          </span>
          <span style={textValue}>
            {data[0]?.doi_tuong_thuc_hien?.ban_pc_qtrr_vimc}
          </span>
        </p>
        <p>
          <span style={labelBold}>
            (2) Ban CMNV VIMC (trong trường hợp giao NĐDPV, ĐVPT thực hiện công
            việc; các Ban cmnv chủ trì):&nbsp;
          </span>
          <span style={textValue}>
            {data[0]?.doi_tuong_thuc_hien?.ban_cmnv_vimc}
          </span>
        </p>

        <Divider />

        <h6>III. KIỂM SOÁT RỦI RO</h6>
        <p>
          <span style={labelBold}>
            1. Đối với các loại rủi ro đã nhận diện:&nbsp;
          </span>
          <span style={textValue}>
            {data[0]?.III_kiem_soat_rui_ro?.doi_voi_cac_loai_rui_ro_da_nhan_dien
              ?.dong_y
              ? 'Đồng ý'
              : 'Không đồng ý'}
          </span>
          <span style={textValue}>
            {
              data[0]?.III_kiem_soat_rui_ro
                ?.doi_voi_cac_loai_rui_ro_da_nhan_dien?.dieu_chinh_bo_sung
            }
          </span>
        </p>
        <p>
          <span style={labelBold}>2. Đối với đánh giá mức rủi ro:&nbsp;</span>
          <span style={textValue}>
            {data[0]?.III_kiem_soat_rui_ro.doi_voi_danh_gia_muc_rui_ro?.dong_y
              ? 'Đồng ý'
              : 'Không đồng ý'}
          </span>
          <span style={textValue}>
            {
              data[0]?.III_kiem_soat_rui_ro?.doi_voi_danh_gia_muc_rui_ro
                ?.dieu_chinh_bo_sung
            }
          </span>
        </p>
        <p>
          <span style={labelBold}>3. Loại rủi ro đã đề xuất xử lý:&nbsp;</span>
          <span style={textValue}>
            {data[0]?.III_kiem_soat_rui_ro.loai_rui_ro_da_de_xuat_xu_ly
              ? 'Đồng ý'
              : 'Không đồng ý'}
          </span>
        </p>
        <p>
          <span style={labelBold}>
            4. Đánh giá biện pháp xử lý đã đề xuất:&nbsp;
          </span>
          <span style={textValue}>
            {data[0]?.III_kiem_soat_rui_ro.danh_gia_bien_phap_xu_ly_da_de_xuat
              ? 'Tuân thủ'
              : 'Không tuân thủ'}
          </span>
        </p>
        <p>
          <span style={labelBold}>
            5. Thời gian hoàn thành công việc được giao:&nbsp;
          </span>
          <span style={textValue}>
            {data[0]?.III_kiem_soat_rui_ro
              .thoi_gian_hoan_thanh_cong_viec_duoc_giao
              ? 'Đúng thời hạn'
              : 'Không đúng thời hạn'}
          </span>
        </p>
        <p>
          <span style={labelBold}>
            6. Ý kiến khác của Ban PC&QTRR/Ban CMNV VIMC:&nbsp;
          </span>
          <span style={textValue}>
            {
              data[0]?.III_kiem_soat_rui_ro
                .y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc
            }
          </span>
        </p>

        <h6 style={{ textAlign: 'center' }}>BAN PC&QTRR VIMC/ BAN CMNV VIMC</h6>
        <table style={styleTable}>
          <tr style={{ backgroundColor: 'white' }}>
            <td
              style={{
                border: 'none',
                margin: '0 50px ',
                backgroundColor: 'white',
                textAlign: 'center',
              }}>
              <div style={{ margin: '0 20px' }}>
                <b>Chuyên viên thực hiện</b>
              </div>
            </td>
            <td
              style={{
                border: 'none',
                margin: '0 50px ',
                backgroundColor: 'white',
                textAlign: 'center',
              }}>
              <div style={{ margin: '0 20px' }}>
                <b>Lãnh đạo ban</b>
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: 'none',
                margin: '0 50px ',
                backgroundColor: 'white',
                textAlign: 'center',
              }}>
              <div style={{ margin: '0 20px' }}>
                {data[0]?.III_kiem_soat_rui_ro.chuyen_vien_thuc_hien}
              </div>
            </td>
            <td
              style={{
                border: 'none',
                margin: '0 50px ',
                backgroundColor: 'white',
                textAlign: 'center',
              }}>
              <div style={{ margin: '0 20px' }}>
                {data[0]?.III_kiem_soat_rui_ro.lanh_dao_ban}
              </div>
            </td>
          </tr>
        </table>
      </RiskActionStyle>
    )
  }
  const riskActionDetailsTier3 = () => {
    return (
      <RiskActionStyle>
        <h6
          style={
            (isPartOfPdf && {
              marginLeft: 24,
              fontStyle: 'italic ',
              marginTop: 8,
            }) ||
            {}
          }>
          Đối tượng thực hiện:
        </h6>
        <p>
          <span style={labelBold}>
            (1) Tổng giám đốc, các Phó Tổng giám đốc:&nbsp;
          </span>
          <span style={textValue}>
            {data[0]?.doi_tuong_thuc_hien?.tong_giam_doc_cac_pho_tong_giam_doc}
          </span>
        </p>
        <p>
          <span style={labelBold}>
            {/* (2) ĐVPT của VIMC (trong trường hợp ĐVPT thực hiện công việc): */}
            (2) Ban KT-KTNB (tham mưu đánh giá độc lập cho HĐQT):&nbsp;
          </span>
          <span style={textValue}>
            {utils.getNameInCapitalize(
              data[0]?.doi_tuong_thuc_hien?.ban_kt_ktnb[0]?.full_name
            )}
          </span>
        </p>
        <Divider />
        <h6>IV: KẾT QUẢ RÀ SOÁT RỦI RO</h6>
        <p>
          <span>
            1. Đồng ý với đánh giá rủi ro/xử lý rủi ro ở tầng phòng vệ thứ nhất
            và thứ hai:&nbsp;
          </span>
          <span style={textValue}>
            {showAgree(
              data[0]?.IV_ket_qua_ra_soat_rui_ro
                ?.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai
            )}
          </span>
        </p>
        <p>
          <span>2. Kiến nghị giải pháp khắc phục:&nbsp;</span>
          <span style={textValue}>
            {data[0]?.IV_ket_qua_ra_soat_rui_ro?.kien_nghi_giai_phap_khac_phuc}
          </span>
        </p>

        {isPartOfPdf ? (
          <table style={styleTable}>
            <tr style={{ backgroundColor: 'white' }}>
              <td
                style={{
                  border: 'none',
                  margin: '0 100px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                <div>
                  <b> BAN KT-KTNB VIMC</b>
                  <div>
                    {data[0]?.IV_ket_qua_ra_soat_rui_ro?.ban_kt_ktnb_vimc}
                  </div>
                </div>
              </td>
              <td
                style={{
                  border: 'none',
                  margin: '0 78px ',
                  backgroundColor: 'white',
                  textAlign: 'center',
                }}>
                <div>
                  <b>
                    HĐQT VIMC
                    <br />
                    (CT/TV HĐQT)
                  </b>
                  <div>{data[0]?.IV_ket_qua_ra_soat_rui_ro?.hdqt_vimc}</div>
                </div>
              </td>
            </tr>
          </table>
        ) : (
          <table style={styleTable}>
            <tr style={{ backgroundColor: 'white' }}>
              <td style={webTableCellStyle(2)}>
                <b> BAN KT-KTNB VIMC</b>
              </td>
              <td style={webTableCellStyle(2)}>
                <b>
                  HĐQT VIMC
                  <br />
                  (CT/TV HĐQT)
                </b>
              </td>
            </tr>
            <tr style={{ backgroundColor: 'white' }}>
              <td style={webTableCellStyle(2)}>
                {data[0]?.IV_ket_qua_ra_soat_rui_ro?.ban_kt_ktnb_vimc}
              </td>
              <td style={webTableCellStyle(2)}>
                {data[0]?.IV_ket_qua_ra_soat_rui_ro?.hdqt_vimc}
              </td>
            </tr>
          </table>
        )}
      </RiskActionStyle>
    )
  }
  const riskActionDetailsTier4 = () => {
    return (
      <RiskActionStyle>
        <h6 style={{ marginTop: isPartOfPdf ? 16 : 0 }}>
          V. KẾT QUẢ KIỂM SOÁT RỦI RO
        </h6>
        <h6 style={{ display: 'inline-block' }}>1. Kết quả:&nbsp;</h6>
        <span style={textValue}>{displayResult(data[0]?.ket_qua)}</span>
        <h6>2. Các ý kiến liên quan</h6>
        <ActionLabel
          label="Ý KIẾN:&nbsp;"
          text=<RichText
            htmlText={data[0]?.cac_y_kien_lien_quan || ''}></RichText>
          labelStyle={labelBold}
        />
        <table style={styleTable}>
          <tr style={{ backgroundColor: 'white' }}>
            <td
              style={{
                border: 'none',
                margin: '0 20px ',
                backgroundColor: 'white',
                textAlign: 'center',
              }}>
              <div style={{ margin: '0 20px' }}>
                <b>ĐƠN VỊ THỰC HIỆN XỬ LÝ RỦI RO</b>
              </div>
            </td>
            <td
              style={{
                border: 'none',
                margin: '0 20px ',
                backgroundColor: 'white',
                textAlign: 'center',
              }}>
              <div style={{ margin: '0 20px' }}>
                <b>BAN PC&QTRR/BAN KT-KTNB VIMC</b>
              </div>
            </td>
            <td
              style={{
                border: 'none',
                margin: '0 20px ',
                backgroundColor: 'white',
                textAlign: 'center',
              }}>
              <div style={{ margin: '0 20px' }}>
                <b>BAN ĐIỀU HÀNH/HĐQT VIMC</b>
              </div>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: 'none',
                margin: '0 20px ',
                backgroundColor: 'white',
                textAlign: 'center',
              }}>
              <div style={{ margin: '0 20px' }}>
                {
                  actionStore?.dataActions?.tier1[0]?.II_xu_ly_rui_ro
                    .don_vi_thuc_hien_xu_ly_rui_ro
                }
              </div>
            </td>
            <td
              style={{
                border: 'none',
                margin: '0 20px ',
                backgroundColor: 'white',
                textAlign: 'center',
              }}>
              <div style={{ margin: '0 20px' }}>
                {utils.getNameInCapitalize(
                  data[0]?.ban_pc_qtrr_ban_kt_ktnb_vimc[0]?.full_name
                )}
              </div>
            </td>
            <td
              style={{
                border: 'none',
                margin: '0 20px ',
                backgroundColor: 'white',
                textAlign: 'center',
              }}>
              <div style={{ margin: '0 20px' }}>
                {utils.getNameInCapitalize(
                  data[0]?.ban_dieu_hanh_hdqt_vimc[0]?.full_name
                )}
              </div>
            </td>
          </tr>
        </table>
      </RiskActionStyle>
    )
  }

  return (
    <div>
      {data && tier === '1' ? riskActionDetailsTier1() : <></>}
      {data && tier === '2' ? riskActionDetailsTier2() : <></>}
      {data && tier === '3' ? riskActionDetailsTier3() : <></>}
      {data && tier === '4' ? riskActionDetailsTier4() : <></>}

      {!isPartOfPdf && (
        <Button
          style={{ marginTop: 16 }}
          onClick={() => {
            setIndexDetailAction({ tier, index: null })

            openEditPopUp()
          }}>
          Sửa
        </Button>
      )}
    </div>
  )
}

export default inject('actionStore')(observer(RiskActionDetails))
