import React, { useEffect, useRef, useState } from 'react'
// Component
import { EmptyText, WordBreak } from '../../../components/Common/CellText'
// Style Component
import {
  ButtonCloseDrawer,
  CommentTitle,
  ContentWrapper,
  DrawerWrapper,
  HeaderDrawer,
  InfoDrawer,
  InfoDrawerLeft,
  InfoDrawerLeftWrapper,
} from './DrawerWorkStyled'
// Ant design
import { Col, message, Popconfirm, Row, Tag, Tooltip } from 'antd'
import {
  ContainerOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  LoadingOutlined,
  StarFilled,
  StarTwoTone,
} from '@ant-design/icons'
// Other
import {
  ACL_ACTION_TYPE,
  ASSIGNEE_TYPE,
  PAGE_NAME,
  STATUS_DEFAULT,
} from '../../../constants'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import utils from '../../../utils'
import authenticationStore from '../../../stores/authenticationStore'
import SpinnerInlineComponent from '../../../components/SpinnerInlineComponent'
import queryString from 'query-string'
import PopupWorkForm from '../PopupWorkForm/PopupWorkForm'
import { WorkRequest } from '../../../requests/WorkRequest'
import WorkPdf from './WorkPdf'
import ListHistoryLogModal from '../../ListHistoryLog/ListHistoryLogModal'
import WorkCommentList from './WorkComment/WorkCommentList'
import WorkCommentEditor from './WorkComment/WorkCommentEditor'
import ContentDrawerRight from './ContentDrawerRight'
import date_format from '../../../date_format'
import { blue } from '../../../color'
import { hozDivider, hozDividerNoMarginSide } from '../../../ui_constants'
import aclStore from '../../../stores/aclStore'
import CommentsPopoverMenu from '../../../components/Shared/CommentsPopoverMenu'
import voteStore from '../../../stores/voteStore'

const DrawerWork = props => {
  const {
    onCloseDrawerWork,
    isVisibleDrawerWork,
    openPopupUpdateWork,
    openPopupAddPersonWork,
    openPopupAddPersonVote,
    openPopupElectronicRelease,
    workStore,
    fileStore,
    selectUserStore,
    queryStringParsed,
    loadingAnimationStore,
    notificationStore,
    aclStore,
  } = props

  const { notificationType } = notificationStore
  const { workDetail, commentWorkList, queryParams, workList } = workStore
  const { voteDetail } = voteStore

  const [commentList, setCommentList] = useState([])
  const [isVisiblePopupWorkForm, setIsVisiblePopupWorkForm] = useState(false)

  const [isVisibleHistory, setIsVisibleHistory] = useState(false)
  const [documentHistory, setDocumentHistory] = useState({})

  const CONTENT_REF = useRef()
  const [editorContent, setEditorContent] = useState(null)

  const [isShowDelComments, setIsShowDelComments] = useState(false)

  const {
    assignees,
    attachments,
    code,
    content,
    due_date,
    start_date,
    status,
    subwork,
    parent_id,
    title,
    parent_title,
    overdue,
    work_type,
    outgoingDocument,
    important,
    created_at
  } = workDetail

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const breakpoint = 900

  useEffect(() => {
    const handleWindowResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const handlerList = assignees
    ? selectUserStore.convertListToDefault(
        toJS(assignees).filter(
          el =>
            el.permission === ASSIGNEE_TYPE.HANDLER &&
            el.assignee_code !== 'admin'
        )
      )
    : []
  const followerList = assignees
    ? selectUserStore.convertListToDefault(
        toJS(assignees).filter(
          el =>
            el.permission === ASSIGNEE_TYPE.FOLLOWER &&
            el.assignee_code !== 'admin'
        )
      )
    : []
  const getCommentWorkList = async () => {
    try {
      loadingAnimationStore.setShowSpinInline(true)
      await workStore.getCommentWorkList(code)
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }

  useEffect(() => {
    if (!code) return
    if (!utils.isIOSDevice()) {
      const channel4Broadcast = new BroadcastChannel('channel4')
      channel4Broadcast.onmessage = async even => {
        if (even.data.type === PAGE_NAME.WORK && even.data.code === code) {
          const scrollCommentWrapper = document.querySelector(
            '.ant-drawer-body'
          )
          await getCommentWorkList()
          await scrollCommentWrapper.scrollTo(
            0,
            scrollCommentWrapper.scrollHeight
          )
        }
      }
    }
  }, [code])

  useEffect(() => {
    if (!code) return
    /** Check thiết bị IOS */
    if (
      utils.isIOSDevice() &&
      notificationType &&
      notificationType.type === PAGE_NAME.WORK
    ) {
      ;(async () => {
        const scrollCommentWrapper = document.querySelector('.ant-drawer-body')
        await getCommentWorkList()
        await scrollCommentWrapper.scrollTo(
          0,
          scrollCommentWrapper.scrollHeight
        )
      })()
    }
  }, [code, notificationType])

  useEffect(() => {
    if (!code) return
    ;(async () => {
      await getCommentWorkList()
    })()
  }, [code])

  const renderStatusWork = status => {
    if (status === STATUS_DEFAULT.COMPLETE) {
      return (
        <Tag
          color={'green'}
          style={{
            borderRadius: 4,
            marginLeft: 10,
            fontWeight: 500,
            fontSize: 10,
          }}>
          Hoàn thành
        </Tag>
      )
    }
    return (
      <Tag
        color={'blue'}
        style={{
          borderRadius: 4,
          marginLeft: 10,
          fontWeight: 500,
          fontSize: 10,
        }}>
        Đang thực hiện
      </Tag>
    )
  }

  const [fileList, setFileList] = useState([])

  useEffect(() => {
    /** Danh sách comment sau khi xóa chứa các comment đã xóa liền nhau gộp thành 1 mảng */

    let commentAfterDeleteList = []
    let key = -1
    commentWorkList &&
      commentWorkList.map((el, i) => {
        if (!el.deleted) {
          commentAfterDeleteList.push(toJS(el))
        }
        if (el.deleted && i > key) {
          const arrCommentDel = []
          for (let index = i; index < commentWorkList.length; index++) {
            key = index
            if (commentWorkList[index].deleted === el.deleted) {
              arrCommentDel.push(toJS(commentWorkList[index]))
            }
            if (
              commentWorkList[index].deleted === !el.deleted ||
              index === commentWorkList.length - 1
            )
              break
          }
          commentAfterDeleteList.push({
            comment_id: arrCommentDel[0].comment_id,
            arrCommentDel: arrCommentDel,
            isHidden: true,
          })
        }
      })
    setCommentList(commentAfterDeleteList)
  }, [commentWorkList])

  const handleOpenWorkDetail = async code => {
    loadingAnimationStore.setShowSpinInline(true)
    try {
      const response = await workStore.getWorkDetail(code)
      if (response.data.subwork.length > 0 && response.data.code !== code) {
        window.history.replaceState(
          null,
          null,
          `/works/view/${code}?parent_id=${response.data.subwork[0].parent_id}`
        )
      } else {
        window.history.replaceState(null, null, `/works/view/${code}`)
      }
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }

  const renderDate = date => {
    if (!date) return <EmptyText>Không rõ</EmptyText>
    return (
      <span style={{ fontStyle: 'italic' }}>
        {' '}
        {date_format.DATE_FORMAT_SLASH(date)}
      </span>
    )
  }
  // Đánh dấu công việc ưu tiên
  const [isMarkingImportant, setIsMarkingImportant] = useState(false)
  const [isImportantWork, setIsImportantWork] = useState(false)
  useEffect(() => {
    setIsImportantWork(!!important)
    console.log(
      `important: ${important}, workDetail.important: ${workDetail.important}`
    )
  }, [workDetail])
  const handleMarkImportantWork = async () => {
    const parentId = utils.getParameterByName('parent_id')
    try {
      setIsMarkingImportant(true)
      await WorkRequest.markImportantWork(code, !isImportantWork)
      if (!parentId) {
        const workMarkedIndex = workList.findIndex(item => item.code === code)
        if (workMarkedIndex !== -1) {
          const newWorkList = [...toJS(workList)]
          newWorkList[workMarkedIndex].important = !isImportantWork
          workStore.setWorkList(newWorkList)
        }
      } else {
        const workParentIndex = workList.findIndex(
          item => item.code === parentId
        )
        if (workParentIndex !== -1) {
          const workMarkedIndex = workList[workParentIndex].subwork.findIndex(
            item => item.code === code
          )
          if (workMarkedIndex !== -1) {
            const newWorkList = [...workList]
            newWorkList[workParentIndex].subwork[
              workMarkedIndex
            ].important = !isImportantWork
            workStore.setWorkList(newWorkList)
          }
        }
      }
      setIsImportantWork(!isImportantWork)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsMarkingImportant(false)
    }
  }

  const infoDrawerRight = (
    <ContentDrawerRight
      screenWidth={screenWidth}
      breakpoint={breakpoint}
      work_type={work_type}
      handlerList={handlerList}
      followerList={followerList}
      attachments={attachments}
      outgoingDocument={outgoingDocument}
      parent_id={parent_id}
      status={status}
      subwork={subwork}
      handleOpenWorkDetail={handleOpenWorkDetail}
      setIsVisiblePopupWorkForm={setIsVisiblePopupWorkForm}
      workList={workList}
      code={code}
      assignees={assignees}
      openPopupUpdateWork={openPopupUpdateWork}
      onCloseDrawerWork={onCloseDrawerWork}
      openPopupAddPersonWork={openPopupAddPersonWork}
      openPopupAddPersonVote={openPopupAddPersonVote}
      openPopupElectronicRelease={openPopupElectronicRelease}
      workDetail={workDetail}
      setDocumentHistory={setDocumentHistory}
      setIsVisibleHistory={setIsVisibleHistory}
      title={title}
      created_at={created_at}
    />
  )

  const handleCloseHistory = async type => {
    setIsVisibleHistory(false)
  }

  const handleShowDeletedComments = async (commentList, isShow) => {
    if (isShow) {
      const newCommentList = []
      commentList.forEach(comment => {
        if (comment.isHidden) {
          newCommentList.push(...comment.arrCommentDel)
          comment.isHidden = !comment.isHidden
        } else newCommentList.push(comment)
      })
      setCommentList(newCommentList)
    } else {
      await getCommentWorkList()
    }
  }

  const handleToggleShowDelCommentsMenu = () => {
    handleShowDeletedComments(commentList, !isShowDelComments)
    setIsShowDelComments(!isShowDelComments)
  }

  return (
    <DrawerWrapper
      getContainer={false}
      placement="right"
      onClose={onCloseDrawerWork}
      visible={isVisibleDrawerWork}
      closable={false}
      width={screenWidth < breakpoint ? 'calc(100% - 50px)' : 850}
      style={{
        transform: 'translateY(50px)',
        position: 'absolute',
        zIndex: 998,
      }}
      className={'listCommentWrapper'}>
      <div style={{ height: 52 }} />
      <InfoDrawer>
        <InfoDrawerLeftWrapper
          style={{
            width: screenWidth < breakpoint ? 790 : 565,
            overflow: 'scroll',
          }}>
          {parent_id && (
            <a
              className={'work-parent'}
              onClick={() => handleOpenWorkDetail(parent_id)}>
              <LeftOutlined style={{ marginRight: 10 }} />
              {parent_title ? parent_title : <EmptyText>Không rõ</EmptyText>}
            </a>
          )}

          <HeaderDrawer>
            {isVisibleDrawerWork && (
              <Tooltip title="Đóng">
                <div
                  style={{
                    position: 'absolute',
                    left: '-43px',
                    top: -2,
                    zIndex: '-1',
                  }}>
                  <ButtonCloseDrawer
                    onClick={() => {
                      workStore.clearWorkDetail()
                      onCloseDrawerWork()
                      const queryStringStringify = queryString.stringify(
                        {
                          ...queryParams,
                          parent_id: null,
                        },
                        {
                          skipNull: true,
                        }
                      )
                      window.history.replaceState(
                        null,
                        null,
                        `/works?${queryStringStringify}`
                      )
                    }}>
                    <DoubleRightOutlined style={{ fontSize: 14 }} />
                  </ButtonCloseDrawer>
                </div>
              </Tooltip>
            )}

            <span>
              {isMarkingImportant ? (
                <LoadingOutlined style={{ fontSize: 20 }} />
              ) : (
                <span>
                  {aclStore.checkAccessControlAction(
                    ACL_ACTION_TYPE.uu_tien__WORK
                  ) ? (
                    <Popconfirm
                      title={
                        isImportantWork
                          ? 'Bạn có chắc chắn muốn bỏ đánh dấu ưu tiên công việc này?'
                          : 'Bạn có chắc chắn muốn đánh dấu ưu tiên công việc này?'
                      }
                      okText={isImportantWork ? 'Bỏ đánh dấu' : 'Đánh dấu'}
                      okType={isImportantWork ? 'danger' : 'primary'}
                      onConfirm={handleMarkImportantWork}
                      placement={'top'}
                      cancelText={'Không'}>
                      <Tooltip
                        title={
                          isImportantWork
                            ? 'Bỏ đánh dấu ưu tiên'
                            : 'Đánh dấu ưu tiên'
                        }>
                        <span className={'star-icon'}>
                          {isImportantWork ? (
                            <StarFilled />
                          ) : (
                            <StarTwoTone twoToneColor={'#bfbfbf'} />
                          )}
                        </span>
                      </Tooltip>
                    </Popconfirm>
                  ) : (
                    <span className={'star-icon'}>
                      {isImportantWork ? (
                        <StarFilled />
                      ) : (
                        <StarTwoTone twoToneColor={'#bfbfbf'} />
                      )}
                    </span>
                  )}
                </span>
              )}
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  marginBottom: 0,
                  marginLeft: 8,
                }}>
                {title}
              </span>
            </span>
          </HeaderDrawer>
          <div style={hozDivider} />
          <InfoDrawerLeft className={'scrollComment'}>
            <Row
              style={{
                margin: '14px 0',
              }}>
              <Col>
                <span>
                  <b>Thời gian: </b>
                  {renderDate(start_date)}
                  <span>{` - `}</span>
                  {renderDate(due_date)}
                </span>
              </Col>
              <Col>{renderStatusWork(status)}</Col>
              <Col flex={'auto'}>
                {status !== STATUS_DEFAULT.COMPLETE &&
                  !parent_id &&
                  aclStore.checkAccessControlAction(
                    ACL_ACTION_TYPE.phat_hanh__WORK
                  ) && (
                    <span
                      onClick={openPopupElectronicRelease}
                      style={{ cursor: 'pointer', float: 'right' }}>
                      <ContainerOutlined style={{ color: blue }} />
                      <span style={{ color: blue, marginLeft: 4 }}>
                        Phát hành
                      </span>
                    </span>
                  )}
              </Col>
            </Row>

            <div style={hozDividerNoMarginSide} />

            <ContentWrapper>
              <div>
                {content ? (
                  WordBreak(content, true)
                ) : (
                  <div style={{ marginBottom: 14 }}>
                    <EmptyText>Không có nội dung.</EmptyText>
                  </div>
                )}
              </div>
            </ContentWrapper>

            {screenWidth < breakpoint && infoDrawerRight}

            <div style={hozDividerNoMarginSide} />

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                justifyContent: 'space-between',
              }}>
              {commentList.length > 0 && (
                <>
                  <CommentTitle>Danh sách ý kiến:</CommentTitle>
                  <CommentsPopoverMenu
                    hideSortMenu
                    handleToggleMenu={handleToggleShowDelCommentsMenu}
                    isShow={isShowDelComments}
                  />
                </>
              )}
            </div>
            <WorkCommentList
              CONTENT_REF={CONTENT_REF}
              commentList={commentList}
              setCommentList={setCommentList}
              code={code}
              isShowDelComments={isShowDelComments}
              handleShowDeletedComments={handleShowDeletedComments}
            />
            {isVisibleDrawerWork && (
              <WorkCommentEditor
                handleShowDeletedComments={handleShowDeletedComments}
                isShowDelComments={isShowDelComments}
                screenWidth={screenWidth}
                breakpoint={breakpoint}
                CONTENT_REF={CONTENT_REF}
                editorContent={editorContent}
                fileList={fileList}
                setFileList={setFileList}
                commentList={commentList}
                setCommentList={setCommentList}
                code={code}
              />
            )}
          </InfoDrawerLeft>
          {/*<div style={{ height: '100vh' }} />*/}
          <div style={{ display: 'none' }}>
            <WorkPdf
              workDetail={workDetail}
              commentList={commentList.filter(item => !item.isHidden) || []}
              isImportantWork={isImportantWork}
              attachments={attachments || []}
              voteList={voteDetail}
            />
          </div>
          <SpinnerInlineComponent noBackground />
        </InfoDrawerLeftWrapper>
        {!(screenWidth < breakpoint) && infoDrawerRight}
      </InfoDrawer>

      <PopupWorkForm
        queryStringParsed={queryStringParsed}
        isVisiblePopupWorkForm={isVisiblePopupWorkForm}
        handleCloseWorkForm={() => setIsVisiblePopupWorkForm(false)}
      />
      <ListHistoryLogModal
        type={'WORK'}
        isVisible={isVisibleHistory}
        taskInfo={documentHistory}
        handleCloseHistory={handleCloseHistory}
      />
    </DrawerWrapper>
  )
}

DrawerWork.propTypes = {}

export default inject(
  'workStore',
  'voteStore',
  'fileStore',
  'selectUserStore',
  'loadingAnimationStore',
  'authenticationStore',
  'notificationStore',
  'aclStore'
)(observer(DrawerWork))
