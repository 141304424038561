import React from 'react'
import { inject, observer } from 'mobx-react'
import { Button, Popconfirm, Space, Tag, Tooltip } from 'antd'
import {
  CloseOutlined,
  UnorderedListOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { blue, yellowPrimary } from '../../color'
import { ModalWrapper } from './SelectPeoplePopupTrucStyled'
import { ModalBody } from '../SelectGroupPopup/SelectGroupPopupStyled'
import { AvatarHasName } from '../Common/Avatar'
import { EmptyText } from '../Common/CellText'
import SelectUserDepartmentBoxTruc from './SelectUserDepartmentBoxTruc'

const SelectPeoplePopupTruc = props => {
  const {
    userTrucStore,
    isVisibleSelectPeoplePopupTruc,
    handleCancelSelectUserTruc,
    handleSubmitSelectUserTruc,
  } = props

  const { selectPopupNameTruc, selectUserDataTruc } = userTrucStore

  const rowSelection = {
    // click checkbox chọn hoặc bỏ chon user
    onSelect: record => {
      selectUserDataTruc.map(user => user.id).includes(record.userName)
        ? userTrucStore.setSelectUserDataTruc(
            selectUserDataTruc.filter(user => user.id !== record.userName)
          )
        : userTrucStore.setSelectUserDataTruc([
            ...selectUserDataTruc,
            {
              id: record.userName,
              name: record.fullName,
            },
          ])
    },
    selectedRowKeys: selectUserDataTruc.map(user => user.id),
  }

  // click row --> select user
  const onSelectUser = selectData => {
    selectUserDataTruc.map(user => user.id).includes(selectData.userName)
      ? userTrucStore.setSelectUserDataTruc(
          selectUserDataTruc.filter(user => user.id !== selectData.userName)
        )
      : userTrucStore.setSelectUserDataTruc([
          ...selectUserDataTruc,
          {
            id: selectData.userName,
            name: selectData.fullName,
          },
        ])
  }

  // bỏ chọn người dùng khi click 'x' trong danh sách người dùng đã chọn
  const unselectUser = id => {
    userTrucStore.setSelectUserDataTruc(
      selectUserDataTruc.filter(user => user.id !== id)
    )
  }

  return (
    <ModalWrapper
      maskClosable={false}
      okText={'Đồng ý'}
      cancelText={'Hủy bỏ'}
      width={1200}
      style={{ top: 20 }}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <span>{selectPopupNameTruc}</span>
          <Space>
            <Popconfirm
              placement="bottom"
              title={'Bạn có chắc chắn muốn xóa hết người được chọn không ?'}
              onConfirm={handleCancelSelectUserTruc}
              okText="Đồng ý"
              cancelText="Không"
              zIndex={1600}>
              <Button
                danger
                icon={<WarningOutlined style={{ color: yellowPrimary }} />}>
                Hủy chọn
              </Button>
            </Popconfirm>
            <Button type={'primary'} onClick={handleSubmitSelectUserTruc}>
              Đồng ý
            </Button>
            <Tooltip title={'Đóng'}>
              <CloseOutlined onClick={handleSubmitSelectUserTruc} />
            </Tooltip>
          </Space>
        </div>
      }
      closable={false}
      forceRender={true}
      footer={null}
      visible={isVisibleSelectPeoplePopupTruc}
      destroyOnClose>
      {isVisibleSelectPeoplePopupTruc && (
        <ModalBody>
          <div className={'list-box'}>
            <label
              style={{
                width: 100,
                display: 'flex',
                fontWeight: 500,
                alignItems: 'center',
              }}>
              <UnorderedListOutlined style={{ marginRight: 4, color: blue }} />
              Danh sách:
            </label>
            {selectUserDataTruc.length > 0 ? (
              selectUserDataTruc.map(user => {
                return (
                  <Tag
                    className={'tag-selected'}
                    color={'blue'}
                    key={user.id}
                    onClose={() => unselectUser(user.id)}
                    closable>
                    <AvatarHasName
                      imgId={user.image_id}
                      size={22}
                      name={user.name}
                    />
                  </Tag>
                )
              })
            ) : (
              <EmptyText>
                <span style={{ lineHeight: '34px' }}>
                  Chưa có người được chọn
                </span>
              </EmptyText>
            )}
          </div>
          <div className={'modal-body'}>
            <SelectUserDepartmentBoxTruc
              onSelectUser={onSelectUser}
              rowSelection={rowSelection}
            />
            <div />
          </div>
        </ModalBody>
      )}
    </ModalWrapper>
  )
}

SelectPeoplePopupTruc.propTypes = {}

export default inject('userTrucStore')(observer(SelectPeoplePopupTruc))
