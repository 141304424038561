import { Checkbox } from 'antd'
import styled from 'styled-components'

export const RiskActionStyle = styled.div`
  h6 {
    font-size: 14px !important;
  }
`
export const CheckboxWrapper = styled.span`
  pointer-events: none !important;
`
