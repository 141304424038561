import styled from 'styled-components'

export const BlockLeft = styled.div`
  width: calc(100% - 415px);
  @media screen and (max-width: 1299px) {
    width: 100%;
    margin-bottom: 15px;
  }
`
export const RiskDetail = styled.div`
  .ant-table-tbody {
    vertical-align: text-top;
  }
`
