import { inject, observer } from 'mobx-react'
import { Button, Col, Form, Input, message, Row, Select } from 'antd'
import React from 'react'
import { Helmet } from 'react-helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { Container } from '../../layouts/Container/Container'
import DashboardLayout from '../../layouts/DashboardLayout'
import { CreateTitle } from './RiskCreatePageStyled'
import { useState } from 'react'
import queryString from 'query-string'
import { useEffect } from 'react'
import { ASSIGNEE_TYPE } from '../../constants'
import { FormActionFooter } from '../ConnectedDocumentIncomingDocCreatePage/ConnectedDocumentIncomingDocCreatePageStyled'
import RiskSteps from '../../components/RiskSteps'

const { Option } = Select

const RiskCreatePage = props => {
  const {
    history,
    selectPeopleStore,
    authenticationStore,
    loadingAnimationStore,
    riskStore,
    actionStore,
  } = props
  const queryStringParse = queryString.parse(location.search)
  const { currentUser } = authenticationStore
  const { riskUnits, createRisk } = riskStore
  const { dataActions, setIndexDetailAction } = actionStore
  const {
    selectUserData,
    assigneePopupType,
    clearSelectPeopleStore,
  } = selectPeopleStore
  const [editorContent, setEditorContent] = useState(null)
  const [selectUserList, setSelectUserList] = useState({
    unitLeader: [],
    VIMCLeader: [],
    KT_KTNB: [],
    HDQT: [],
    USER:[],
    lanh_dao_vimc:[],
    ban_kt_ktnb:[],
    ban_pc_qtrr_ban_kt_ktnb_vimc:[],
    ban_dieu_hanh_hdqt_vimc:[]

  })
  // const [dataActions, setDataActions] = useState()
  const [current, setCurrent] = useState(0)

  const [form] = Form.useForm()

  useEffect(() => {
    ;(async () => {
      try {
        await riskStore.getRiskUnits()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      }
    })()
    return () => {
      riskStore.clearRiskStore()
      actionStore.clearActionStore()
      clearSelectPeopleStore()
    }
  }, [])

  useEffect(() => {
    if (!assigneePopupType) return
    setSelectUserList({
      ...selectUserList,
      unitLeader: [
        ...selectUserData[ASSIGNEE_TYPE.UNIT_LEADER].map(user => ({
          ...user,
          status: 'PENDING',
        })),
      ],
      VIMCLeader: [
        ...selectUserData[ASSIGNEE_TYPE.LEADER].map(user => ({
          ...user,
          status: 'PENDING',
        })),
      ],
      KT_KTNB: [
        ...selectUserData[ASSIGNEE_TYPE.KT_KTNB].map(user => ({
          ...user,
          status: 'PENDING',
        })),
      ],
      HDQT: [
        ...selectUserData[ASSIGNEE_TYPE.HDQT].map(user => ({
          ...user,
          status: 'PENDING',
        })),
      ],
      USER: [
        ...selectUserData[ASSIGNEE_TYPE.USER]
      ],
      lanh_dao_vimc: [
        ...selectUserData[ASSIGNEE_TYPE.lanh_dao_vimc]
      ],
      ban_kt_ktnb: [
        ...selectUserData[ASSIGNEE_TYPE.ban_kt_ktnb]
      ],
      ban_pc_qtrr_ban_kt_ktnb_vimc: [
        ...selectUserData[ASSIGNEE_TYPE.ban_pc_qtrr_ban_kt_ktnb_vimc]
      ],
      ban_dieu_hanh_hdqt_vimc: [
        ...selectUserData[ASSIGNEE_TYPE.ban_dieu_hanh_hdqt_vimc]
      ],
    })
  }, [selectUserData])

  const onChangeSteps = value => {
    setCurrent(value)
  }

  const handleCreateRiskControl = async value => {
    if (selectUserList.unitLeader?.length <= 0) {
      message.error('Vui lòng chọn lãnh đạo đơn vị!')
      return
    } else if (selectUserList.VIMCLeader?.length <= 0) {
      message.error('Vui lòng chọn lãnh đạo VIMC!')
      return
    } else if (selectUserList.KT_KTNB?.length <= 0) {
      message.error('Vui lòng chọn KT-KTNB VIMC!')
      return
    } else if (selectUserList.HDQT?.length <= 0) {
      message.error('Vui lòng chọn thành viên hội đồng quản trị!')
      return
    }
    const submitData = {
      ...value,
      type: queryStringParse.type,
      tiers: [
        {
          name: 'Tuyến phòng vệ thứ nhất',
          level: 1,
          approvals: [
            {
              name: 'Chuyên viên thực hiện',
              level: 1,
              peoples: [
                {
                  username: currentUser.code,
                  status: 'CREATED',
                },
              ],
            },
            {
              name: 'Lãnh đạo đơn vị',
              level: 2,
              peoples: selectUserList.unitLeader?.map(user => ({
                username: user.id,
                status: 'PENDING',
              })),
            },
          ],
          actions: dataActions.tier1.map(action => ({
            name: 'Rủi ro nhận diện 1',
            data: JSON.stringify(action),
          })),
        },
        {
          name: 'Tuyến phòng vệ thứ hai',
          level: 2,
          approvals: [
            {
              name: 'Đơn vị thực hiện xử lý rủi ro',
              level: 1,
              peoples: [
                {
                  username: currentUser.code,
                  status: 'CREATED',
                },
              ],
            },
            {
              name: 'Lãnh đạo VIMC',
              level: 2,
              peoples: selectUserList.VIMCLeader?.map(user => ({
                username: user.id,
                status: 'PENDING',
              })),
            },
          ],
          actions: dataActions.tier2.map(action => ({
            name: 'Rủi ro nhận diện 2',
            data: JSON.stringify(action),
          })),
        },
        {
          name: 'Tuyến phòng vệ thứ ba',
          level: 3,
          approvals: [
            {
              name: 'Đơn vị thực hiện xử lý rủi ro',
              level: 1,
              peoples: [
                {
                  username: currentUser.code,
                  status: 'CREATED',
                },
              ],
            },
            {
              name: 'Ban KT-KTNB VIMC',
              level: 2,
              peoples: selectUserList.KT_KTNB?.map(user => ({
                username: user.id,
                status: 'PENDING',
              })),
            },
            {
              name: 'Hội đồng quản trị',
              level: 3,
              peoples: selectUserList.HDQT?.map(user => ({
                username: user.id,
                status: 'PENDING',
              })),
            },
          ],
          actions: dataActions.tier3.map(action => ({
            name: 'Rủi ro nhận diện 3',
            data: JSON.stringify(action),
          })),
        },
        {
          name: 'Tuyến phòng vệ thứ tư',
          level: 4,
          approvals: [],
          actions: dataActions.tier4.map(action => ({
            name: 'Rủi ro nhận diện 4',
            data: JSON.stringify(action),
          })),
        },
      ],
    }
    loadingAnimationStore.showSpinner(true)
    try {
      await createRisk(submitData)
      message.success(`Tạo phiếu rủi ro thành công`)
      clearSelectPeopleStore()
      history.goBack()
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }
  return (
    <DashboardLayout>
      <Helmet>
        <title>Tạo mới phiếu rủi ro | VIMC tạo mới phiếu rủi ro</title>
      </Helmet>
      <PageTitle location={props.location} title={'Thêm phiếu rủi ro'} />
      <ContentBlockWrapper>
        <Container maxWidth={1000}>
          <CreateTitle>Tạo mới phiếu kiểm soát rủi ro</CreateTitle>
          <div className={'btn-group-left'}>
            <Form
              form={form}
              layout={'vertical'}
              onFinish={handleCreateRiskControl}>
              <Row type={'flex'} gutter={15}>
                <Col span={8}>
                  <Form.Item
                    name={'title'}
                    label={'Tiêu đề:'}
                    rules={[
                      { required: true, message: 'Vui lòng nhập tiêu đề!' },
                    ]}>
                    <Input placeholder={'Tiêu đề phiếu kiểm soát rủi ro'} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name={'risk_unit_code'}
                    label={'Đơn vị:'}
                    rules={[
                      { required: true, message: 'Vui lòng chọn đơn vị!' },
                    ]}>
                    <Select placeholder={'Đơn vị'}>
                      {riskUnits?.map(unit => (
                        <Option
                          key={unit.code}
                          name={'risk_unit_code'}
                          value={unit.code}>
                          {unit.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <RiskSteps
                current={current}
                onChangeSteps={onChangeSteps}
                createUser={currentUser}
                selectUserList={selectUserList}
              />
              <div style={{ textAlign: 'left' }}>
                <FormActionFooter>
                  <Button
                    onClick={() => {
                      history.goBack()
                    }}>
                    Huỷ bỏ
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    type={'primary'}
                    htmlType={'submit'}>
                    Tạo
                  </Button>
                </FormActionFooter>
              </div>
            </Form>
          </div>
        </Container>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default inject(
  'selectPeopleStore',
  'authenticationStore',
  'loadingAnimationStore',
  'riskStore',
  'actionStore'
)(observer(RiskCreatePage))
