import React from 'react'
import { greenPrimary } from '../../color'
import RichText from '../../components/RichEditor/RichText'
import RiskAction from '../../components/RiskAction'
import { ACTION_STATUS } from '../../constants'
import utils from '../../utils'

const displayUsers = (userList, status) => {
  return userList
    .filter(user => user.status === status)
    .map(user => (
      <span key={user.code}>
        &nbsp;<b>{utils.getNameInCapitalize(user.full_name)}</b>{' '}
        <i>
          (
          {status === ACTION_STATUS.APPROVED ? (
            <span style={{ color: greenPrimary }}>Đã duyệt</span>
          ) : status === ACTION_STATUS.REJECTED ? (
            <span style={{ color: 'red' }}>Từ chối</span>
          ) : (
            <span style={{ color: 'blue' }}>Chờ duyệt</span>
          )}
          ),
        </i>
      </span>
    ))
}
const RiskPdf = props => {
  const { riskDetail, dataActions, createUser, selectUserList } = props
  return (
    <div id={'riskDivToPrint'} style={{ fontSize: '10px !important ' }}>
      <div style={{ fontSize: 20, marginBottom: 16 }}>
        <b>{riskDetail.title}</b>
      </div>
      <RichText htmlText={riskDetail.content}></RichText>
      <div style={{ fontSize: 20, margin: '16px 0' }}>
        <b>A. Tuyến phòng vệ thứ nhất</b>
      </div>
      <div>
        Chuyên viên thực hiện:{' '}
        <b>
          {utils.getNameInCapitalize(
            createUser?.full_name || createUser?.name_uppercase
          )}
        </b>
      </div>
      <div style={{ margin: '14px 0 16px',lineHeight:'1.4' }}>
        Lãnh đạo đơn vị:{' '}
        {displayUsers(selectUserList.unitLeader, ACTION_STATUS.APPROVED)}
        {displayUsers(selectUserList.unitLeader, ACTION_STATUS.REJECTED)}
        {displayUsers(selectUserList.unitLeader, ACTION_STATUS.PENDING)}
      </div>
      <RiskAction data={dataActions?.tier1} tier={'1'} />
      <div style={{ fontSize: 20, margin: '20px 0' }}>
        <b>B. Tuyến phòng vệ thứ hai</b>
      </div>
      <div>
        Chuyên viên thực hiện:{' '}
        <b>
          {utils.getNameInCapitalize(
            createUser?.full_name || createUser?.name_uppercase
          )}
        </b>
      </div>
      <div style={{ margin: '14px 0 16px' ,lineHeight:'1.4'}}>
        Lãnh đạo VIMC:
        {displayUsers(selectUserList.VIMCLeader, ACTION_STATUS.APPROVED)}
        {displayUsers(selectUserList.VIMCLeader, ACTION_STATUS.REJECTED)}
        {displayUsers(selectUserList.VIMCLeader, ACTION_STATUS.PENDING)}
      </div>
      <RiskAction data={dataActions?.tier2} tier={'2'} />
      <div style={{ fontSize: 20, margin: '20px 0' }}>
        <b>C. Tuyến phòng vệ thứ ba</b>
      </div>
      <div style={{}}>
        Chuyên viên thực hiện:{' '}
        <b>
          {utils.getNameInCapitalize(
            createUser?.full_name || createUser?.name_uppercase
          )}
        </b>
      </div>
      <div style={{ margin: '14px 0 ',lineHeight:'1.4' }}>
        BAN KT-KTNB VIMC:
        {displayUsers(selectUserList.KT_KTNB, ACTION_STATUS.APPROVED)}
        {displayUsers(selectUserList.KT_KTNB, ACTION_STATUS.REJECTED)}
        {displayUsers(selectUserList.KT_KTNB, ACTION_STATUS.PENDING)}
      </div>
      <div style={{ marginBottom: '16px' ,lineHeight:'1.4'}}>
        HDQT VIMC:
        {displayUsers(selectUserList.HDQT, ACTION_STATUS.APPROVED)}
        {displayUsers(selectUserList.HDQT, ACTION_STATUS.REJECTED)}
        {displayUsers(selectUserList.HDQT, ACTION_STATUS.PENDING)}
      </div>
      <RiskAction data={dataActions?.tier3} tier={'3'} />
    </div>
  )
}
RiskPdf.propTypes = {}

export default RiskPdf
