import React, { useEffect } from 'react'
import { useRef } from 'react'
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
} from 'antd'
import RichEditor from '../../components/RichEditor/RichEditor'
import { FormActionFooter } from '../ConnectedDocumentIncomingDocCreatePage/ConnectedDocumentIncomingDocCreatePageStyled'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Label } from 'react-konva'
import { inject, observer } from 'mobx-react'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import utils from '../../utils'
import { useState } from 'react'

const RiskAssessmentCreatePopup = ({
  handleCancel,
  isVisible,
  actionStore,
  riskStore,
  getRiskDetail,
}) => {
  const {
    indexDetailAction,
    dataActions,
    setDataActions,
    createAction,
    updateAction,
    deleteAction,
  } = actionStore
  const { riskDetail } = riskStore
  const [form] = Form.useForm()
  const HANDLE_SOLUTION_REF = useRef()
  const [isRenderRichText, setIsRenderRichText]=useState(false)

  useEffect(() => {
    const fillData = dataActions?.tier2[0]
    if (!fillData || indexDetailAction?.tier !== '2') {
      return
    }

    form.setFieldsValue({
      ban_pc_qtrr_vimc: fillData.doi_tuong_thuc_hien.ban_pc_qtrr_vimc,
      ban_cmnv_vimc: fillData.doi_tuong_thuc_hien.ban_cmnv_vimc,
      //III_kiem_soat_rui_ro -> doi_voi_cac_loai_rui_ro_da_nhan_dien
      doi_voi_cac_loai_rui_ro_da_nhan_dien__dong_y:
        fillData.III_kiem_soat_rui_ro.doi_voi_cac_loai_rui_ro_da_nhan_dien
          .dong_y,
      doi_voi_cac_loai_rui_ro_da_nhan_dien__dieu_chinh_bo_sung:
        fillData.III_kiem_soat_rui_ro.doi_voi_cac_loai_rui_ro_da_nhan_dien
          .dieu_chinh_bo_sung,
      //III_kiem_soat_rui_ro -> doi_voi_danh_gia_muc_rui_ro
      doi_voi_danh_gia_muc_rui_ro__dong_y:
        fillData.III_kiem_soat_rui_ro.doi_voi_danh_gia_muc_rui_ro.dong_y,
      doi_voi_danh_gia_muc_rui_ro__dieu_chinh_bo_sung:
        fillData.III_kiem_soat_rui_ro.doi_voi_danh_gia_muc_rui_ro
          .dieu_chinh_bo_sung,
      //
      loai_rui_ro_da_de_xuat_xu_ly:
        fillData.III_kiem_soat_rui_ro.loai_rui_ro_da_de_xuat_xu_ly,
      danh_gia_bien_phap_xu_ly_da_de_xuat:
        fillData.III_kiem_soat_rui_ro.danh_gia_bien_phap_xu_ly_da_de_xuat,
      thoi_gian_hoan_thanh_cong_viec_duoc_giao:
        fillData.III_kiem_soat_rui_ro.thoi_gian_hoan_thanh_cong_viec_duoc_giao,
      y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc:
        fillData.III_kiem_soat_rui_ro.y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc,
      chuyen_vien_thuc_hien:
        fillData.III_kiem_soat_rui_ro.chuyen_vien_thuc_hien,
      lanh_dao_ban: fillData.III_kiem_soat_rui_ro.lanh_dao_ban,
    })
  }, [indexDetailAction])
  const handleCancelModal = () => {
    handleCancel()
  }

  const handleSubmit = async value => {
    const submitData = {
      doi_tuong_thuc_hien: {
        ban_pc_qtrr_vimc: value.ban_pc_qtrr_vimc,
        ban_cmnv_vimc: value.ban_cmnv_vimc,
      },
      III_kiem_soat_rui_ro: {
        doi_voi_cac_loai_rui_ro_da_nhan_dien: {
          dong_y: value.doi_voi_cac_loai_rui_ro_da_nhan_dien__dong_y,
          dieu_chinh_bo_sung:
            value.doi_voi_cac_loai_rui_ro_da_nhan_dien__dieu_chinh_bo_sung,
        },
        doi_voi_danh_gia_muc_rui_ro: {
          dong_y: value.doi_voi_danh_gia_muc_rui_ro__dong_y,
          dieu_chinh_bo_sung:
            value.doi_voi_danh_gia_muc_rui_ro__dieu_chinh_bo_sung,
        },
        loai_rui_ro_da_de_xuat_xu_ly: value.loai_rui_ro_da_de_xuat_xu_ly,
        danh_gia_bien_phap_xu_ly_da_de_xuat:
          value.danh_gia_bien_phap_xu_ly_da_de_xuat,
        thoi_gian_hoan_thanh_cong_viec_duoc_giao:
          value.thoi_gian_hoan_thanh_cong_viec_duoc_giao,
        y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc:
          value.y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc,
        chuyen_vien_thuc_hien: value.chuyen_vien_thuc_hien,
        lanh_dao_ban: value.lanh_dao_ban,
      },
    }

    if (riskDetail?.tiers) {
      const currentAction = riskDetail?.tiers[1]?.actions[0]
      if (currentAction) {
        await updateAction(currentAction.code, {
          data: JSON.stringify(submitData),
          risk_tier_code: riskDetail?.tiers[1]?.code,
        })
      } else {
        await createAction({
          ...submitData,
          data: JSON.stringify(submitData),
          risk_tier_code: riskDetail?.tiers[1]?.code,
        })
      }
      message.success('Lưu thành công')
      handleCancelModal()
      getRiskDetail()
    } else {
      setDataActions({
        ...dataActions,
        tier2: [submitData],
      })
      message.success('Lưu thành công')
      handleCancelModal()
    }
  }

  const valueStyle = { marginLeft: 8 }

  return (
    <Modal
      width={800}
      style={{ top: 40,  zIndex: 500 }}
      title={'B.	Tuyến phòng vệ thứ hai'}
      visible={isVisible}
      maskClosable={false}
      destroyOnClose={true}
      afterClose={() => {
        setIsRenderRichText(false)
      }}
      onCancel={() => {
        handleCancelModal()
      }}
      footer={null}>
      <Form
        form={form}
        layout={'vertical'}
        onFinish={handleSubmit}
        preserve={false}>
        <h3>Đối tượng thực hiện:</h3>
        <Form.Item
          name={'ban_pc_qtrr_vimc'}
          label={
            '(1) Ban PC&QTRR VIMC (trong trường hợp các Ban CMNV thực hiện công việc):'
          }>
          <Input placeholder={'Nhập nội dung'} />
        </Form.Item>
        <Form.Item
          name={'ban_cmnv_vimc'}
          label={
            '(2) Ban CMNV VIMC (trong trường hợp giao NĐDPV, ĐVPT thực hiện công việc; các Ban cmnv chủ trì):'
          }>
          <Input placeholder={'Nhập nội dung'} />
        </Form.Item>

        <Divider />

        <h3>III. Kiểm soát rủi ro</h3>
        <p>
          1. Đối với các loại rủi ro đã nhận diện:
          <span style={valueStyle}>
            <Space direction="horizontal">
              <Form.Item
                name={'doi_voi_cac_loai_rui_ro_da_nhan_dien__dong_y'}
                valuePropName="checked">
                <Checkbox>Đồng ý</Checkbox>
              </Form.Item>

              <Form.Item
                name={
                  'doi_voi_cac_loai_rui_ro_da_nhan_dien__dieu_chinh_bo_sung'
                }>
                <Input placeholder={'Điều chỉnh bổ sung'} />
              </Form.Item>
            </Space>
          </span>
        </p>

        <p>
          2. Đối với đánh giá mức rủi ro:
          <span style={valueStyle}>
            <Space direction="horizontal">
              <Form.Item
                name={'doi_voi_danh_gia_muc_rui_ro__dong_y'}
                valuePropName="checked">
                <Checkbox>Đồng ý</Checkbox>
              </Form.Item>

              <Form.Item
                name={'doi_voi_danh_gia_muc_rui_ro__dieu_chinh_bo_sung'}>
                <Input placeholder={'Điều chỉnh bổ sung'} />
              </Form.Item>
            </Space>
          </span>
        </p>

        <p>
          3. Loại rủi ro đã đề xuất xử lý:
          <span style={valueStyle}>
            <Space direction="horizontal">
              <Form.Item
                name={'loai_rui_ro_da_de_xuat_xu_ly'}
                valuePropName="checked">
                <Checkbox>Đồng ý</Checkbox>
              </Form.Item>
            </Space>
          </span>
        </p>

        <p>
          4. Đánh giá biện pháp xử lý đã đề xuất:
          <span style={valueStyle}>
            <Space>
              <Form.Item
                name={'danh_gia_bien_phap_xu_ly_da_de_xuat'}
                valuePropName="checked">
                <Checkbox>Tuân thủ</Checkbox>
              </Form.Item>
            </Space>
          </span>
        </p>

        <p>
          5. Thời gian hoàn thành công việc được giao:
          <span style={valueStyle}>
            <Space>
              <Form.Item
                name={'thoi_gian_hoan_thanh_cong_viec_duoc_giao'}
                valuePropName="checked">
                <Checkbox>Tuân thủ</Checkbox>
              </Form.Item>
            </Space>
          </span>
        </p>

        <Form.Item
          name={'y_kien_khac_cua_ban_pc_qtrr_ban_cmnv_vimc'}
          label={'6. Ý kiến khác của Ban PC&QTRR/Ban CMNV VIMC:'}>
          <Input placeholder={'Nhập nội dung'} />
        </Form.Item>

        <Divider />

        <h3>BAN PC&QTRR VIMC/ BAN CMNV VIMC</h3>

        <Form.Item
          name={'chuyen_vien_thuc_hien'}
          label={'Chuyên viên thực hiện:'}>
          <Input placeholder={'Nhập nội dung'} />
        </Form.Item>
        <Form.Item name={'lanh_dao_ban'} label={'Lãnh đạo Ban:'}>
          <Input placeholder={'Nhập nội dung'} />
        </Form.Item>

        <FormActionFooter style={{ marginBottom: 0 }}>
          <Button icon={<CloseOutlined />} danger onClick={handleCancelModal}>
            Đóng
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            type={'primary'}
            htmlType={'submit'}>
            Chỉnh sửa
          </Button>
        </FormActionFooter>
      </Form>
    </Modal>
  )
}

export default inject(
  'actionStore',
  'riskStore'
)(observer(RiskAssessmentCreatePopup))
