import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import { Button, Col, Dropdown, Menu, message, Modal, Row } from 'antd'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PaperClipOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import FileDownload from 'js-file-download'
import { blue } from '../../../color'
import {
  DocumentInfoContent,
  FileViewBlock,
  HeadingButtonWrapper,
  TitleBlock,
  ValueBlock,
  ViewDetailContent,
} from './DocumentVBPCDetailStyled'
import PDFViewer from '../../../components/PDFViewer'
import { ACL_ACTION_TYPE, DATE_FORMAT_DEFAULT } from '../../../constants'
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'
import utils from '../../../utils'

const { confirm } = Modal

const DocumentVBPCFormPage = props => {
  const {
    loadingAnimationStore,
    documentStore,
    history,
    match,
    aclStore,
  } = props

  const [attachmentForView, setAttachmentForView] = useState()
  // const [attachments, setAttachments] = useState([])

  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1299px)',
  })

  const { documentId } = match.params
  const { VBPCDetail, attachment } = documentStore
  const {
    id,
    file_id,
    document_name,
    document_number,
    contruction_board,
    issue_date,
    status,
    file_name,
    issue_level,
    attachments,
  } = VBPCDetail

  const downloadFile = useCallback(
    (id, file_id, file_name) => {
      loadingAnimationStore.showSpinner(true)
      documentStore
        .getAttachmentVBPC({
          recordId: id,
          fileId: file_id,
        })
        .then(response => {
          loadingAnimationStore.showSpinner(false)
          const blob = new Blob([response.data], { type: 'application/pdf' })
          FileDownload(blob, `${file_name}`)
        })
        .catch(() => {
          loadingAnimationStore.showSpinner(false)
        })
    },
    [documentStore, loadingAnimationStore]
  )

  useEffect(() => {
    ;(async () => {
      loadingAnimationStore.showSpinner(true)
      try {
        await documentStore.getVBPCDetail(documentId)
      } catch (err) {
        history.push('/utility/vbpc')
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => {}
  }, [documentId])

  const handleDeleteDocument = async () => {
    loadingAnimationStore.showSpinner(true)
    try {
      await documentStore.deleteVBPC(documentId)
      history.push('/utility/vbpc')
      message.success('Xóa văn bản thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const showConfirm = () => {
    confirm({
      title: 'Bạn có muốn xóa văn bản này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDeleteDocument()
      },
    })
  }

  useEffect(() => {
    if (!attachments) return
    setAttachmentForView(
      attachments.find(file =>
        ['pdf', 'doc', 'docx'].includes(utils.getExtensionFile(file.name))
      )
    )
  }, [attachments])

  const renderAttachment = useMemo(
    () => (
      <PDFViewer
        fileId={attachmentForView?.id}
        customStyleObj={{ height: isTabletOrMobile ? 500 : 1000 }}
        fileType={utils.getExtensionFile(attachmentForView?.name)}
      />
    ),
    [attachmentForView]
  )

  const menuHandleDocument = (
    <Menu>
      {aclStore.checkAccessControlAction(ACL_ACTION_TYPE.edit__PHAP_CHE) && (
        <Menu.Item
          key={1}
          onClick={() => history.push(`/utility/vbpc/${documentId}`)}
          icon={<EditOutlined style={{ color: blue }} />}>
          <span style={{ color: blue }}>Chỉnh sửa văn bản</span>
        </Menu.Item>
      )}
      {aclStore.checkAccessControlAction(ACL_ACTION_TYPE.delete__PHAP_CHE) && (
        <Menu.Item
          key={2}
          danger
          onClick={showConfirm}
          icon={<DeleteOutlined />}>
          Xóa văn bản
        </Menu.Item>
      )}
    </Menu>
  )

  const renderViewContent = () => (
    <Row type={'flex'} gutter={30} style={{ height: '100%' }}>
      <Col span={24} xs={24} md={16}>
        {<FileViewBlock>{renderAttachment}</FileViewBlock>}
      </Col>
      <Col span={24} xs={24} md={8}>
        <ContentBlockWrapper>
          <DocumentInfoContent>
            <Row>
              <TitleBlock>
                <strong>Tên văn bản:</strong>
              </TitleBlock>
              <ValueBlock>{document_name}</ValueBlock>
            </Row>

            <Row>
              <TitleBlock>
                <strong>Số quyết định ban hành:</strong>
              </TitleBlock>
              <ValueBlock>{document_number}</ValueBlock>
            </Row>

            <Row>
              <TitleBlock>
                <strong>Ngày ban hành:</strong>
              </TitleBlock>
              <ValueBlock>
                {moment(issue_date).format(DATE_FORMAT_DEFAULT)}
              </ValueBlock>
            </Row>

            <Row>
              <TitleBlock>
                <strong>Cấp ban hành:</strong>
              </TitleBlock>
              <ValueBlock>{issue_level || '-'}</ValueBlock>
            </Row>

            <Row>
              <TitleBlock>
                <strong>Ban chủ trì xây dựng:</strong>
              </TitleBlock>
              <ValueBlock>{contruction_board || '-'}</ValueBlock>
            </Row>

            <Row>
              <TitleBlock>
                <strong>Trạng thái:</strong>
              </TitleBlock>
              <ValueBlock>
                {
                  ['Đã bãi bỏ', 'Hết hiệu lực', 'Còn hiệu lực'][
                    parseInt(status) - 1
                  ]
                }
              </ValueBlock>
            </Row>

            <Row>
              <Col>
                <Button
                  onClick={() => downloadFile(id, file_id, file_name)}
                  type={'primary'}>
                  <PaperClipOutlined className={'cursor-pointer'} /> Download
                </Button>
              </Col>
            </Row>
          </DocumentInfoContent>
        </ContentBlockWrapper>
      </Col>
    </Row>
  )

  return (
    <DashboardLayout>
      <Helmet>
        <title>Chi tiết văn bản pháp chế | Tài liệu</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={document_name}
        showTitle={true}
        routerGoback={'/utility/vbpc'}>
        <HeadingButtonWrapper>
          <Dropdown overlay={menuHandleDocument} trigger={['click']}>
            <span className={'btn-style'}>
              <MoreOutlined style={{ color: blue }} />
            </span>
          </Dropdown>
        </HeadingButtonWrapper>
      </PageTitle>
      <ViewDetailContent>{renderViewContent()}</ViewDetailContent>
    </DashboardLayout>
  )
}

DocumentVBPCFormPage.propTypes = {}

export default memo(
  inject(
    'loadingAnimationStore',
    'commonStore',
    'documentStore',
    'aclStore'
  )(observer(DocumentVBPCFormPage))
)
