import React, { useEffect, useReducer, useState } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Checkbox,
  Collapse,
  List,
  message,
  Popconfirm,
  Spin,
  Tooltip,
} from 'antd'
import moment from 'moment'
import {
  ACL_ACTION_TYPE,
  ASSIGNEE_TYPE,
  DATE_FORMAT_SLASH,
  STATUS_DEFAULT,
  TYPE_STATUS,
  VIEWLOG_DATE,
  WORK_STATUS,
} from '../../../constants'
import {
  BlockContent,
  BlockLeft,
  ContentBlock,
  HeaderWork,
  ListWrapper,
  StarIcon,
  TaskItem,
} from '../WorkStyledPage'
import {
  CaretRightOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  LoadingOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons'
import { grayPrimary, greenPrimary } from '../../../color'
import PopoverWatchTime from '../../../components/PopoverWatchTime'
import { EmptyText } from '../../../components/Common/CellText'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import { StatusTag } from '../../../components/Common/StatusTag'
import utils from '../../../utils'
import date_format from '../../../date_format'
import Search from './Search'
import { AvatarHasName } from '../../../components/Common/Avatar'
import queryString from 'query-string'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { WorkRequest } from '../../../requests/WorkRequest'
import { runInAction, toJS } from 'mobx'
import axios from 'axios'

const { Panel } = Collapse

const ListWork = props => {
  const {
    workStore,
    selectUserStore,
    selectPeopleStore,
    authenticationStore,
    aclStore,
    loadingAnimationStore,
    handleOpenDrawerWork,
  } = props

  const { workList, workDetail, queryParams, totalWork } = workStore
  const history = useHistory()
  const location = useLocation()
  const queryStringParsed = queryString.parse(location.search, {
    arrayFormat: 'index',
  })

  const params = useParams()

  useEffect(() => {
    if (!authenticationStore.currentUser) return
    if (
      queryStringParsed.assignee &&
      Array.isArray(queryStringParsed.assignee)
    ) {
      queryStringParsed.assignee.forEach(item => {
        const itemInfo = item.split(',')
        const username = itemInfo[0].split('=')[1]
        if (itemInfo.includes(ASSIGNEE_TYPE.USER)) {
          selectPeopleStore.setSelectUserData({
            ...selectPeopleStore.selectUserData,
            [itemInfo[2]]: [
              {
                assignee_type: ASSIGNEE_TYPE.USER,
                full_name: itemInfo[3],
                id: username,
                permission: itemInfo[2],
                department_code: null,
                department_name: null,
                image_id: null,
                position_name: null,
              },
            ],
          })
        }
        if (itemInfo.includes(ASSIGNEE_TYPE.DEPARTMENT)) {
          selectPeopleStore.setSelectDepartmentData({
            ...selectPeopleStore.selectDepartmentData,
            [itemInfo[2]]: [
              {
                assignee_type: ASSIGNEE_TYPE.DEPARTMENT,
                full_name: itemInfo[3],
                id: username,
                permission: itemInfo[2],
                department_code: null,
                department_name: null,
                image_id: null,
                position_name: null,
              },
            ],
          })
        }
        if (itemInfo.includes(ASSIGNEE_TYPE.GROUP)) {
          selectPeopleStore.setSelectGroupData({
            ...selectPeopleStore.selectGroupData,
            [itemInfo[2]]: [
              {
                assignee_type: ASSIGNEE_TYPE.GROUP,
                full_name: itemInfo[3],
                id: username,
                permission: itemInfo[2],
                department_code: null,
                department_name: null,
                image_id: null,
                position_name: null,
              },
            ],
          })
        }
      })
    }
  }, [authenticationStore.currentUser])

  const reducer = (state, action) => {
    switch (action.type) {
      case ASSIGNEE_TYPE.CREATE:
        return {
          ...state,
          [ASSIGNEE_TYPE.CREATE]:
            action.value &&
            `assignee=${encodeURIComponent(action.value)},${
              action.assigneeType
            },${ASSIGNEE_TYPE.CREATE},${action.fullName || 'unknown'}`,
        }
      case ASSIGNEE_TYPE.HANDLER:
        return {
          ...state,
          [ASSIGNEE_TYPE.HANDLER]:
            action.value &&
            `assignee=${encodeURIComponent(action.value)},${
              action.assigneeType
            },${ASSIGNEE_TYPE.HANDLER},${action.fullName || 'unknown'}`,
        }
      default:
        return state
    }
  }

  const [usersFilter, dispatchUserFilter] = useReducer(reducer, {
    [ASSIGNEE_TYPE.CREATE]:
      queryStringParsed.assignee && Array.isArray(queryStringParsed.assignee)
        ? queryStringParsed.assignee.find(el =>
            el.includes(ASSIGNEE_TYPE.CREATE)
          )
        : null,
    [ASSIGNEE_TYPE.HANDLER]:
      queryStringParsed.assignee && Array.isArray(queryStringParsed.assignee)
        ? queryStringParsed.assignee.find(el =>
            el.includes(ASSIGNEE_TYPE.HANDLER)
          )
        : null,
  })

  useEffect(() => {
    if (params?.code_work) return

    runInAction(() => {
      workStore.queryParams = {
        page: queryStringParsed.page || 0,
        size: queryStringParsed.size || 30,
        title: queryStringParsed.title,
        from_date: queryStringParsed.from_date,
        to_date: queryStringParsed.to_date,
        status: queryStringParsed.status,
        work_type: queryStringParsed.work_type,
        permission: queryStringParsed.permission,
        parent_id: queryStringParsed.parent_id,
        is_important: queryStringParsed.is_important,
        is_unread: queryStringParsed.is_unread,
        assignee: Object.values(usersFilter)
          .filter(item => item)
          .map(item => {
            const assignee = item.split(',')
            if (assignee.length === 3) {
              return assignee.join()
            }
            assignee.pop()
            return assignee.join()
          })
          .join('&'),
      }
    })

    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParsed,
        assignee: Object.values(usersFilter),
        [ASSIGNEE_TYPE.CREATE]:
          typeof usersFilter[ASSIGNEE_TYPE.CREATE] === 'string'
            ? usersFilter[ASSIGNEE_TYPE.CREATE].split(',')[0].substring(9)
            : null,
        [ASSIGNEE_TYPE.HANDLER]:
          typeof usersFilter[ASSIGNEE_TYPE.HANDLER] === 'string'
            ? usersFilter[ASSIGNEE_TYPE.HANDLER].split(',')[0].substring(9)
            : null,
        [ASSIGNEE_TYPE.FOLLOWER]:
          typeof usersFilter[ASSIGNEE_TYPE.FOLLOWER] === 'string'
            ? usersFilter[ASSIGNEE_TYPE.FOLLOWER].split(',')[0].substring(9)
            : null,
      },
      {
        skipNull: true,
        arrayFormat: 'index',
      }
    )
    history.replace(`/works?${queryStringStringify}`)
  }, [usersFilter])

  const handleOpenWorkDetail = async code => {
    loadingAnimationStore.setShowSpinInline(true)
    handleOpenDrawerWork()
    try {
      const response = await workStore.getWorkDetail(code)
      if (response.data.subwork.length > 0 && response.data.code !== code) {
        const queryStringStringify = queryString.stringify(
          {
            parent_id: response.data.code,
          },
          {
            skipNull: true,
          }
        )
        window.history.replaceState(
          null,
          null,
          `/works/view/${code}?${queryStringStringify}`
        )
      } else {
        window.history.replaceState(null, null, `/works/view/${code}`)
      }
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setShowSpinInline(false)
    }
  }

  const getWorkList = async cancelToken => {
    try {
      loadingAnimationStore.setTableLoading(true)
      workStore.clearWorkList()
      const res = await workStore.getWorkList(cancelToken)

      if (res) {
        loadingAnimationStore.setTableLoading(false)
      }
    } catch (error) {
      console.log(error)
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
    }
  }

  useEffect(() => {
    const source = axios.CancelToken.source()
    ;(async () => {
      isFetchingMore ? await workStore.getWorkList() : getWorkList(source.token)
      setIsFetchingMore(false)
    })()
    return () => {
      source.cancel()
    }
  }, [workStore.queryParams])

  const handleChangeFilterImportant = async value => {
    setActiveKey([])
    workStore.setQueryParams({
      ...queryParams,
      page: 0,
      is_important: value.target.checked ? true : null,
      parent_id: null,
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParsed,
        is_important: value.target.checked ? true : null,
        parent_id: null,
      },
      {
        skipNull: true,
      }
    )
    history.replace(`/works?${queryStringStringify}`)
    // await getWorkList()
  }
  const onChangeUnReadStatus = async value => {
    setActiveKey([])
    workStore.setQueryParams({
      ...queryParams,
      is_unread: value.target.checked ? true : null,
      page: 0,
      parent_id: null,
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParsed,
        is_unread: value.target.checked ? true : null,
        parent_id: null,
      },
      {
        skipNull: true,
      }
    )
    history.replace(`/works?${queryStringStringify}`)
    // await getWorkList()
  }

  const renderHeaderWork = (
    <HeaderWork>
      <div className={'title-work title-header'}>
        <div className="checkbox-left">
          <Checkbox
            onChange={handleChangeFilterImportant}
            defaultChecked={!!queryStringParsed.is_important}>
            <span
              style={{
                color: '#fff',
                fontWeight: 'bold',
                transform: 'translateY(-1px)',
                display: 'inline-block',
              }}>
              Chọn việc ưu tiên
            </span>
          </Checkbox>
        </div>
        <Checkbox
          onChange={onChangeUnReadStatus}
          defaultChecked={!!queryStringParsed.is_unread}>
          <span
            style={{
              color: '#fff',
              fontWeight: 'bold',
              transform: 'translateY(-1px)',
              display: 'inline-block',
            }}>
            Chọn việc chưa đọc
          </span>
        </Checkbox>
      </div>

      <BlockLeft>
        <ContentBlock className={'user'}>
          <span style={{ paddingRight: 15 }}>Người xử lý</span>
        </ContentBlock>
        <ContentBlock className={'user'}>
          <span>Người tạo</span>
        </ContentBlock>
        {WORK_STATUS.ALL_OTHER.includes(queryParams.work_type) && (
          <ContentBlock className={'work-type-width'}>
            <span>Phân loại</span>
          </ContentBlock>
        )}
        <ContentBlock className={'time-width'}>
          <span>Hạn hoàn thành</span>
        </ContentBlock>
      </BlockLeft>
    </HeaderWork>
  )

  const updatedBy = (user_name, updated_at) => {
    if (!user_name) return null
    return (
      <div style={{ flex: '0 0 100%', fontSize: 8, marginTop: 2 }}>
        <span>
          Cập nhật bởi <b>{utils.getNameInCapitalize(user_name)}</b> -{' '}
          <span>{date_format.renderTime(updated_at)}</span>
        </span>
      </div>
    )
  }

  const timeColumn = item => (
    <ContentBlock className={'time-width'}>
      {' '}
      {item.due_date ? (
        moment(item.due_date).format(DATE_FORMAT_SLASH)
      ) : (
        <i style={{ color: grayPrimary }}>Không rõ</i>
      )}
    </ContentBlock>
  )

  const handleOpenCollapse = async (work_code, keyArray) => {
    if (
      !work_code ||
      workList.find(el => el.code === work_code).subwork.length > 0
    ) {
      setActiveKey(keyArray)
      return
    }

    try {
      loadingAnimationStore.setTableLoading(true)
      await workStore.getListSubWork(work_code)
      setActiveKey(keyArray)
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  const [activeKey, setActiveKey] = useState([])

  useEffect(() => {
    if (queryStringParsed.parent_id) {
      setActiveKey([queryStringParsed.parent_id])
    }
  }, [location])

  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    if (workList.length >= totalWork) {
      setHasMore(false)
    } else {
      setHasMore(true)
    }
  }, [workList])

  const [isFetchingMore, setIsFetchingMore] = useState(false)

  const fetchMoreData = async () => {
    setIsFetchingMore(true)
    workStore.setQueryParams({
      ...queryParams,
      page: queryParams.page + 1,
    })
  }

  const [markingImportant, setMarkingImportant] = useState({
    status: false,
    workCode: null,
  })

  const handleMarkImportantWork = async (code, status, parentCode) => {
    try {
      setMarkingImportant({
        status: true,
        workCode: code,
      })
      await WorkRequest.markImportantWork(code, !status)
      if (!parentCode) {
        const workMarkedIndex = workList.findIndex(item => item.code === code)
        if (workMarkedIndex !== -1) {
          const newWorkList = [...toJS(workList)]
          newWorkList[workMarkedIndex].important = !status
          workStore.setWorkList(newWorkList)
        }
      } else {
        const workParentIndex = workList.findIndex(
          item => item.code === parentCode
        )
        if (workParentIndex !== -1) {
          const workMarkedIndex = workList[workParentIndex].subwork.findIndex(
            item => item.code === code
          )
          if (workMarkedIndex !== -1) {
            const newWorkList = [...workList]
            newWorkList[workParentIndex].subwork[
              workMarkedIndex
            ].important = !status
            workStore.setWorkList(newWorkList)
          }
        }
      }
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setMarkingImportant({
        status: false,
        workCode: null,
      })
    }
  }

  return (
    <ContentBlockWrapper style={{ minHeight: 'calc(100vh - 140px)' }}>
      <Search
        handleCloseCollapse={() => setActiveKey([])}
        dispatchUserFilter={dispatchUserFilter}
      />
      <BlockContent id={'divScroll'}>
        <InfiniteScroll
          dataLength={workList.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 10,
              }}>
              {!loadingAnimationStore.tableLoading && (
                <Spin tip="Đang tải..." />
              )}
            </div>
          }
          scrollableTarget={'divScroll'}>
          <ListWrapper
            header={renderHeaderWork}
            loading={{
              wrapperClassName: 'spinner',
              tip: 'Đang tải công việc...',
              spinning: loadingAnimationStore.tableLoading,
            }}
            dataSource={workList}
            renderItem={item => {
              const handleList = selectUserStore.convertListToDefault(
                item.assignees.filter(
                  user => user.permission === ASSIGNEE_TYPE.HANDLER
                )
              )
              return (
                <Collapse
                  style={{ marginBottom: 8 }}
                  activeKey={activeKey}
                  expandIcon={({ isActive }) => {
                    return (
                      <CaretRightOutlined
                        rotate={isActive ? 90 : 0}
                        style={{
                          position: 'absolute',
                          left: '36px',
                          fontSize: 14,
                          color: '#595959',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          padding: 0,
                        }}
                      />
                    )
                  }}
                  onChange={async keyArray => {
                    if (!(item.has_subwork || item.subwork?.length > 0)) return
                    await handleOpenCollapse(item.code, keyArray)
                  }}
                  bordered={false}>
                  <Panel
                    key={item.code}
                    collapsible
                    showArrow={item.has_subwork || item.subwork?.length > 0}
                    header={
                      <TaskItem
                        workActive={item.code === workDetail.code}
                        isUnread={!item.is_read}>
                        <div
                          className={'title-work'}
                          onClick={async e => {
                            e.stopPropagation()
                            await handleOpenWorkDetail(item.code)
                            if (!item.is_read) {
                              workStore.setIsReadTask({
                                code: item.code,
                                parent_code: null,
                              })
                            }
                          }}>
                          {aclStore.checkAccessControlAction(
                            ACL_ACTION_TYPE.uu_tien__WORK
                          ) ? (
                            <Popconfirm
                              title={
                                item.important
                                  ? 'Bạn có chắc chắn muốn bỏ đánh dấu ưu tiên công việc này?'
                                  : 'Bạn có chắc chắn muốn đánh dấu ưu tiên công việc này?'
                              }
                              okText={
                                item.important ? 'Bỏ đánh dấu' : 'Đánh dấu'
                              }
                              okType={item.important ? 'danger' : 'primary'}
                              onConfirm={async e => {
                                e.stopPropagation()
                                await handleMarkImportantWork(
                                  item.code,
                                  item.important
                                )
                              }}
                              onCancel={e => e.stopPropagation()}
                              placement={'top'}
                              cancelText={'Không'}>
                              <Tooltip
                                placement={'left'}
                                title={
                                  item.important
                                    ? 'Bỏ đánh dấu ưu tiên'
                                    : 'Đánh dấu ưu tiên'
                                }>
                                <StarIcon
                                  hasSubWork={
                                    item.has_subwork || item.subwork?.length > 0
                                  }
                                  onClick={e => e.stopPropagation()}>
                                  {!(
                                    markingImportant.status &&
                                    markingImportant.workCode === item.code
                                  ) ? (
                                    item.important ? (
                                      <StarFilled
                                        style={{
                                          color: '#fadb14',
                                          fontSize: 16,
                                        }}
                                      />
                                    ) : (
                                      <StarOutlined
                                        style={{
                                          fontSize: 16,
                                          color: '#595959',
                                        }}
                                      />
                                    )
                                  ) : (
                                    <LoadingOutlined
                                      style={{ fontSize: 16, color: '#595959' }}
                                    />
                                  )}
                                </StarIcon>
                              </Tooltip>
                            </Popconfirm>
                          ) : (
                            <StarIcon
                              hasSubWork={
                                item.has_subwork || item.subwork?.length > 0
                              }
                              onClick={e => e.stopPropagation()}>
                              {!(
                                markingImportant.status &&
                                markingImportant.workCode === item.code
                              ) ? (
                                item.important ? (
                                  <StarFilled
                                    style={{ color: '#fadb14', fontSize: 16 }}
                                  />
                                ) : (
                                  <StarOutlined
                                    style={{ fontSize: 16, color: '#595959' }}
                                  />
                                )
                              ) : (
                                <LoadingOutlined
                                  style={{ fontSize: 16, color: '#595959' }}
                                />
                              )}
                            </StarIcon>
                          )}

                          <div className={'complete-icon'}>
                            {item.status === STATUS_DEFAULT.COMPLETE ? (
                              <CheckCircleFilled
                                style={{ color: greenPrimary, fontSize: 14 }}
                              />
                            ) : (
                              <CheckCircleOutlined
                                style={{ color: '#595959', fontSize: 14 }}
                              />
                            )}
                          </div>
                          <div>
                            {item.title}
                            {updatedBy(item.updated_user, item.updated_at)}
                          </div>
                        </div>
                        <BlockLeft>
                          <div
                            className={'assign'}
                            onClick={e => e.stopPropagation()}>
                            {handleList.length > 0 ? (
                              <PopoverWatchTime
                                userList={handleList}
                                title={'Danh sách người xử lý'}
                                isFullViewer={true}
                                compared_date={VIEWLOG_DATE.work_read_until}
                                typeId={item.code}
                                created_at={item.created_at}
                              />
                            ) : (
                              <EmptyText>Không có người xử lý.</EmptyText>
                            )}
                          </div>
                          <ContentBlock className={'user'}>
                            {item.assignees.length > 0 ? (
                              <AvatarHasName
                                imgId={
                                  item.assignees.find(
                                    user =>
                                      user.permission === ASSIGNEE_TYPE.CREATE
                                  )?.image
                                }
                                name={
                                  item.assignees.find(
                                    user =>
                                      user.permission === ASSIGNEE_TYPE.CREATE
                                  )?.name_uppercase
                                }
                              />
                            ) : (
                              <EmptyText>Không rõ.</EmptyText>
                            )}
                          </ContentBlock>
                          {WORK_STATUS.ALL_OTHER.includes(
                            queryParams.work_type
                          ) && (
                            <ContentBlock className={'work-type-width'}>
                              {StatusTag(TYPE_STATUS.WORK, item.work_type)}
                            </ContentBlock>
                          )}
                          {timeColumn(item)}
                        </BlockLeft>
                      </TaskItem>
                    }>
                    {item.subwork.length > 0 ? (
                      item.subwork.map(el => {
                        const handlerSubList = selectUserStore.convertListToDefault(
                          el.assignees.filter(
                            user => user.permission === ASSIGNEE_TYPE.HANDLER
                          )
                        )
                        return (
                          <List.Item
                            className={!el.is_read ? 'item-unread' : ''}
                            key={el.code}
                            style={{
                              paddingRight: 47,
                              paddingLeft: 84,
                              backgroundColor:
                                el.code === workDetail.code && '#D7E3FA',
                            }}
                            onClick={async () => {
                              await handleOpenWorkDetail(el.code)
                              if (!el.is_read) {
                                workStore.setIsReadTask({
                                  code: el.code,
                                  parent_code: item.code,
                                })
                              }
                            }}>
                            {aclStore.checkAccessControlAction(
                              ACL_ACTION_TYPE.uu_tien__WORK
                            ) ? (
                              <Popconfirm
                                title={
                                  el.important
                                    ? 'Bạn có chắc chắn muốn bỏ đánh dấu ưu tiên công việc này?'
                                    : 'Bạn có chắc chắn muốn đánh dấu ưu tiên công việc này?'
                                }
                                okText={
                                  el.important ? 'Bỏ đánh dấu' : 'Đánh dấu'
                                }
                                okType={el.important ? 'danger' : 'primary'}
                                onConfirm={async e => {
                                  e.stopPropagation()
                                  await handleMarkImportantWork(
                                    el.code,
                                    el.important,
                                    el.parent_id
                                  )
                                }}
                                onCancel={e => e.stopPropagation()}
                                placement={'top'}
                                cancelText={'Không'}>
                                <Tooltip
                                  placement={'left'}
                                  title={
                                    el.important
                                      ? 'Bỏ đánh dấu ưu tiên'
                                      : 'Đánh dấu ưu tiên'
                                  }>
                                  <span
                                    className={'start-children'}
                                    onClick={e => e.stopPropagation()}>
                                    {!(
                                      markingImportant.status &&
                                      markingImportant.workCode === el.code
                                    ) ? (
                                      el.important ? (
                                        <StarFilled
                                          style={{
                                            color: '#fadb14',
                                            fontSize: 16,
                                          }}
                                        />
                                      ) : (
                                        <StarOutlined
                                          style={{
                                            fontSize: 16,
                                            color: '#595959',
                                          }}
                                        />
                                      )
                                    ) : (
                                      <LoadingOutlined
                                        style={{
                                          fontSize: 16,
                                          color: '#595959',
                                        }}
                                      />
                                    )}
                                  </span>
                                </Tooltip>
                              </Popconfirm>
                            ) : (
                              <span
                                className={'start-children'}
                                onClick={e => e.stopPropagation()}>
                                {!(
                                  markingImportant.status &&
                                  markingImportant.workCode === el.code
                                ) ? (
                                  el.important ? (
                                    <StarFilled
                                      style={{
                                        color: '#fadb14',
                                        fontSize: 16,
                                      }}
                                    />
                                  ) : (
                                    <StarOutlined
                                      style={{
                                        fontSize: 16,
                                        color: '#595959',
                                      }}
                                    />
                                  )
                                ) : (
                                  <LoadingOutlined
                                    style={{ fontSize: 16, color: '#595959' }}
                                  />
                                )}
                              </span>
                            )}

                            {el.status === STATUS_DEFAULT.COMPLETE ? (
                              <CheckCircleFilled
                                style={{
                                  fontSize: 14,
                                  marginLeft: 12,
                                  color: greenPrimary,
                                }}
                              />
                            ) : (
                              <CheckCircleOutlined
                                style={{
                                  marginLeft: 12,
                                  fontSize: 14,
                                  color: '#595959',
                                }}
                              />
                            )}
                            <div className={'title-subwork'}>
                              <div>{el.title}</div>
                              {updatedBy(el.updated_user, el.updated_at)}
                            </div>
                            <div style={{ display: 'flex', columnGap: 10 }}>
                              <div
                                className={'assign'}
                                onClick={e => e.stopPropagation()}>
                                {handlerSubList.length > 0 ? (
                                  <PopoverWatchTime
                                    userList={handlerSubList}
                                    title={'Danh sách người xử lý'}
                                    isFullViewer={true}
                                    compared_date={VIEWLOG_DATE.work_read_until}
                                    typeId={el.code}
                                    created_at={el.created_at}
                                  />
                                ) : (
                                  <EmptyText>Không có người xử lý.</EmptyText>
                                )}
                              </div>
                              <ContentBlock className={'user'}>
                                {el.assignees.length > 0 ? (
                                  <AvatarHasName
                                    imgId={
                                      el.assignees.find(
                                        user =>
                                          user.permission ===
                                          ASSIGNEE_TYPE.CREATE
                                      )?.image
                                    }
                                    name={
                                      el.assignees.find(
                                        user =>
                                          user.permission ===
                                          ASSIGNEE_TYPE.CREATE
                                      )?.name_uppercase
                                    }
                                  />
                                ) : (
                                  <EmptyText>Không rõ.</EmptyText>
                                )}
                              </ContentBlock>
                              {WORK_STATUS.ALL_OTHER.includes(
                                queryParams.work_type
                              ) && (
                                <ContentBlock className={'work-type-width'}>
                                  {StatusTag(TYPE_STATUS.WORK, el.work_type)}
                                </ContentBlock>
                              )}
                              {timeColumn(item)}
                            </div>
                          </List.Item>
                        )
                      })
                    ) : (
                      <div style={{ padding: '10px 0 10px 60px' }}>
                        <EmptyText>Không có công việc phụ</EmptyText>
                      </div>
                    )}
                  </Panel>
                </Collapse>
              )
            }}
          />
        </InfiniteScroll>
      </BlockContent>
    </ContentBlockWrapper>
  )
}

export default inject(
  'workStore',
  'selectUserStore',
  'selectPeopleStore',
  'loadingAnimationStore',
  'authenticationStore',
  'aclStore'
)(observer(ListWork))

function getWorkList() {
  throw new Error('Function not implemented.')
}
