import axios from 'axios'
import { apiUrl } from '../config'
import authenticationStore from '../stores/authenticationStore'

export const ActionRequest = {
  createAction: payload =>
    axios({
      method: 'post',
      url: `${apiUrl}/api/v1/risk/action`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: payload,
    }),
  updateAction: (code, payload) =>
    axios({
      method: 'put',
      url: `${apiUrl}/api/v1/risk/action/${code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
      data: payload,
    }),
  deleteAction: code =>
    axios({
      method: 'delete',
      url: `${apiUrl}/api/v1/risk/action/${code}`,
      headers: {
        Authorization: `Bearer ${
          JSON.parse(authenticationStore.appToken).access_token
        }`,
        'Content-Type': 'application/json',
      },
    }),
}
