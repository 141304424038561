import { action, observable } from 'mobx'
import { MessagesRequest } from '../requests/MessagesRequest'

class MessagesStore {
  @observable messageList = []
  @observable messageDetail = {}
  @observable messageTotalCount = 0
  @observable unreadMessageCount = 0
  @observable replyMessages = []
  @observable pageSize = 30
  @observable pageIndex = 0

  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }
  @action getList = () => {
    return new Promise((resolve, reject) => {
      MessagesRequest.getList(this.pageIndex, this.pageSize)
        .then(response => {
          this.messageList = [...this.messageList, ...response.data.data]
          console.log(response.data)
          console.log(response.data.totalElements)
          this.messageTotalCount = response.data.totalElements
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getMessageCreated = () => {
    return new Promise((resolve, reject) => {
      MessagesRequest.getList(0, 1)
        .then(response => {
          this.messageList = [...response.data.data, ...this.messageList]
          console.log(response.data)
          console.log(response.data.totalElements)
          this.messageTotalCount = response.data.totalElements
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getMessageById = id => {
    return new Promise((resolve, reject) => {
      MessagesRequest.getMessageById(id)
        .then(response => {
          this.messageDetail = response.data
          this.replyMessages = response.data.replyMessages
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action create = data => {
    return new Promise((resolve, reject) => {
      MessagesRequest.create(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Clear */
  @action clearMessageStore = () => {
    this.messageList.length = 0
    this.messageTotalCount = 0
    this.replyMessages = []
    this.unreadMessageCount = 0
    this.messageDetail = {}
    this.pageIndex = 0
    this.pageSize = 30
  }
}

export default new MessagesStore()
