import { action, observable } from 'mobx'
import { ActionRequest } from '../requests/ActionRequest'

class actionStore {
  @observable dataActions = {
    tier1: [],
    tier2: [],
    tier3: [],
    tier4: [],
  }

  @observable indexDetailAction = {
    tier: null,
    index: null,
  }



  @observable setDataActions = data => {
    this.dataActions = data
  }

  @observable setIndexDetailAction = data => {
    this.indexDetailAction = data
  }


  @action createAction = payload => {
    return new Promise((resolve, reject) => {
      ActionRequest.createAction(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action updateAction = (code, payload) => {
    return new Promise((resolve, reject) => {
      ActionRequest.updateAction(code, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action deleteAction = code => {
    return new Promise((resolve, reject) => {
      ActionRequest.deleteAction(code)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action clearActionStore = () => {
    this.dataActions = {
      tier1: [],
      tier2: [],
      tier3: [],
      tier4: [],
    }
    this.indexDetailAction = {
      tier: null,
      index: null,
    }
  }
}

export default new actionStore()
