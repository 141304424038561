import React from 'react'
import PropTypes from 'prop-types'
// Component
import NormalTag from '../NormalTag/NormalTag'
import { EmptyText } from '../Common/CellText'
// Styled component
import {
  PopoverItem,
  PopoverList,
  PopoverTitle,
  TagWrapper,
  ViewLogModal,
} from './PopoverWatchTimeStyled'
// Ant design
import { message, Popover } from 'antd'
import {
  BankOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  HistoryOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
// Other
import { blue, green, yellowPrimary } from '../../color'
import { ASSIGNEE_TYPE } from '../../constants'
import date_format from '../../date_format'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import useOpenItem from '../../hooks/useOpenItem'
import utils from '../../utils'
import SpinnerInlineComponent from '../SpinnerInlineComponent'

const findAssigneeType = assigneeType => {
  switch (assigneeType) {
    case ASSIGNEE_TYPE.USER:
      return <UserOutlined style={{ color: blue, marginRight: 6 }} />
    case ASSIGNEE_TYPE.DEPARTMENT:
      return <BankOutlined style={{ color: green, marginRight: 6 }} />
    case ASSIGNEE_TYPE.GROUP:
      return <TeamOutlined style={{ color: yellowPrimary, marginRight: 6 }} />
    default:
      return <UserOutlined style={{ color: blue, marginRight: 6 }} />
  }
}
const findTextColor = assignee_type => {
  return assignee_type === ASSIGNEE_TYPE.USER
    ? blue
    : assignee_type === ASSIGNEE_TYPE.DEPARTMENT
    ? green
    : yellowPrimary
}

const PopoverWatchTime = props => {
  const {
    userList,
    title,
    showFullTag,
    internalDocumentStore,
    selectPeopleStore,
    isFullViewer,
    typeId,
    compared_date,
    created_at = '',
  } = props

  // const { documentId, } = useParams()
  const { viewLogList, setViewLogList, getViewLog } = internalDocumentStore
  const {
    isOpen: isVisibleViewLog,
    handleOpen: handelVisibleLogView,
    handleClose: handleCancelLogView,
  } = useOpenItem()
  const [isOpenPopoverList, setIsOpenPopoverList] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const handlePopoverList = value => {
    setIsOpenPopoverList(value)
  }
  // userList có dạng:
  // userList = [
  //   {
  //     assignee_type: "USER"
  //     full_name: "ĐỖ ĐỨC ÂN"
  //     id: "andd"
  // last_date_read
  //     permission: "LEAD"
  //   }
  //   ...
  // ]
  const createdAt = new Date(created_at)
  const comparedDate = new Date(compared_date)
  const groups = userList.filter(
    item => item.assignee_type !== ASSIGNEE_TYPE.USER
  )

  const users = userList.filter(
    item => item.assignee_type === ASSIGNEE_TYPE.USER
  )
  const handleGetViewLog = async groups => {
    setIsLoading(true)
    await Promise.all([
      ...groups.map(group =>
        getViewLog({
          assignee_code: group.id,
          assignee_type: group.assignee_type,
          document_code: typeId,
        })
      ),

      getViewLog({
        assignee_code: users.map(user => user.id).join(','),
        assignee_type: ASSIGNEE_TYPE.USER,
        document_code: typeId,
      }),
    ]).finally(() => setIsLoading(false))
  }
  const popoverList = (listSelected, selectGroup) => {
    return (
      <PopoverList>
        <div className={'popover-head'}>
          {selectGroup ? (
            <span
              style={{
                color: findTextColor(selectGroup.assignee_type),
                textTransform: 'capitalize',
                marginLeft: selectGroup ? '8px' : 0,
                maxWidth: 300,
              }}>
              {findAssigneeType(selectGroup.assignee_type)}
              {(selectGroup?.full_name ?? selectGroup?.name)?.toLowerCase()}
            </span>
          ) : (
            <b>
              <UserOutlined style={{ color: blue, marginRight: 6 }} />
              Người dùng
            </b>
          )}

          <b style={{ display: 'inline-block', width: 132 }}>
            <HistoryOutlined style={{ color: blue, marginRight: 6 }} />
            Thời gian xem
          </b>
        </div>
        {listSelected?.length > 0 ? (
          listSelected.map((el, index) => {
            return (
              <PopoverItem key={index}>
                <span
                  style={{
                    color: blue,
                    textTransform: 'capitalize',
                    marginLeft: selectGroup ? '8px' : 0,
                  }}>
                  {findAssigneeType(el?.assignee_type)}
                  {utils
                    .getInfoUser(
                      el?.username,
                      selectPeopleStore.originDepartmentWithUserList
                    )
                    ?.full_name?.toLowerCase() ??
                    el?.full_name ??
                    el?.name}
                </span>
                <time>
                  {isFullViewer && createdAt < comparedDate ? (
                    <span>
                      <EyeOutlined style={{ color: green, marginRight: 6 }} />
                      Đã xem
                    </span>
                  ) : el.last_date_read || el.first_date_read ? (
                    <span>
                      <EyeOutlined style={{ color: green, marginRight: 6 }} />
                      {date_format.HH_mm_DD_MM_YYYY(
                        el.last_date_read || el.first_date_read
                      )}
                    </span>
                  ) : (
                    <EmptyText>
                      <EyeInvisibleOutlined
                        style={{ color: '#ccc', marginRight: 6 }}
                      />
                      Chưa xem.
                    </EmptyText>
                  )}
                </time>
              </PopoverItem>
            )
          })
        ) : (
          <EmptyText>
            <span style={{ marginLeft: 12 }}>Không có.</span>
          </EmptyText>
        )}
        {isLoading && (
          <div style={{ height: 150, position: 'relative' }}>
            <SpinnerInlineComponent
              sizeSpin={'small'}
              tip="Đang tải dữ liệu..."
              isLoading={isLoading}
              backGround={'#ffffffc7'}
            />
          </div>
        )}
      </PopoverList>
    )
  }

  return (
    <>
      {isFullViewer ? (
        <TagWrapper
          onClick={() => {
            handleGetViewLog(groups)
            handelVisibleLogView()
          }}>
          <NormalTag
            noHover={true}
            showFullTag={showFullTag}
            tags={userList.map(user =>
              (user?.full_name ?? user?.name)?.toLowerCase()
            )}
          />
        </TagWrapper>
      ) : (
        <Popover
          placement="top"
          content={() => popoverList(userList)}
          title={<PopoverTitle>{title}</PopoverTitle>}
          visible={isOpenPopoverList}
          onVisibleChange={handlePopoverList}
          trigger="click"
          overlayClassName={'wrapper-list'}
          overlayStyle={{ maxWidth: '450px' }}>
          <TagWrapper>
            <NormalTag
              noHover={true}
              showFullTag={showFullTag}
              tags={userList.map(user =>
                (user?.full_name ?? user?.name)?.toLowerCase()
              )}
            />
          </TagWrapper>
        </Popover>
      )}

      <ViewLogModal
        title={'Danh sách người xem'}
        width={550}
        style={{ top: 40 }}
        visible={isVisibleViewLog}
        closable={true}
        maskClosable={true}
        onCancel={() => {
          handleCancelLogView()
          setViewLogList([])
        }}
        footer={null}>
        {groups.map(group =>
          popoverList(
            viewLogList.filter(item => item.groupId === group.id),
            group
          )
        )}
        {users?.length > 0
          ? popoverList(
              viewLogList.filter(user => user.groupType === ASSIGNEE_TYPE.USER)
            )
          : ''}
      </ViewLogModal>
    </>
  )
}

PopoverWatchTime.propTypes = {
  userList: PropTypes.array,
  title: PropTypes.string,
  showFullTag: PropTypes.bool,
}

export default inject(
  'internalDocumentStore',
  'selectPeopleStore'
)(observer(PopoverWatchTime))
