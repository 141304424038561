import { CloseOutlined } from '@ant-design/icons'
import { Button, Empty, Form, message, Modal, Radio, Select } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import TableComponent from '../../components/Common/TableComponent'
import RichText from '../../components/RichEditor/RichText'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import { FormActionFooter } from '../ConnectedDocumentIncomingDocCreatePage/ConnectedDocumentIncomingDocCreatePageStyled'
import { TemplateTable } from './RiskCreatePageStyled'

const RuiRoNhanDienTemplate = ({
  isVisible,
  handleCancelModal,
  riskStore,
  setRuiRoNhanDien,
}) => {
  const {
    ruiRoNhanDienType,
    getRuiRoNhanDienType,
    getListTemplate,
    listTemplate,
  } = riskStore
  const [isLoading, setIsLoading] = useState(false)
  const [selectTemplate, setSelectTemplate] = useState({})
  const [type, setType] = useState('van_phong_co_quan')
  const [form] = Form.useForm()
  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      try {
        await getRuiRoNhanDienType()
        type[0] && (await getListTemplate(type))
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])
  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      try {
        await getListTemplate(type)
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoading(false)
      }
    })()
  }, [type])

  const columns = [
    {
      title: '',
      dataIndex: 'name',
      width: '5%',
      render: (text, record) => (
        <Radio
          checked={record.id === selectTemplate.id}
          // onClick={() => setSelectTemplate(record)}
        />
      ),
    },
    {
      title: 'RỦI RO NHẬN DIỆN',
      dataIndex: 'rui_ro_nhan_dien',
      width: '25%',
      render: text => (
        <RichText htmlText={text || ''}></RichText>
      ),
    },
    {
      title: 'NGUYÊN NHÂN',
      dataIndex: 'nguyen_nhan',
      width: '25%',
      render: text => (
        <RichText htmlText={text || ''}></RichText>
      ),
    },
    {
      title: 'BIỆN PHÁP PHÒNG NGỪA, GIẢM THIỂU ',
      dataIndex: 'bien_phap_phong_ngua_giam_thieu',
      width: '55%',
      render: text => (
        <RichText htmlText={text || ''}></RichText>
      ),
    },
  ]

  return (
    <Modal
      style={{}}
      width={1000}
      destroyOnClose={true}
      style={{ top: 60,zIndex: 500, position: 'relative' }}
      title={'Rủi ro nhận diện'}
      visible={isVisible}
      maskClosable={false}
      // transitionName=""
      // maskTransitionName=""
      onCancel={() => {
        handleCancelModal()
      }}
      footer={null}>
      <Select
        style={{ width: '50%', marginBottom: 16 }}
        value={type}
        onChange={value => setType(value)}>
        {ruiRoNhanDienType?.map(truiRoNhanDienTypeype => (
          <Select.Option key={truiRoNhanDienTypeype.code} value={truiRoNhanDienTypeype.code}>
            {truiRoNhanDienTypeype.name}
          </Select.Option>
        ))}
      </Select>

      <TemplateTable>
        <TableComponent
          columns={columns}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                setSelectTemplate(record)
              },
            }
          }}
          dataSource={listTemplate}
          size="small"
          // columns={tableColumns}
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Không có dữ liệu'}
              />
            ),
          }}
          // loading={loadingAnimationStore.tableLoading}
        />
      </TemplateTable>

      <FormActionFooter style={{ marginBottom: 0 }}>
        <Button icon={<CloseOutlined />} danger onClick={handleCancelModal}>
          Đóng
        </Button>
        <Button
          style={{ marginLeft: 10 }}
          type={'primary'}
          htmlType={'submit'}
          onClick={() => {
            setRuiRoNhanDien(selectTemplate)
            handleCancelModal()
          }}>
          Chọn
        </Button>
      </FormActionFooter>

      <SpinnerInlineComponent
        sizeSpin={'small'}
        tip="Đang tải dữ liệu..."
        isLoading={isLoading}
        backGround={'#ffffffc7'}
      />
    </Modal>
  )
}

export default RuiRoNhanDienTemplate
