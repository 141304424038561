import React, { useCallback } from 'react'
import { inject, observer } from 'mobx-react'
import { ModalBody, ModalWrapper } from './SelectGroupMemberPopupStyled'
import { Button, Popconfirm, Space, Tag, Tooltip } from 'antd'
import {
  CloseOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { blue, yellowPrimary } from '../../color'
import { AvatarHasName } from '../Common/Avatar'
import { EmptyText } from '../Common/CellText'
import SelectGroupMemberBox from './SelectGroupMemberBox'
import {
  ASSIGNEE_TYPE,
} from '../../constants'

const SelectGroupMemberPopup = props => {
  const {
    isVisibleSelectGroupPopup,
    handleSubmitSelectGroup,
    handleCancelSelectGroup,
    selectPeopleStore,
  } = props

  const { selectGroupData, setSelectGroupData } = selectPeopleStore

  const onCancelSelectGroup = () => {
    handleCancelSelectGroup()
  }

  const handleRemoveSelect = useCallback(
    group => {
      setSelectGroupData({
        ...selectGroupData,
        [ASSIGNEE_TYPE.HANDLER]: selectGroupData[ASSIGNEE_TYPE.HANDLER].filter(
          el => el.id !== group.id
        ),
      })
    },
    [selectGroupData]
  )

  return (
    <ModalWrapper
      maskClosable={false}
      okText={'Đồng ý'}
      cancelText={'Hủy bỏ'}
      width={1200}
      footer={null}
      style={{ top: 20 }}
      title={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <span>Bảng chọn nhóm</span>
          <Space>
            <Popconfirm
              placement="bottom"
              title={'Bạn có chắc chắn muốn xóa hết người được chọn không ?'}
              onConfirm={onCancelSelectGroup}
              okText="Đồng ý"
              cancelText="Không" zIndex={1600}>
              
              <Button
                danger
                icon={<WarningOutlined style={{ color: yellowPrimary }} />}>
                Hủy chọn
              </Button>
            </Popconfirm>
            <Button type={'primary'} onClick={handleSubmitSelectGroup}>
              Đồng ý
            </Button>
            <Tooltip title={'Đóng'}>
              <CloseOutlined onClick={handleSubmitSelectGroup} />
            </Tooltip>
          </Space>
        </div>
      }
      closable={false}
      forceRender={true}
      visible={isVisibleSelectGroupPopup}>
      <ModalBody>
        <div className={'list-box'}>
          <label
            style={{
              width: 100,
              display: 'flex',
              alignItems: 'center',
              fontWeight: 500,
            }}>
            <UnorderedListOutlined style={{ marginRight: 4, color: blue }} />
            Danh sách:
          </label>
          {selectGroupData[ASSIGNEE_TYPE.HANDLER].length > 0 ? (
            selectGroupData[ASSIGNEE_TYPE.HANDLER].map((el, index) => {
              return (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={index}
                  closable
                  onClose={() => handleRemoveSelect(el)}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={<TeamOutlined />}
                  />
                </Tag>
              )
            })
          ) : (
            <EmptyText>
              <span style={{ lineHeight: '34px' }}>
                Chưa có nhóm nào được chọn
              </span>
            </EmptyText>
          )}
        </div>
        <div className={'modal-body'}>
          <SelectGroupMemberBox />
          <div />
        </div>
      </ModalBody>
    </ModalWrapper>
  )
}

SelectGroupMemberPopup.propTypes = {}

export default inject('selectPeopleStore', 'userStore')(observer(SelectGroupMemberPopup))
