import { ConfigProvider, message } from 'antd'
import viVN from 'antd/es/locale/vi_VN'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/vi'
import React, { useEffect, useState } from 'react'
import LoadingSpinner from './components/LoadingSpinner'
import ThemeProvider from './providers/ThemeProvider'
// React Router
import { createBrowserHistory } from 'history'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
// MobX
import { Provider } from 'mobx-react'
import 'mobx-react-lite/batchingForReactDom'
import contentTemplateStore from './pages/ContentTemplate/contentTemplateStore'
import isoDocumentStore from './pages/ISODocumentPage/isoDocumentStore'
import kpiQuarterPageStore from './pages/KpiQuarterPage/KpiQuarterPageStore'
import listHistoryLogStore from './pages/ListHistoryLog/ListHistoryLogStore'
import consultStore from './stores/ConsultStore'
import accountEditStore from './stores/accountEditStore'
import accountStore from './stores/accountStore'
import aclStore from './stores/aclStore'
import actionStore from './stores/actionStore'
import authenticationStore from './stores/authenticationStore'
import authorityStore from './stores/authorityStore'
import bookStore from './stores/bookStore'
import chamCongStore from './stores/chamCongStore' //Bỏ, thay bằng ChamCongStore
import chuTruongCommentStore from './stores/chuTruongCommentStore'
import chuTruongStore from './stores/chuTruongStore'
import commandStore from './stores/commandStore'
import commonServiceStore from './stores/commonServiceStore'
import commonStore from './stores/commonStore'
import companyStore from './stores/companyStore'
import connectedCommitteeDocumentStore from './stores/connectedCommitteeDocumentStore'
import connectedDocumentStore from './stores/connectedDocumentStore'
import consultCommentStore from './stores/consultCommentStore'
import departmentStore from './stores/departmentStore'
import documentAccessibilityStore from './stores/documentAccessibilityStore'
import documentStore from './stores/documentStore'
import electronicDocumentStore from './stores/electronicDocumentStore'
import fileStore from './stores/fileStore'
import internalDocumentStore from './stores/internalDocumentStore'
import internalMessageStore from './stores/internalMessageStore'
import kpiStore from './stores/kpiStore'
import leavePageStore from './stores/leavePageStore'
import loadingAnimationStore from './stores/loadingAnimationStore'
import messagesStore from './stores/messagesStore'
import moduleStore from './stores/moduleStore'
import notificationStore from './stores/notificationStore'
import organizationStore from './stores/organizationStore'
import payslipStore from './stores/payslipStore'
import phieuTrinhFakeDataStore from './stores/phieuTrinhFakeDataStore'
import positionStore from './stores/positionStore'
import proposalAdvanceStore from './stores/proposalAdvanceStore'
import proposalSalaryStore from './stores/proposalSalaryStore'
import proposalStore from './stores/proposalStore'
import riskStore from './stores/riskStore'
import roleStore from './stores/roleStore'
import salaryStore from './stores/salaryStore'
import sampleDocumentStore from './stores/sampleDocumentStore'
import selectPeopleStore from './stores/selectPeopleStore'
import selectUserStore from './stores/selectUserStore'
import signAccountStore from './stores/signAccountStore'
import signedDocumentStore from './stores/signedDocumentStore'
import staffStore from './stores/staffStore'
import statisticStore from './stores/statisticStore'
import taskManagementCommentStore from './stores/taskManagementCommentStore'
import taskManagementStore from './stores/taskManagementStore'
import taskStore from './stores/taskStore'
import timesheetsStore from './stores/timesheetsStore' //Bỏ, thay bằng ChamCongStore
import tintucStore from './stores/tintucStore'
import userGroupStore from './stores/userGroupStore'
import userStore from './stores/userStore'
import userTrucStore from './stores/userTrucStore'
import voteStore from './stores/voteStore'
import workScheduleStore from './stores/workScheduleStore'
import workStore from './stores/workStore'
// Pages
import DashboardPageV2 from './pages/DashboardPageV2'
import NotFoundPage from './pages/NotFoundPage'

//ProposalPage
//Demo
import DemoTagPage from './pages/Demo/index'

// Styling
import './App.less'

import Report from './components/MainSidebar/Report'
import { normalRoutes, routes } from './routes'

//keycloak
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web'
import { useSSO } from './config'
import keycloak from './keycloak'
import approvalTemplateStore from './stores/approvalTemplateStore'

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem('jwt') ||
      sessionStorage.getItem('jwt') ||
      sessionStorage.getItem('jwt') ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
)

const history = createBrowserHistory()

const rootStore = {
  loadingAnimationStore,
  commonStore,
  authenticationStore,
  userStore,
  userGroupStore,
  commandStore,
  companyStore,
  accountStore,
  accountEditStore,
  positionStore,
  notificationStore,
  departmentStore,
  connectedDocumentStore,
  internalDocumentStore,
  connectedCommitteeDocumentStore,
  fileStore,
  authorityStore,
  bookStore,
  staffStore,
  workScheduleStore,
  taskManagementStore,
  sampleDocumentStore,
  roleStore,
  taskManagementCommentStore,
  statisticStore,
  organizationStore,
  documentStore,
  leavePageStore,
  phieuTrinhFakeDataStore,
  timesheetsStore,
  chamCongStore,
  payslipStore,
  proposalStore,
  approvalTemplateStore,
  taskStore,
  selectUserStore,
  consultStore,
  consultCommentStore,
  signedDocumentStore,
  signAccountStore,
  tintucStore,
  workStore,
  chuTruongCommentStore,
  chuTruongStore,
  electronicDocumentStore,
  selectPeopleStore,
  messagesStore,
  internalMessageStore,
  userTrucStore,
  kpiQuarterPageStore,
  kpiStore,
  salaryStore,
  aclStore,
  commonServiceStore,
  moduleStore,
  proposalAdvanceStore,
  proposalSalaryStore,
  listHistoryLogStore,
  isoDocumentStore,
  contentTemplateStore,
  documentAccessibilityStore,
  voteStore,
  riskStore,
  actionStore,
}

// axios.defaults.timeout = 600000
axios.defaults.timeout = 999000
axios.interceptors.request.use(
  config => config,
  error => Promise.reject(error)
)
axios.interceptors.response.use(
  response => response,
  error => {
    console.log(error)
    if (axios.isCancel(error)) {
      return Promise.resolve()
    }
    if (error?.response?.status === 408 || error?.code === 'ECONNABORTED') {
      message.config({
        maxCount: 1,
      })
      message.error('Request timeout!')
      authenticationStore.userLogout()
      history.push('/login')
    }

    var errorMessage = error?.response?.data?.errorMessage?.messages
    if (errorMessage) {
      errorMessage.errorCode = error?.response?.data?.errorMessage?.errorCode
    }

    return Promise.reject(
      errorMessage || {
        vi: 'Đã có lỗi xảy ra trong quá trình kết nối!',
      }
    )
  }
)

moment.locale('vi', {
  week: {
    dow: 1,
  },
})

const saveToken = tokenValue => {
  if (tokenValue) {
    let objToken = {
      access_token: tokenValue,
    }
    localStorage.setItem('jwt', JSON.stringify(objToken))
    authenticationStore.setAppToken(JSON.stringify(objToken))
    // console.log('Update access_token', objToken)
  }
}

const App = () => {
  const [ready, setReady] = useState(false)

  const eventLogger = (event, error) => {
    // console.log('onKeycloakEvent', event, error)

    if (event.toString() === 'onReady') {
      setReady(true)
    }
  }

  const tokenLogger = tokens => {
    // console.log('tokenLogger', tokens)

    if (tokens.token) {
      saveToken(tokens.token)
    }
  }

  const keycloakProviderInitConfig = {
    onLoad: 'login-required',
    silentCheckSsoRedirectUri:
      window.location.origin + '/assets/silent-check-sso.html',
  }

  return useSSO ? (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakProviderInitConfig}
      onEvent={eventLogger}
      onTokens={tokenLogger}>
      {ready ? <AppRouter /> : <p />}
    </ReactKeycloakProvider>
  ) : (
    <AppRouter />
  )
}

export const AppRouter = () => {
  const fakeKeycloak = {
    keycloak: { token: authenticationStore.appToken },
  }
  const { keycloak, initialized } = useSSO ? useKeycloak() : fakeKeycloak

  useEffect(() => {
    console.log('useSSO', useSSO, process.env.REACT_APP_USE_SSO)
  }, [useSSO])

  useEffect(
    () => {
      // console.log('useEffect. keycloak.token', keycloak.token)
      if (useSSO) {
        saveToken(keycloak.token)
      }
    },
    useSSO ? [keycloak.token] : [authenticationStore.appToken]
  )

  useEffect(
    () => {
      // console.log('useEffect. check session login')
      if (!keycloak.token) return
      ;(async () => {
        loadingAnimationStore.showSpinner(true)
        try {
          const { data } = await authenticationStore.checkCurrentUser()
          await Promise.all([
            aclStore.getACLDetailByUser(data.username),
            commandStore.getCommandList(),
            accountStore.getCurrentUserAccount(),
            moduleStore.getModuleList(),
            companyStore.getCompanyList(),
          ])
        } catch (err) {
          history.push('/login')
          localStorage.clear()
        } finally {
          loadingAnimationStore.showSpinner(false)
        }
      })()
    },
    useSSO ? [keycloak.token] : [authenticationStore.appToken]
  )

  useEffect(
    () => {
      if (!keycloak.token) return // loadingAnimationStore.showSpinner(true)
      ;(async () => {
        try {
          await Promise.allSettled([
            userStore.getMentionUserList(),
            notificationStore.getUnreadNotificationCount(),
            notificationStore.getUnreadNewsCount(),
            authenticationStore.getCurrentUserAvatar(),
          ])
        } catch (err) {
          console.log(err)
          // message.error(err?.vi || 'Đã có lỗi xảy ra!')
        } finally {
          // loadingAnimationStore.showSpinner(false)
        }
      })()
    },
    useSSO ? [keycloak.token] : [authenticationStore.appToken]
  )

  return (
    <Provider {...rootStore}>
      <ThemeProvider>
        <ConfigProvider locale={viVN}>
          <Router history={history}>
            <Switch>
              {normalRoutes.map(route => (
                <Route
                  key={route.path}
                  exact
                  path={route.path}
                  component={route.component}
                />
              ))}

              <ProtectedRoute exact path={'/'} component={DashboardPageV2} />
              <ProtectedRoute
                exact
                path={'/dashboard'}
                component={DashboardPageV2}
              />

              {routes.map(route => (
                <ProtectedRoute
                  key={route.path}
                  exact
                  path={route.path}
                  component={route.component}
                />
              ))}

              <ProtectedRoute exact path={'/demo'} component={DemoTagPage} />

              <Route component={NotFoundPage} />
            </Switch>
          </Router>
        </ConfigProvider>
        <LoadingSpinner />
        <div
          style={{
            position: 'fixed',
            top: 17,
            right: 10,
            zIndex: 10000000001,
          }}>
          <Report />
        </div>
      </ThemeProvider>
    </Provider>
  )
}

export default App
