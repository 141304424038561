import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import RichText from '../../../components/RichEditor/RichText'
import { compare } from './diff_compare_object'
import TableComponent from '../../../components/Common/TableComponent'
import uuid from 'uuid'

const DiffTable = props => {
  const { type, dataBefore, dataAfter,riskDetail } = props

  const [diffList, setDiffList] = useState([])

  useEffect(() => {
    setDiffList(compare(type, JSON.parse(dataBefore), JSON.parse(dataAfter),riskDetail))
  }, [])

  const columns = [
    {
      title: 'Trường dữ liệu',
      width: 150,
      render: record => <RichText htmlText={record.field}></RichText>,
    },
    {
      title: 'Trước thay đổi',
      width: 300,
      render: record => (
        <RichText htmlText={record.fieldValueBefore}></RichText>
      ),
    },
    {
      title: 'Sau thay đổi',
      width: 300,
      render: record => <RichText htmlText={record.fieldValueAfter}></RichText>,
    },
  ]

  return (
    <div>
      <TableComponent
        rowKey={() => uuid()}
        dataSource={diffList}
        columns={columns}
        pagination={false}
      />
    </div>
  )
}

DiffTable.propTypes = {
  dataBefore: PropTypes.string,
  dataAfter: PropTypes.string,
  type: PropTypes.string,
}

export default DiffTable
