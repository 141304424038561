import { Tag } from 'antd'
import React from 'react'
import utils from '../../utils'
import './RiskSelecUsertListStyle'
import { RiskSelectList, UserTag } from './RiskSelecUsertListStyle'

const RiskSelectUserList = ({ userList }) => {
  return (
    <RiskSelectList>
      {' '}
      {userList?.map(user => (
        <div
          key={user?.id}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <UserTag>
            {utils.getNameInCapitalize(user?.full_name || user?.name_uppercase)}
          </UserTag>
          {user?.status === 'PENDING' ? (
            <Tag
              color={'orange'}
              style={{
                marginTop: 4,
                borderRadius: 10,
              }}>
              Chờ duyệt
            </Tag>
          ) : user?.status === 'APPROVED' ? (
            <Tag color={'green'} style={{ marginTop: 4, borderRadius: 10 }}>
              Đã duyệt
            </Tag>
          ) :user?.status === 'REJECTED' ? (
            <Tag color={'red'} style={{ marginTop: 4, borderRadius: 10 }}>
              Đã từ chối
            </Tag>
          ):''}
        </div>
      ))}
    </RiskSelectList>
  )
}

export default RiskSelectUserList
