import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ACL_ACTION_TYPE,
  ASSIGNEE_TYPE,
  STATUS_DEFAULT,
  VIEWLOG_DATE,
  WORK_STATUS,
} from '../../../constants'
import { inject, observer } from 'mobx-react'
import PopoverWatchTime from '../../../components/PopoverWatchTime'
import { EmptyText } from '../../../components/Common/CellText'
import FileItem from '../../../components/Common/FileItemComponent/FileItem'
import utils from '../../../utils'
import {
  HeadingButtonWrapper,
  InfoDrawerRight,
  InfoDrawerRightWrapper,
  ListSubWork,
  OpinionItem,
  SubWork,
  SubWorkTitle,
  UpdateVotePopover,
} from './DrawerWorkStyled'
import {
  Button,
  Col,
  Dropdown,
  List,
  Menu,
  message,
  Modal,
  Popover,
  Row,
} from 'antd'
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckOutlined,
  ContainerOutlined,
  DeleteOutlined,
  DislikeFilled,
  EditOutlined,
  FormOutlined,
  HistoryOutlined,
  LikeFilled,
  MoreOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
  QuestionCircleOutlined,
  RetweetOutlined,
} from '@ant-design/icons'
import { blue, greenPrimary } from '../../../color'
import AccessControlAction from '../../../components/AccessControlAction/AccessControlAction'
import selectPeopleStore from '../../../stores/selectPeopleStore'
import loadingAnimationStore from '../../../stores/loadingAnimationStore'
import workStore from '../../../stores/workStore'
import aclStore from '../../../stores/aclStore'
import authenticationStore from '../../../stores/authenticationStore'
import queryString from 'query-string'
import voteStore from '../../../stores/voteStore'
import useOpenItem from '../../../hooks/useOpenItem'
import { PopupEditPersonVote } from '../PopupVote'

const { confirm } = Modal

const ContentDrawerRight = props => {
  const history = useHistory()
  const { currentUser } = authenticationStore
  const { queryParams, commentWorkList } = workStore
  const [disableChange, setDisableChange] = useState(true)
  const {
    isOpen: isVisibleEditPersonVote,
    handleOpen: handleOpenEditPersonVote,
    handleClose: handleCloseEditPersonVote,
  } = useOpenItem()
  const {
    screenWidth,
    breakpoint,
    work_type,
    handlerList,
    followerList,
    attachments,
    outgoingDocument,
    parent_id,
    status,
    subwork,
    handleOpenWorkDetail,
    setIsVisiblePopupWorkForm,
    workList,
    code,
    assignees,
    openPopupUpdateWork,
    onCloseDrawerWork,
    openPopupAddPersonWork,
    openPopupAddPersonVote,
    openPopupElectronicRelease,
    workDetail,
    setDocumentHistory,
    setIsVisibleHistory,
    title,
    created_at,
  } = props
  const { voteDetail } = voteStore
  useEffect(() => {
    let disable = false
    commentWorkList &&
      commentWorkList.forEach(comment => {
        console.log(comment.isHidden)
        if (!comment.deleted) {
          disable = true
        }
      })
    setDisableChange(disable)
  }, [commentWorkList])

  useEffect(() => {
    ;(async () => {
      try {
        code && (await voteStore.getVoteDetail(code))
      } catch (error) {
        console.log(error)
        // message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
      }
    })()
  }, [code])
  useEffect(() => {
    return () => {
      voteStore.clearStore()
    }
  }, [])

  const handleUpdateStatusWork = async status => {
    const parentId = utils.getParameterByName('parent_id')
    loadingAnimationStore.setTableLoading(true)
    loadingAnimationStore.setShowSpinInline(true)
    try {
      await workStore.updateStatusWork(code, status)
      const response = await workStore.getWorkDetail(code)
      if (parentId) {
        await workStore.getListSubWork(parentId)
      } else {
        const newWorkList = [...workList]
        const indexParentTarget = workList.findIndex(item => item.code === code)
        if (indexParentTarget !== -1) {
          newWorkList[indexParentTarget] = {
            ...response.data,
            has_subwork: response.data.subwork?.length > 0,
          }
        }
        workStore.setWorkList(newWorkList)
      }
      if (status === STATUS_DEFAULT.COMPLETE) {
        return message.success('Bạn đã đánh dấu công việc hoàn thành!')
      }
      message.success('Bạn đã đánh dấu công việc là đang thực hiện!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.setTableLoading(false)
      loadingAnimationStore.setShowSpinInline(false)
    }
  }

  const showConfirmDeleteWork = () => {
    confirm({
      title: 'Bạn có muốn xóa công việc này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDeleteWork()
      },
    })
  }

  const handleOpenPopupAddPersonWork = () => {
    openPopupAddPersonWork()
    const userList = assignees
      ? assignees.filter(
          el =>
            el.assignee_type === ASSIGNEE_TYPE.USER &&
            el.permission !== ASSIGNEE_TYPE.CREATE
        )
      : []
    const departmentList = assignees
      ? assignees.filter(el => el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT)
      : []
    const groupList = assignees
      ? assignees.filter(el => el.assignee_type === ASSIGNEE_TYPE.GROUP)
      : []
    selectPeopleStore.setSelectUserData(
      selectPeopleStore.convertSelectDataObj(userList)
    )
    selectPeopleStore.setSelectDepartmentData(
      selectPeopleStore.convertSelectDataObj(departmentList)
    )
    selectPeopleStore.setSelectGroupData(
      selectPeopleStore.convertSelectDataObj(groupList)
    )
  }
  const handleOpenPopupAddPersonVote = () => {
    openPopupAddPersonVote()
    selectPeopleStore.setAssigneePopupType(ASSIGNEE_TYPE.USER)
    selectPeopleStore.setSelectUserData(
      selectPeopleStore.convertSelectDataObj([])
    )
  }
  const handleOpenPopupeditPersonVote = () => {
    handleOpenEditPersonVote()
    selectPeopleStore.setAssigneePopupType(ASSIGNEE_TYPE.USER)
    selectPeopleStore.setSelectUserData(
      selectPeopleStore.convertSelectDataObj(voteDetail)
    )
  }

  const handleDeleteWork = async () => {
    const parentId = utils.getParameterByName('parent_id')
    loadingAnimationStore.showSpinner(true)
    try {
      await workStore.deleteWork(code)
      if (parentId) {
        await workStore.getListSubWork(parentId)
        await workStore.getWorkDetail(parentId)
        window.history.replaceState(null, null, `/works/view/${parentId}`)
      } else {
        const indexParentTarget = workList.findIndex(item => item.code === code)
        workList.splice(indexParentTarget, 1)
        onCloseDrawerWork()
        const queryStringStringify = queryString.stringify(
          {
            ...queryParams,
            parent_id: null,
          },
          {
            skipNull: true,
          }
        )
        window.history.replaceState(
          null,
          null,
          `/works?${queryStringStringify}`
        )
      }
      message.success('Xóa công việc thành công!')
    } catch (err) {
      console.log(err)
      if (err?.errorCode === 'WORK-106') {
        message.error('Công việc này đã được gán ở KPI nên không thể xóa')
      } else message.error(err?.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const exportWork = () => {
    utils.exportPDF('divToPrint', title)
  }

  const menuButton = (
    <Menu>
      <>
        {status !== STATUS_DEFAULT.COMPLETE &&
          aclStore.checkAccessControlAction(
            utils.getParameterByName('parent_id')
              ? ACL_ACTION_TYPE.sua_cong_viec_phu__WORK
              : ACL_ACTION_TYPE.edit__WORK
          ) && (
            <Menu.Item
              key="edit"
              icon={<EditOutlined style={{ color: blue }} />}
              onClick={() => {
                openPopupUpdateWork()
                const userList = assignees
                  ? assignees.filter(
                      el =>
                        el.assignee_type === ASSIGNEE_TYPE.USER &&
                        el.permission !== ASSIGNEE_TYPE.CREATE
                    )
                  : []
                const departmentList = assignees
                  ? assignees.filter(
                      el => el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT
                    )
                  : []
                const groupList = assignees
                  ? assignees.filter(
                      el => el.assignee_type === ASSIGNEE_TYPE.GROUP
                    )
                  : []
                selectPeopleStore.setSelectUserData(
                  selectPeopleStore.convertSelectDataObj(userList)
                )
                selectPeopleStore.setSelectDepartmentData(
                  selectPeopleStore.convertSelectDataObj(departmentList)
                )
                selectPeopleStore.setSelectGroupData(
                  selectPeopleStore.convertSelectDataObj(groupList)
                )
              }}
              disabled={disableChange}>
              <span style={{ color: blue }}>Sửa công việc</span>
            </Menu.Item>
          )}

        {status !== STATUS_DEFAULT.COMPLETE &&
          (currentUser?.username ===
            assignees?.find(el => el.permission === ASSIGNEE_TYPE.CREATE)
              .assignee_code ||
            handlerList.some(el => currentUser?.username === el.id)) && (
            <Menu.Item
              key="add_assignee"
              icon={<PlusCircleOutlined style={{ color: blue }} />}
              onClick={() => {
                handleOpenPopupAddPersonWork()
              }}>
              <span style={{ color: blue }}>Thêm người</span>
            </Menu.Item>
          )}

        {status !== STATUS_DEFAULT.COMPLETE &&
          !parent_id &&
          aclStore.checkAccessControlAction(
            ACL_ACTION_TYPE.phat_hanh__WORK
          ) && (
            <Menu.Item
              key="publish_document"
              icon={<ContainerOutlined style={{ color: blue }} />}
              onClick={() => {
                openPopupElectronicRelease()
              }}>
              <span style={{ color: blue }}>Phát hành điện tử</span>
            </Menu.Item>
          )}
        {status !== STATUS_DEFAULT.COMPLETE && (
          <Menu.Item
            key="create_vote"
            icon={<FormOutlined style={{ color: blue }} />}
            onClick={() => {
              voteStore?.voteDetail?.length > 0
                ? handleOpenPopupeditPersonVote()
                : handleOpenPopupAddPersonVote()
            }}>
            <span style={{ color: blue }}>Lấy ý kiến</span>
          </Menu.Item>
        )}
        <Menu.Item
          key="history"
          icon={<HistoryOutlined style={{ color: blue }} />}
          onClick={() => {
            setDocumentHistory({
              code: workDetail.code,
            })
            setIsVisibleHistory(true)
          }}>
          <span style={{ color: blue }}>Lịch sử</span>
        </Menu.Item>

        <Menu.Item
          key="print"
          icon={<PrinterOutlined style={{ color: blue }} />}
          onClick={() => {
            exportWork()
          }}>
          <span style={{ color: blue }}>In</span>
        </Menu.Item>

        {status !== STATUS_DEFAULT.COMPLETE &&
          currentUser?.username ===
            assignees?.find(el => el.permission === ASSIGNEE_TYPE.CREATE)
              .assignee_code &&
          aclStore.checkAccessControlAction(
            utils.getParameterByName('parent_id')
              ? ACL_ACTION_TYPE.xoa_cong_viec_phu__WORK
              : ACL_ACTION_TYPE.delete__WORK
          ) && (
            <Menu.Item
              key="delete"
              danger
              icon={<DeleteOutlined />}
              onClick={showConfirmDeleteWork}
              disabled={disableChange}>
              <span>Xóa công việc</span>
            </Menu.Item>
          )}
      </>
    </Menu>
  )
  const handleAgree = async userCode => {
    await voteStore.updateVote(userCode, { result: true })
    await voteStore.getVoteDetail(code)
  }
  const handleDisagree = async userCode => {
    await voteStore.updateVote(userCode, { result: false })
    await voteStore.getVoteDetail(code)
  }

  return (
    <InfoDrawerRightWrapper isMobile={screenWidth < breakpoint}>
      <Row gutter={8}>
        <Col flex={'auto'}>
          {status !== STATUS_DEFAULT.COMPLETE ? (
            <Button
              type={'primary'}
              icon={<CheckOutlined />}
              onClick={() => handleUpdateStatusWork(STATUS_DEFAULT.COMPLETE)}
              style={{
                backgroundColor: '#3aaf50',
                borderColor: '#3aaf50',
                width: '100%',
              }}>
              Hoàn thành
            </Button>
          ) : (
            <Button
              icon={<RetweetOutlined />}
              style={{ width: '100%' }}
              onClick={() => handleUpdateStatusWork(STATUS_DEFAULT.PENDING)}>
              Chưa hoàn thành
            </Button>
          )}
        </Col>
        <Col>
          <HeadingButtonWrapper>
            <Dropdown overlay={menuButton} trigger={['click']}>
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          </HeadingButtonWrapper>
        </Col>
      </Row>
      <InfoDrawerRight>
        <dt>
          {work_type === WORK_STATUS.ELECTRONIC
            ? 'Lãnh đạo ký duyệt'
            : 'Người xử lý'}
        </dt>
        <dd>
          {handlerList.length > 0 ? (
            <PopoverWatchTime
              userList={handlerList}
              title={
                work_type === WORK_STATUS.ELECTRONIC
                  ? 'Lãnh đạo ký duyệt'
                  : 'Danh sách người xử lý'
              }
              isFullViewer={true}
              compared_date={VIEWLOG_DATE.work_read_until}
              typeId={workDetail.code}
              created_at={created_at}
            />
          ) : (
            <EmptyText>Không có.</EmptyText>
          )}
        </dd>
        <dt>Người theo dõi</dt>
        <dd>
          {followerList.length > 0 ? (
            <PopoverWatchTime
              userList={followerList}
              title={'Danh sách người theo dõi'}
              isFullViewer={true}
              compared_date={VIEWLOG_DATE.work_read_until}
              typeId={workDetail.code}
              created_at={created_at}
            />
          ) : (
            <EmptyText>Không có người theo dõi.</EmptyText>
          )}
        </dd>
        <dt>Tài liệu đính kèm</dt>
        <dd>
          {attachments && attachments.length > 0 ? (
            attachments.map(file => (
              <FileItem
                key={file.file_id}
                file_id={file.file_id}
                file_name={file.file_name}
                file_type={utils.getExtensionFile(file.file_name)}
              />
            ))
          ) : (
            <EmptyText>Không có tài liệu đính kèm.</EmptyText>
          )}
        </dd>

        {voteStore?.voteDetail.length > 0 && (
          <>
            <div
              style={{
                height: 0.5,
                backgroundColor: '#f0f0f0',
                marginBottom: 10,
              }}
            />
            <dt>Ý kiến</dt>
            <dd>
              <div>
                {voteStore?.voteDetail?.map(user =>
                  currentUser.username === user.assignee_code ? (
                    <Popover
                      content={
                        <UpdateVotePopover>
                          <div className="content-title">Gửi ý kiến</div>
                          <div className="content-body">
                            <div onClick={() => handleAgree(user.code)}>
                              <div>
                                <span className="thumb-up">
                                  <LikeFilled />
                                </span>
                              </div>
                              <div>Đồng ý</div>
                            </div>
                            <div onClick={() => handleDisagree(user.code)}>
                              <div>
                                <span className="thumb-down">
                                  <DislikeFilled />
                                </span>
                              </div>
                              <div>Không đồng ý</div>
                            </div>
                          </div>
                        </UpdateVotePopover>
                      }
                      title={false}
                      trigger="hover">
                      <OpinionItem>
                        {user.result === true ? (
                          <span className="vote-icon thumb-up">
                            <LikeFilled />
                            <div>Đồng ý</div>
                          </span>
                        ) : user.result === false ? (
                          <span className="vote-icon thumb-down">
                            <DislikeFilled />
                            <div>Ko đồng ý</div>
                          </span>
                        ) : (
                          <span className="vote-icon">
                            <span className="empty-circle"></span>
                          </span>
                        )}
                        <span
                          style={{
                            fontSize: 12,
                            textTransform: 'capitalize',
                          }}>
                          {user.name_uppercase?.toLowerCase()}
                        </span>
                      </OpinionItem>
                    </Popover>
                  ) : (
                    <OpinionItem>
                      {user.result === true ? (
                        <span className="vote-icon thumb-up">
                          <LikeFilled />
                          <div>Đồng ý</div>
                        </span>
                      ) : user.result === false ? (
                        <span className="vote-icon thumb-down">
                          <DislikeFilled />
                          <div>Ko đồng ý</div>
                        </span>
                      ) : (
                        <span className="vote-icon">
                          <span className="empty-circle"></span>
                        </span>
                      )}

                      <span
                        style={{
                          fontSize: 12,
                          textTransform: 'capitalize',
                        }}>
                        {user.name_uppercase?.toLowerCase()}
                      </span>
                    </OpinionItem>
                  )
                )}
              </div>
            </dd>
          </>
        )}
        {outgoingDocument && (
          <>
            <dt>Văn bản liên kết</dt>
            <dd
              onClick={() =>
                history.push(
                  `/internal-document/outgoing-document/view/${outgoingDocument.document_code}`
                )
              }>
              <a>{outgoingDocument.document_name}</a>
            </dd>
          </>
        )}
      </InfoDrawerRight>
      <div
        style={{
          height: 0.5,
          backgroundColor: '#f0f0f0',
          marginBottom: 10,
        }}
      />
      {!parent_id && (
        <SubWork>
          <SubWorkTitle>
            <span>
              Công việc phụ:
              {status !== STATUS_DEFAULT.COMPLETE && (
                <AccessControlAction
                  aclActionType={ACL_ACTION_TYPE.them_cong_viec_phu__WORK}>
                  <PlusCircleOutlined
                    style={{ cursor: 'pointer', color: blue, marginLeft: 6 }}
                    onClick={() => {
                      selectPeopleStore.clearSelected()
                      setIsVisiblePopupWorkForm(true)
                    }}
                  />
                </AccessControlAction>
              )}
            </span>
          </SubWorkTitle>
          <ListSubWork
            size={'small'}
            locale={{
              emptyText: <EmptyText>Không có công việc phụ</EmptyText>,
            }}
            dataSource={subwork}
            renderItem={item => (
              <List.Item
                key={item.code}
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenWorkDetail(item.code)}>
                <div>
                  {item.status === STATUS_DEFAULT.COMPLETE ? (
                    <CheckCircleFilled
                      style={{ marginRight: 8, color: greenPrimary }}
                    />
                  ) : (
                    <CheckCircleOutlined style={{ marginRight: 8 }} />
                  )}
                  {item.title}
                </div>
              </List.Item>
            )}
          />
        </SubWork>
      )}
      <PopupEditPersonVote
        isVisibleEditPersonVote={isVisibleEditPersonVote}
        handleCloseEditPersonVote={() => {
          handleCloseEditPersonVote()
        }}
      />
    </InfoDrawerRightWrapper>
  )
}

ContentDrawerRight.propTypes = {}

export default inject('voteStore')(observer(ContentDrawerRight))
