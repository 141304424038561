import { action, observable } from 'mobx'
import React from 'react'
import { DeparmentRequest } from '../requests/DepartmentRequest'
import { ASSIGNEE_TYPE } from '../constants'
import { UserGroupRequest } from '../requests/UserGroupRequest'

class SelectPeopleStore {
  @observable departmentWithUserList = []
  @observable originDepartmentWithUserList = []
  @observable originGroupList = []
  @observable groupList = []
  @observable selectPopupName = 'Bảng chọn'
  @observable assigneePopupType = null
  @observable companyCode = null
  @observable selectGroupMemberData = []

  @observable setSelectGroupMemberData = type => {
    this.selectGroupMemberData = type
  }

  @observable clearSelectGroupMemberData = () => {
    this.selectGroupMemberData = []
  }

  @observable setAssigneePopupType = type => {
    this.assigneePopupType = type
    if (!type) {
      this.selectPopupName = 'Bảng chọn người dùng'
    }
  }

  @observable paramDepartmentSort =
    'departmentCode,desc,CDTCT,BCNTT,VPCQTCT,BTGTT,BPC%26QTRR,BTTKH,BCB%26DVHH,BVTB,BKTKTNB,BTKTH,BTCKT,BTCNS,BDH,HDQT'

  @action getDepartmentWithUsers = () => {
    return new Promise((resolve, reject) => {
      DeparmentRequest.getDepartmentWithUsers(
        this.paramDepartmentSort,
        this.companyCode
      )
        .then(response => {
          this.departmentWithUserList = response.data
          this.originDepartmentWithUserList = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action setDepartmentWithUserList = newList => {
    this.departmentWithUserList = newList
  }

  @action getGroupList = groupType => {
    return new Promise((resolve, reject) => {
      UserGroupRequest.getGroupList(
        0,
        1000,
        undefined,
        groupType,
        this.companyCode
      )
        .then(response => {
          const groupListCustom = response.data.data.map(group => ({
            ...group,
            users: [],
          }))
          this.originGroupList = groupListCustom
          this.groupList = groupListCustom
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action setGroupList = newList => {
    this.groupList = newList
  }
  @action getMemberGroup = id_group => {
    return new Promise((resolve, reject) => {
      UserGroupRequest.getMemberGroupByIdGroup(id_group, 0, 100)
        .then(response => {
          const resultGroupList = this.originGroupList.map(group =>
            group.id !== id_group
              ? group
              : {
                  ...group,
                  users: response.data.data,
                }
          )
          this.originGroupList = resultGroupList
          this.groupList = resultGroupList
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @observable selectUserData = {
    [ASSIGNEE_TYPE.HANDLER]: [],
    [ASSIGNEE_TYPE.COMBINER]: [],
    [ASSIGNEE_TYPE.FOLLOWER]: [],
    [ASSIGNEE_TYPE.LEADER]: [],
    [ASSIGNEE_TYPE.CREATE]: [],
    [ASSIGNEE_TYPE.USER]: [],
    [ASSIGNEE_TYPE.UNIT_LEADER]: [],
    [ASSIGNEE_TYPE.KT_KTNB]: [],
    [ASSIGNEE_TYPE.HDQT]: [],
    [ASSIGNEE_TYPE.lanh_dao_vimc]: [],
    [ASSIGNEE_TYPE.ban_kt_ktnb]: [],
    [ASSIGNEE_TYPE.ban_pc_qtrr_ban_kt_ktnb_vimc]: [],
    [ASSIGNEE_TYPE.ban_dieu_hanh_hdqt_vimc]: [],
  }
  @action setSelectUserData = payloadSelect => {
    this.selectUserData = payloadSelect
  }
  @action clearSelectUserData = permissionType => {
    if (!permissionType) {
      this.selectUserData = {
        [ASSIGNEE_TYPE.HANDLER]: [],
        [ASSIGNEE_TYPE.COMBINER]: [],
        [ASSIGNEE_TYPE.FOLLOWER]: [],
        [ASSIGNEE_TYPE.LEADER]: [],
        [ASSIGNEE_TYPE.CREATE]: [],
        [ASSIGNEE_TYPE.USER]: [],
        [ASSIGNEE_TYPE.UNIT_LEADER]: [],
        [ASSIGNEE_TYPE.KT_KTNB]: [],
        [ASSIGNEE_TYPE.HDQT]: [],
        [ASSIGNEE_TYPE.lanh_dao_vimc]: [],
        [ASSIGNEE_TYPE.ban_kt_ktnb]: [],
        [ASSIGNEE_TYPE.ban_pc_qtrr_ban_kt_ktnb_vimc]: [],
        [ASSIGNEE_TYPE.ban_dieu_hanh_hdqt_vimc]: [],
      }
    } else {
      this.selectUserData = {
        ...this.selectUserData,
        [permissionType]: [],
      }
    }
  }

  @observable selectDepartmentData = {
    [ASSIGNEE_TYPE.HANDLER]: [],
    [ASSIGNEE_TYPE.COMBINER]: [],
    [ASSIGNEE_TYPE.FOLLOWER]: [],
    [ASSIGNEE_TYPE.LEADER]: [],
    [ASSIGNEE_TYPE.CREATE]: [],
    [ASSIGNEE_TYPE.USER]: [],
    [ASSIGNEE_TYPE.UNIT_LEADER]: [],
    [ASSIGNEE_TYPE.KT_KTNB]: [],
    [ASSIGNEE_TYPE.HDQT]: [],
    [ASSIGNEE_TYPE.lanh_dao_vimc]: [],
    [ASSIGNEE_TYPE.ban_kt_ktnb]: [],
    [ASSIGNEE_TYPE.ban_pc_qtrr_ban_kt_ktnb_vimc]: [],
    [ASSIGNEE_TYPE.ban_dieu_hanh_hdqt_vimc]: [],
  }
  @action setSelectDepartmentData = payloadSelect => {
    this.selectDepartmentData = payloadSelect
  }
  @action clearSelectDepartmentData = permissionType => {
    if (!permissionType) {
      this.selectDepartmentData = {
        [ASSIGNEE_TYPE.HANDLER]: [],
        [ASSIGNEE_TYPE.COMBINER]: [],
        [ASSIGNEE_TYPE.FOLLOWER]: [],
        [ASSIGNEE_TYPE.LEADER]: [],
        [ASSIGNEE_TYPE.CREATE]: [],
        [ASSIGNEE_TYPE.USER]: [],
        [ASSIGNEE_TYPE.UNIT_LEADER]: [],
        [ASSIGNEE_TYPE.KT_KTNB]: [],
        [ASSIGNEE_TYPE.HDQT]: [],
        [ASSIGNEE_TYPE.lanh_dao_vimc]: [],
        [ASSIGNEE_TYPE.ban_kt_ktnb]: [],
        [ASSIGNEE_TYPE.ban_pc_qtrr_ban_kt_ktnb_vimc]: [],
        [ASSIGNEE_TYPE.ban_dieu_hanh_hdqt_vimc]: [],
      }
    } else {
      this.selectDepartmentData = {
        ...this.selectDepartmentData,
        [permissionType]: [],
      }
    }
  }

  @observable selectGroupData = {
    [ASSIGNEE_TYPE.HANDLER]: [],
    [ASSIGNEE_TYPE.COMBINER]: [],
    [ASSIGNEE_TYPE.FOLLOWER]: [],
    [ASSIGNEE_TYPE.LEADER]: [],
    [ASSIGNEE_TYPE.CREATE]: [],
    [ASSIGNEE_TYPE.USER]: [],
    [ASSIGNEE_TYPE.UNIT_LEADER]: [],
    [ASSIGNEE_TYPE.KT_KTNB]: [],
    [ASSIGNEE_TYPE.HDQT]: [],
    [ASSIGNEE_TYPE.lanh_dao_vimc]: [],
    [ASSIGNEE_TYPE.ban_kt_ktnb]: [],
  }
  @action setSelectGroupData = payloadSelect => {
    this.selectGroupData = payloadSelect
  }
  @action clearSelectGroupData = permissionType => {
    if (!permissionType) {
      this.selectGroupData = {
        [ASSIGNEE_TYPE.HANDLER]: [],
        [ASSIGNEE_TYPE.COMBINER]: [],
        [ASSIGNEE_TYPE.FOLLOWER]: [],
        [ASSIGNEE_TYPE.LEADER]: [],
        [ASSIGNEE_TYPE.CREATE]: [],
        [ASSIGNEE_TYPE.USER]: [],
        [ASSIGNEE_TYPE.UNIT_LEADER]: [],
        [ASSIGNEE_TYPE.KT_KTNB]: [],
        [ASSIGNEE_TYPE.HDQT]: [],
        [ASSIGNEE_TYPE.lanh_dao_vimc]: [],
        [ASSIGNEE_TYPE.ban_kt_ktnb]: [],
      }
    } else {
      this.selectGroupData = {
        ...this.selectGroupData,
        [permissionType]: [],
      }
    }
  }

  @observable isVisibleSelectUserDepartmentPopup = false
  @observable setIsVisibleSelectUserDepartmentPopup = bool => {
    this.isVisibleSelectUserDepartmentPopup = bool
  }
  @observable isVisibleSelectGroupPopup = false
  @observable setIsVisibleSelectGroupPopup = bool => {
    this.isVisibleSelectGroupPopup = bool
  }

  @action handleOpenSelectUserDepartmentPopup = popupType => {
    this.isVisibleSelectUserDepartmentPopup = true
    this.assigneePopupType = popupType
  }

  @action handleCancelSelectUserDepartment = () => {
    this.isVisibleSelectUserDepartmentPopup = false
    // this.assigneePopupType = null
  }
  @action handleRemoveSelectUserDepartment = () => {
    this.isVisibleSelectUserDepartmentPopup = false
    this.selectUserData = {
      ...this.selectUserData,
      [this.assigneePopupType]: [],
    }
    this.selectDepartmentData = {
      ...this.selectDepartmentData,
      [this.assigneePopupType]: [],
    }
  }

  @action handleOpenSelectGroupPopup = popupType => {
    this.isVisibleSelectGroupPopup = true
    this.assigneePopupType = popupType
  }
  @action handleCancelSelectGroup = () => {
    this.isVisibleSelectGroupPopup = false
    // this.selectGroupData = {
    //   ...this.selectGroupData,
    //   [this.assigneePopupType]: [],
    // }
    // this.assigneePopupType = null
  }
  @action handleRemoveSelectGroup = () => {
    this.isVisibleSelectGroupPopup = false
    this.selectGroupData = {
      ...this.selectGroupData,
      [this.assigneePopupType]: [],
    }
  }

  @action handleRemoveSelect = item => {
    if (item.assignee_type === ASSIGNEE_TYPE.USER) {
      this.selectUserData = {
        ...this.selectUserData,
        [item.permission]: this.selectUserData[item.permission].filter(
          el => el.id !== item.id
        ),
      }
    }
    if (item.assignee_type === ASSIGNEE_TYPE.DEPARTMENT) {
      this.selectDepartmentData = {
        ...this.selectDepartmentData,
        [item.permission]: this.selectDepartmentData[item.permission].filter(
          el => el.id !== item.id
        ),
      }
    }
    if (item.assignee_type === ASSIGNEE_TYPE.GROUP) {
      this.selectGroupData = {
        ...this.selectGroupData,
        [item.permission]: this.selectGroupData[item.permission].filter(
          el => el.id !== item.id
        ),
      }
    }
  }

  @action convertSelectDataList = userList => {
    if (!userList || userList.length === 0) return []
    return userList.map(assign => {
      const result = {
        id: assign.assignee_code,
        assignee_type: assign.assignee_type,
        full_name: assign.name_uppercase,
        image_id: assign.image,
        permission: assign.permission,
        department_code: undefined,
        department_name: undefined,
      }
      this.originDepartmentWithUserList.forEach(department => {
        if (
          department.users.find(user => user.user_name === assign.assignee_code)
        ) {
          result.department_code = department.code
          result.department_name = department.name
        }
      })
      return result
    })
  }

  @action convertSelectDataObj = dataList => ({
    [ASSIGNEE_TYPE.HANDLER]: this.convertSelectDataList(dataList).filter(
      el => el.permission === ASSIGNEE_TYPE.HANDLER
    ),
    [ASSIGNEE_TYPE.COMBINER]: this.convertSelectDataList(dataList).filter(
      el => el.permission === ASSIGNEE_TYPE.COMBINER
    ),
    [ASSIGNEE_TYPE.FOLLOWER]: this.convertSelectDataList(dataList).filter(
      el => el.permission === ASSIGNEE_TYPE.FOLLOWER
    ),
    [ASSIGNEE_TYPE.LEADER]: this.convertSelectDataList(dataList).filter(
      el => el.permission === ASSIGNEE_TYPE.LEADER
    ),
    [ASSIGNEE_TYPE.CREATE]: this.convertSelectDataList(dataList).filter(
      el => el.permission === ASSIGNEE_TYPE.CREATE
    ),
    [ASSIGNEE_TYPE.USER]: this.convertSelectDataList(dataList).filter(
      el => el.permission === ASSIGNEE_TYPE.USER
    ),
    [ASSIGNEE_TYPE.UNIT_LEADER]: this.convertSelectDataList(dataList).filter(
      el => el.permission === ASSIGNEE_TYPE.UNIT_LEADER
    ),
    [ASSIGNEE_TYPE.KT_KTNB]: this.convertSelectDataList(dataList).filter(
      el => el.permission === ASSIGNEE_TYPE.KT_KTNB
    ),
    [ASSIGNEE_TYPE.HDQT]: this.convertSelectDataList(dataList).filter(
      el => el.permission === ASSIGNEE_TYPE.HDQT
    ),
    [ASSIGNEE_TYPE.lanh_dao_vimc]: this.convertSelectDataList(dataList).filter(
      el => el.permission === ASSIGNEE_TYPE.lanh_dao_vimc
    ),
    [ASSIGNEE_TYPE.ban_kt_ktnb]: this.convertSelectDataList(dataList).filter(
      el => el.permission === ASSIGNEE_TYPE.ban_kt_ktnb
    ),
  })

  @action clearSelectPeopleStore = () => {
    this.departmentWithUserList.length = 0
    this.originDepartmentWithUserList.length = 0
    this.groupList.length = 0
    this.originGroupList.length = 0
    this.selectPopupName = 'Bảng chọn người dùng'
    this.assigneePopupType = null
    this.companyCode = null
    this.clearSelectUserData()
    this.clearSelectDepartmentData()
    this.clearSelectGroupData()
  }

  @action clearSelected = () => {
    this.clearSelectUserData()
    this.clearSelectDepartmentData()
    this.clearSelectGroupData()
    this.selectPopupName = 'Bảng chọn người dùng'
    this.assigneePopupType = null
  }

  @action clearSelectedByType = permissionType => {
    this.clearSelectUserData(permissionType)
    this.clearSelectDepartmentData(permissionType)
    this.clearSelectGroupData(permissionType)
  }
}

export default new SelectPeopleStore()
