import React, { useEffect, useState } from 'react'
// Component
import { DivFlex } from '../../../../components/Common/CellText'
// Style Component
import {
  LableItem,
  ModalWrapper,
  TitleModal,
} from './PopupHandleDocumentOutgoingStyled'
// Ant design
import { Button, message, Space, Tag, Tooltip } from 'antd'
import { BankOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
// Mobx
import { inject, observer } from 'mobx-react'
// Other
import { blue, yellowPrimary } from '../../../../color'
import { ASSIGNEE_TYPE } from '../../../../constants'
import SelectPeoplePopup from '../../../../components/SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../../../../components/SelectGroupPopup/SelectGroupPopup'
import { SelectList } from '../../../MissionCreatePage/MissionCreatePageStyled'
import { AvatarHasName } from '../../../../components/Common/Avatar'

const PopupHandleDocumentOutgoing = props => {
  const {
    internalDocumentStore,
    loadingAnimationStore,
    selectPeopleStore,
  } = props

  const {
    selectedInternalDocumentOutgoing,
    userAssignList,
    departmentAssignList,
    groupAssignList,
  } = internalDocumentStore
  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectGroupPopup,
    setIsVisibleSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
    originDepartmentWithUserList,
    clearSelected,
  } = selectPeopleStore

  const {
    isVisiblePopupHandleDocument,
    handleCloseHandleDocument,
    handleCancelHandleDocument,
  } = props

  const [followerList, setFollowerList] = useState([])

  useEffect(() => {
    console.log('setFollowerList')
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  useEffect(() => {
    if (!isVisiblePopupHandleDocument) return

    if (selectUserData[ASSIGNEE_TYPE.FOLLOWER].length === 0) {
      selectPeopleStore.setSelectUserData(
        selectPeopleStore.convertSelectDataObj(userAssignList)
      )
    }

    if (selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER].length === 0) {
      selectPeopleStore.setSelectDepartmentData(
        selectPeopleStore.convertSelectDataObj(departmentAssignList)
      )
    }

    if (selectGroupData[ASSIGNEE_TYPE.FOLLOWER].length === 0) {
      selectPeopleStore.setSelectGroupData(
        selectPeopleStore.convertSelectDataObj(groupAssignList)
      )
    }
  }, [
    selectedInternalDocumentOutgoing,
    isVisiblePopupHandleDocument,
    originDepartmentWithUserList,
  ])

  const handleSubmit = async () => {
    const new_items_assign = []
    const remove_items_assign = []
    const listAssignOrigin = [
      ...userAssignList,
      ...departmentAssignList,
      ...groupAssignList,
    ].map(el => ({
      assignee_code: el.assignee_code,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    const listAssignAfter = followerList.map(el => ({
      assignee_code: el.id,
      assignee_type: el.assignee_type,
      permission: el.permission,
    }))
    listAssignAfter.forEach(assignAfter => {
      if (
        !listAssignOrigin.some(
          assignOrigin =>
            assignOrigin.assignee_code + assignOrigin.permission ===
            assignAfter.assignee_code + assignAfter.permission
        )
      ) {
        new_items_assign.push({
          assignee_code: assignAfter.assignee_code,
          assignee_type: assignAfter.assignee_type,
          permission: assignAfter.permission,
        })
      }
    })
    listAssignOrigin.forEach(assignOrigin => {
      if (
        !listAssignAfter.some(
          assignAfter =>
            assignAfter.assignee_code + assignAfter.permission ===
            assignOrigin.assignee_code + assignOrigin.permission
        )
      ) {
        remove_items_assign.push({
          assignee_code: assignOrigin.assignee_code,
          assignee_type: assignOrigin.assignee_type,
          permission: assignOrigin.permission,
        })
      }
    })

    const dataSubmit = {
      new_items: new_items_assign,
      remove_items: remove_items_assign,
      start_date: null,
      end_date: null,
    }
    loadingAnimationStore.showSpinner(true)
    try {
      await internalDocumentStore.updateAssignees(
        selectedInternalDocumentOutgoing.code,
        dataSubmit
      )
      await internalDocumentStore.getInternalDocumentOutgoingById(
        selectedInternalDocumentOutgoing.code
      )
      handleCloseHandleDocument()
      message.success('Xử lý văn bản thành công!')
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  return (
    <ModalWrapper
      closable={false}
      footer={null}
      title={
        <TitleModal>
          <span className={'title-modal'}>Xử lý văn bản</span>
          <Space>
            <Button
              danger
              onClick={() => {
                handleCancelHandleDocument()
                clearSelected()
              }}>
              Hủy bỏ
            </Button>
            <Button type={'primary'} onClick={handleSubmit}>
              Đồng ý
            </Button>
          </Space>
        </TitleModal>
      }
      visible={isVisiblePopupHandleDocument}>
      <DivFlex margin={'0 0 15px'}>
        <LableItem>Chọn người theo dõi:</LableItem>
        <Space>
          <Tooltip title="Chọn người dùng" color={blue}>
            <Button
              icon={<UserOutlined style={{ color: blue }} />}
              onClick={() =>
                handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.FOLLOWER)
              }
            />
          </Tooltip>
          <Tooltip title="Chọn nhóm" color={'#ffc069'}>
            <Button
              icon={<TeamOutlined />}
              style={{ color: yellowPrimary }}
              onClick={() => handleOpenSelectGroupPopup(ASSIGNEE_TYPE.FOLLOWER)}
            />
          </Tooltip>
        </Space>
      </DivFlex>
      <SelectList>
        {followerList.map(el => (
          <Tag
            className={'tag-selected'}
            color={'blue'}
            key={el.id}
            closable
            onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
            <AvatarHasName
              imgId={el.image_id}
              size={22}
              name={el.full_name}
              icon={
                (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                  <BankOutlined />
                )) ||
                (el.assignee_type === ASSIGNEE_TYPE.GROUP && <TeamOutlined />)
              }
            />
          </Tag>
        ))}
      </SelectList>

      <SelectPeoplePopup
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(false)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />
      <SelectGroupPopup
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
      />
    </ModalWrapper>
  )
}

PopupHandleDocumentOutgoing.propTypes = {}

export default inject(
  'internalDocumentStore',
  'loadingAnimationStore',
  'selectPeopleStore'
)(observer(PopupHandleDocumentOutgoing))
