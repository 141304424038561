import { PlusOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Col, Row, Steps, Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { useState } from 'react'
import { blue, redPrimary } from '../../color'
import { ASSIGNEE_TYPE } from '../../constants'
import useOpenItem from '../../hooks/useOpenItem'
import RiskAssessmentCreatePopup from '../../pages/RiskCreatePage/RiskAssessmentCreatePopup'
import RiskControlResult from '../../pages/RiskCreatePage/RiskControlResult'
import RiskIdentificationCreatePopup from '../../pages/RiskCreatePage/RiskIdentificationCreatePopup'
import RiskWorkCreatePopup from '../../pages/RiskCreatePage/RiskWorkCreatePopup'
import RiskAction from '../RiskAction'
import RiskActionDetails from '../RiskActionDetails'
import RiskSelectUserList from '../RiskSelecUsertList/RiskSelectUserList'
import SelectPeoplePopup from '../SelectPeoplePopup'
import './RiskStepsStyle'
import { CreateSubTitle, StepsWrapper } from './RiskStepsStyle'

const { Step } = Steps
const RiskSteps = props => {
  const {
    selectPeopleStore,
    createUser,
    selectUserList,
    current,
    onChangeSteps,
    updateTierApproval,
    actionStore,
    getRiskDetail,
  } = props
  const { handleOpenSelectUserDepartmentPopup } = selectPeopleStore
  const { dataActions } = actionStore

  const {
    isOpen: isVisibleSelectUserPopup,
    handleOpen: handleVisibleSelectUserPopup,
    handleClose: handleCloseSelectUserPopup,
  } = useOpenItem()
  const {
    isOpen: isVisibleCreateRiskId,
    handleOpen: handleVisibleCreateRiskId,
    handleClose: handleCloseCreateRiskId,
  } = useOpenItem()
  const {
    isOpen: isVisibleCreateRiskAssessment,
    handleOpen: handleVisibleCreateRiskAssessment,
    handleClose: handleCloseCreateRiskAssessment,
  } = useOpenItem()
  const {
    isOpen: isVisibleCreateRiskWork,
    handleOpen: handleVisibleCreateRiskWork,
    handleClose: handleCloseCreateRiskWork,
  } = useOpenItem()
  const {
    isOpen: isVisibleRiskControlResult,
    handleOpen: handleVisibleRiskControlResult,
    handleClose: handleCloseRiskControlResult,
  } = useOpenItem()
  const {
    isOpen: isVisibleSelectOneUserPopup,
    handleOpen: handleVisibleSelectOneUserPopup,
    handleClose: handleCloseSelectOneUserPopup,
  } = useOpenItem()
  const SelectUserItem = ({ title, handleSelectUser, userList }) => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            gap: 8,
            alignItems: 'center',
            marginBottom: 8,
            marginTop: -8,
          }}>
          <div>
            <span style={{ color: redPrimary }}>* </span>
            {title}
          </div>
          <Tooltip title="Chọn người dùng" color={blue}>
            <Button
              icon={<UserOutlined style={{ color: blue }} />}
              onClick={handleSelectUser}
            />
          </Tooltip>
        </div>
        <RiskSelectUserList userList={userList} />
      </>
    )
  }

  const handleSelectUser = assigneeType => {
    handleVisibleSelectUserPopup()
    handleOpenSelectUserDepartmentPopup(assigneeType)
  }
  return (
    <>
      <StepsWrapper>
        <Steps onChange={onChangeSteps} current={current}>
          <Step />
          <Step />
          <Step />
          <Step />
        </Steps>
      </StepsWrapper>
      {current === 0 ? (
        <>
          <CreateSubTitle>Tuyến phòng vệ thứ nhất</CreateSubTitle>
          <Row type={'flex'} gutter={15}>
            <Col span={8}>
              <div style={{ marginBottom: 8, fontWeight: 600 }}>
                Chuyên viên thực hiện:
              </div>
              <RiskSelectUserList userList={[createUser]} />
            </Col>
            <Col span={8}>
              <SelectUserItem
                title="Lãnh đạo đơn vị"
                handleSelectUser={() =>
                  handleSelectUser(ASSIGNEE_TYPE.UNIT_LEADER)
                }
                userList={selectUserList.unitLeader}
              />
            </Col>
          </Row>
          <RiskActionDetails
            data={dataActions?.tier1}
            tier={'1'}
            openEditPopUp={handleVisibleCreateRiskId}
          />
        </>
      ) : current === 1 ? (
        <>
          {' '}
          <CreateSubTitle>Tuyến phòng vệ thứ hai</CreateSubTitle>
          <Row type={'flex'} gutter={15}>
            <Col span={8}>
              <div style={{ marginBottom: 8, fontWeight: 600 }}>
                Đơn vị thực hiện xử lý rủi ro:
              </div>
              <RiskSelectUserList userList={[createUser]} />
            </Col>
            <Col span={8}>
              <SelectUserItem
                title="Lãnh đạo VIMC"
                handleSelectUser={() => handleSelectUser(ASSIGNEE_TYPE.LEADER)}
                userList={selectUserList.VIMCLeader}
              />
            </Col>
          </Row>
          <RiskActionDetails
            data={dataActions?.tier2}
            tier={'2'}
            openEditPopUp={handleVisibleCreateRiskAssessment}
          />
        </>
      ) : current === 2 ? (
        <>
          {' '}
          <CreateSubTitle>Tuyến phòng vệ thứ ba</CreateSubTitle>
          <Row type={'flex'} gutter={15}>
            <Col span={8}>
              <div style={{ marginBottom: 8, fontWeight: 600 }}>
                Đơn vị thực hiện xử lý rủi ro:
              </div>
              <RiskSelectUserList userList={[createUser]} />
            </Col>
            <Col span={8}>
              <SelectUserItem
                title="BAN KT-KTNB VIMC"
                handleSelectUser={() => handleSelectUser(ASSIGNEE_TYPE.KT_KTNB)}
                userList={selectUserList.KT_KTNB}
              />
            </Col>
            <Col span={8}>
              <SelectUserItem
                title="HDQT VIMC"
                handleSelectUser={() => handleSelectUser(ASSIGNEE_TYPE.HDQT)}
                userList={selectUserList.HDQT}
              />
            </Col>
          </Row>
          <RiskActionDetails
            data={dataActions?.tier3}
            tier={'3'}
            openEditPopUp={handleVisibleCreateRiskWork}
          />
        </>
      ) : (
        <>
          {' '}
          <CreateSubTitle>Tuyến phòng vệ thứ tư</CreateSubTitle>
          <RiskActionDetails
            data={dataActions?.tier4}
            tier={'4'}
            openEditPopUp={handleVisibleRiskControlResult}
          />
        </>
      )}
      {updateTierApproval ? (
        <SelectPeoplePopup
          disableSelectDepartment
          handleCancelSelectUser={() => handleCloseSelectUserPopup()}
          handleUpdateEmpty={() => updateTierApproval('empty')}
          handleSubmitSelectUser={() => {
            updateTierApproval()
            handleCloseSelectUserPopup()
          }}
          isVisibleSelectPeoplePopup={isVisibleSelectUserPopup}
        />
      ) : (
        <SelectPeoplePopup
          disableSelectDepartment
          handleCancelSelectUser={() => handleCloseSelectUserPopup()}
          handleSubmitSelectUser={() => handleCloseSelectUserPopup()}
          isVisibleSelectPeoplePopup={isVisibleSelectUserPopup}
        />
      )}
      <RiskIdentificationCreatePopup
        handleCancel={handleCloseCreateRiskId}
        isVisible={isVisibleCreateRiskId}
        getRiskDetail={getRiskDetail}
        selectUserList={selectUserList}
        handleVisibleSelectOneUserPopup={handleVisibleSelectOneUserPopup}
      />
      <RiskAssessmentCreatePopup
        handleCancel={handleCloseCreateRiskAssessment}
        isVisible={isVisibleCreateRiskAssessment}
        getRiskDetail={getRiskDetail}
      />
      <RiskWorkCreatePopup
        handleCancel={handleCloseCreateRiskWork}
        isVisible={isVisibleCreateRiskWork}
        getRiskDetail={getRiskDetail}
        selectUserList={selectUserList}
        handleVisibleSelectOneUserPopup={handleVisibleSelectOneUserPopup}
      />
      <RiskControlResult
        handleCancel={handleCloseRiskControlResult}
        isVisible={isVisibleRiskControlResult}
        getRiskDetail={getRiskDetail}
        selectUserList={selectUserList}
        handleVisibleSelectOneUserPopup={handleVisibleSelectOneUserPopup}
      />
      <SelectPeoplePopup
        onlyOneUser
        isUserDisableList={false}
        handleCancelSelectUser={() => handleCloseSelectOneUserPopup()}
        handleSubmitSelectUser={() => handleCloseSelectOneUserPopup()}
        isVisibleSelectPeoplePopup={isVisibleSelectOneUserPopup}
      />
    </>
  )
}

export default inject('selectPeopleStore', 'actionStore')(observer(RiskSteps))
