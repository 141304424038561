import { action, observable } from 'mobx'
import { InternalMessagesRequest } from '../requests/InternalMessagesRequest'

import { ASSIGNEE_TYPE } from '../constants'

class InternalMessageStore {
  @observable internalMessageList = []
  @observable internalMessageDetail = {}
  @observable internalMessageListTotalCount = 0
  @observable unreadInternalMessageCount = 0
  @observable replyInternalMessages = []
  @observable pageSize = 30
  @observable pageIndex = 0
  @observable keyword = ''
  @observable queryParams = {
    page: 0,
    size: 10,
  }

  @action setQueryParams = queryParams => {
    this.queryParams = queryParams
  }

  @action setFilter = (filterName, filterValue) => {
    if (typeof filterName !== 'string') return
    this[filterName] = filterValue
  }
  @action getInternalMessageReceiveList = queryStringParse => {
    return new Promise((resolve, reject) => {
      InternalMessagesRequest.getReceiveList(
        queryStringParse.page,
        queryStringParse.size,
        queryStringParse.keyword
      )
        .then(response => {
          this.internalMessageList = response.data?.data
          this.internalMessageList?.map(item => {
            const receiverList =
              item.receiver?.map((el, index) => ({
                id: index,
                full_name: el.fullname,
                assignee_type: ASSIGNEE_TYPE.USER,
                last_date_read: el.updatedTime,
              })) ?? []
            item.receiverList = receiverList
            return item
          })
          this.internalMessageListTotalCount = response.data.total_elements
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getInternalMessageSendList = queryStringParse => {
    return new Promise((resolve, reject) => {
      InternalMessagesRequest.getSendList(
        queryStringParse.page,
        queryStringParse.size,
        queryStringParse.keyword
      )
        .then(response => {
          this.internalMessageList = response.data.data
          this.internalMessageList.map(item => {
            const receiverList =
              item.receiver?.map((el, index) => ({
                id: index,
                full_name: el.fullname,
                assignee_type: ASSIGNEE_TYPE.USER,
                last_date_read: el.updatedTime,
              })) ?? []
            item.receiverList = receiverList
            return item
          })
          this.internalMessageListTotalCount = response.data.totalElements
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getReceiveMessageById = id => {
    return new Promise((resolve, reject) => {
      InternalMessagesRequest.getReceiveMessageById(id)
        .then(response => {
          this.internalMessageDetail = response.data
          if (response.data.reply_internal_messages?.length > 0) {
            this.replyInternalMessages = response.data.reply_internal_messages.sort(
              (a, b) => (a.created_time > b.created_time ? 1 : -1)
            )
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getSendMessageById = id => {
    return new Promise((resolve, reject) => {
      InternalMessagesRequest.getSendMessageById(id)
        .then(response => {
          this.internalMessageDetail = response.data
          if (response.data.reply_internal_messages?.length > 0) {
            this.replyInternalMessages = response.data.reply_internal_messages.sort(
              (a, b) => (a.created_time > b.created_time ? 1 : -1)
            )
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action create = data => {
    return new Promise((resolve, reject) => {
      InternalMessagesRequest.create(data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action getInternalMessageUserCodeByInternalMessageCode = id => {
    return new Promise((resolve, reject) => {
      InternalMessagesRequest.getInternalMessageUserCodeByInternalMessageCode(
        id
      )
        .then(response => {
          resolve(response.data.internal_message_user_code)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /** Clear */
  @action clearMessageStore = () => {
    this.internalMessageList.length = 0
    this.internalMessageListTotalCount = 0
    this.replyInternalMessages = []
    this.unreadInternalMessageCount = 0
    this.internalMessageDetail = {}
    this.pageIndex = 0
    this.pageSize = 30
  }

  @action clearInternalMessageList = () => {
    this.internalMessageList = []
  }

  @action clearQueryParams = () => {
    this.queryParams = {
      page: 0,
      size: 10,
    }
  }
}

export default new InternalMessageStore()
