import { Tag } from 'antd'
import styled from 'styled-components'
export const RiskSelectList = styled.div`
   display: flex;
  gap:18px 0px;
  flex-wrap: wrap;
  padding-bottom: 16px;
`
export const UserTag = styled(Tag)`
  display: inline-block;
    font-size: 12px;
    border: 1px solid #91d5ff;
    border-radius: 4px;
    padding: 2px 10px;
    max-width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    margin-bottom: 4px;
    white-space: nowrap;
    color: #1890ff;
    background: #e6f7ff;
`
