import styled from 'styled-components'
import { blue } from '../../color'

export const StepsWrapper = styled.div`
  margin: 56px auto;
  max-width: 40%;
  .ant-steps-item-wait .ant-steps-item-icon {
    border-color: ${blue};
    color: ${blue};
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: ${blue};
}
`
export const CreateSubTitle = styled.div`
  padding: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 32px;
  margin-top: 10;
  text-align: center;
`

