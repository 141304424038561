import { inject, observer } from 'mobx-react'
import RichText from '../../../components/RichEditor/RichText'
import { TASK_TYPE_fromKey } from '../../../constants'
import date_format from '../../../date_format'
import {
  action_title,
  assign_type,
  field_history_log,
  work_important,
  work_status,
  work_type,
} from '../../../enum_utils'
import utils from '../../../utils'

const {
  default: _,
  isEqual,
  isEmpty,
  difference,
  isNull,
  isNil,
  isUndefined,
} = require('lodash')

const compareObj = (obj1, obj2) => {
  let oldValues = {}
  let newValues = {}
  Object.keys(obj1).forEach(key => {
    if (obj1[key] !== obj2[key]) {
      oldValues[key] = obj1[key]
      newValues[key] = obj2[key]
    }
  })
  return { oldValues, newValues }
}
const displayActions = actions =>
  Object.keys(actions)
    .map(key =>
      key === 'p' || key === 's'
        ? `<div><span><b>${action_title.toText(key)}:</b></span><span>&nbsp;${
            actions[key]
          }</span></div>`
        : `<div><b>${action_title.toText(key)}:</b></div><div>${
            actions[key]
          }</div>`
    )
    .join('')

export const compare = (type, dataBefore, dataAfter, riskDetail = {}) => {
  let diff = []
  if (!dataAfter) {
    return []
  }

  //find & match keys between before & after
  let keyBefore = Object.keys(dataBefore)
  let keyAfter = Object.keys(dataAfter)
  let diffKeys = difference(keyAfter, keyBefore)
  diffKeys.forEach(newKey => {
    dataBefore[newKey] = null
  })
  console.log('riskDetail', riskDetail)
  const handleTask = () => {
    for (const [keyBefore, valueBefore] of Object.entries(dataBefore)) {
      let valueAfter = dataAfter[keyBefore]

      const pushDiff = (before, after) => {
        diff.push({
          field: field_history_log.toText(keyBefore, type),
          fieldValueBefore: before ?? '',
          fieldValueAfter: after ?? '',
        })
      }

      if (!isUndefined(valueBefore) && !isUndefined(valueAfter)) {
        switch (keyBefore) {
          case 'data':
          case 'noiDungKetQua':
          case 'kienNghi':
          case 'phanTramHoanThanh':
          case 'ghiChu':
          case 'idTaskLevel':
            if (valueBefore !== valueAfter) pushDiff(valueBefore, valueAfter)
            break
          case 'ngayBatDau':
          case 'ngayKetThuc':
          case 'createdAt':
          case 'updatedAt':
            let strBefore =
              (valueBefore && date_format.DEFAULT_DATE(valueBefore)) ??
              'Không có'
            let strAfter =
              (valueAfter && date_format.DEFAULT_DATE(valueAfter)) ?? 'Không có'

            if (!isEqual(strBefore, strAfter)) pushDiff(strBefore, strAfter)

            break
          case 'loaiNhiemVu':
            if (valueBefore !== valueAfter) {
              pushDiff(
                TASK_TYPE_fromKey(valueBefore)?.value ?? 'Không có',
                TASK_TYPE_fromKey(valueAfter)?.value ?? 'Không có'
              )
            }
            break
          case 'displayDate':
            let monthBefore = date_format.MMMM_YYYY(valueBefore)
            let monthAfter = date_format.MMMM_YYYY(valueAfter)
            if (!isEqual(monthBefore, monthAfter))
              pushDiff(monthBefore, monthAfter)
            break
        }
      } else {
        switch (keyBefore) {
          case 'ngayKetThuc':
            let strBefore =
              (valueBefore && date_format.DEFAULT_DATE(valueBefore)) ??
              'Không có'
            let strAfter =
              (valueAfter && date_format.DEFAULT_DATE(valueAfter)) ?? 'Không có'
            pushDiff(strBefore, strAfter)
            break
        }
      }
    }

    //assignees
    let removePersons = (dataAfter?.remove_persons ?? [])
      .map(
        item =>
          `<p><s style='color:red'><b>- ${
            item.nameUppercase
          }</b>: ${assign_type.enumToText(item.permission)}</s></p>`
      )
      .join('')
    //
    let addPersons = (dataAfter?.add_persons ?? [])
      .map(
        item =>
          `<p style='color:blue'><b>+ ${
            item.nameUppercase
          }</b>: ${assign_type.enumToText(item.permission)}</p>`
      )
      .join('')
    //
    if (!isEmpty(removePersons) || !isEmpty(addPersons)) {
      diff.push({
        field: field_history_log.toText('assignees', type),
        fieldValueBefore: (dataBefore?.old_persons ?? [])
          .map(
            item =>
              `<p><b>• ${item.nameUppercase}</b>: ${assign_type.enumToText(
                item.permission
              )}</p>`
          )
          .join(''),
        fieldValueAfter: addPersons.concat(removePersons),
      })
    }

    //documents
    let removeDocuments = (dataAfter?.remove_van_ban_lq ?? [])
      .map(
        item =>
          `<p><s style='color:red'><b>-</b> ${item.documentNumber} - ${item.title}</s></p>`
      )
      .join('')
    let addDocuments = (dataAfter?.add_van_ban_lq ?? [])
      .map(
        item =>
          `<p style='color:blue'><b>+</b> ${item.documentNumber} - ${item.title}</p>`
      )
      .join('')
    if (!isEmpty(removeDocuments) || !isEmpty(addDocuments)) {
      diff.push({
        field: field_history_log.toText('documents', type),
        fieldValueBefore: (dataBefore?.old_van_ban_lq ?? [])
          .map(item => `<p><b>•</b> ${item.documentNumber} - ${item.title}</p>`)
          .join(''),
        fieldValueAfter: addDocuments.concat(removeDocuments),
      })
    }

    //files vb
    let removeDocumentFiles = (dataAfter?.remove_file_vb_dinh_kem ?? [])
      .map(item => `<p><s style='color:red'><b>-</b> ${item.fileName}</s></p>`)
      .join('')
    let addDocumentFiles = (dataAfter?.add_file_vb_dinh_kem ?? [])
      .map(item => `<p style='color:blue'><b>+</b> ${item.fileName}</p>`)
      .join('')
    if (!isEmpty(removeDocumentFiles) || !isEmpty(addDocumentFiles)) {
      diff.push({
        field: field_history_log.toText('documentFiles', type),
        fieldValueBefore: (dataBefore?.old_file_vb_dinh_kem ?? [])
          .map(item => `<p><b>•</b> ${item.fileName}</p>`)
          .join(''),
        fieldValueAfter: addDocumentFiles.concat(removeDocumentFiles),
      })
    }
    //files
    let removeFiles = (dataAfter?.remove_files ?? [])
      .map(item => `<p><s style='color:red'><b>-</b> ${item.fileName}</s></p>`)
      .join('')
    let addFiles = (dataAfter?.add_files ?? [])
      .map(item => `<p style='color:blue'><b>+</b> ${item.fileName}</p>`)
      .join('')
    if (!isEmpty(removeFiles) || !isEmpty(addFiles)) {
      diff.push({
        field: field_history_log.toText('files', type),
        fieldValueBefore: (dataBefore?.old_files ?? [])
          .map(item => `<p><b>•</b> ${item.fileName}</p>`)
          .join(''),
        fieldValueAfter: addFiles.concat(removeFiles),
      })
    }

    //works
    if (dataAfter?.works) {
      diff.push({
        field: field_history_log.toText('works', type),
        fieldValueBefore: (dataBefore?.works ?? [])
          .map(item => `<p><b>•</b> ${item.title}</p>`)
          .join(''),
        fieldValueAfter: (dataAfter?.works ?? [])
          .map(item => `<p style='color:blue'><b>+</b> ${item.title}</p>`)
          .join(''),
      })
    }
  }

  const handleWork = () => {
    for (const [keyBefore, valueBefore] of Object.entries(dataBefore)) {
      let valueAfter = dataAfter[keyBefore]

      const pushDiff = (before, after) => {
        diff.push({
          field: field_history_log.toText(keyBefore, type),
          fieldValueBefore: before ?? '',
          fieldValueAfter: after ?? '',
        })
      }

      if (!isUndefined(valueBefore) && !isUndefined(valueAfter)) {
        switch (keyBefore) {
          case 'title':
          case 'content':
            if (valueBefore !== valueAfter) {
              pushDiff(valueBefore, valueAfter)
            }
            break
          case 'startDate':
          case 'dueDate':
          case 'createdAt':
            let strBefore =
              (valueBefore && date_format.DEFAULT_DATE(valueBefore)) ??
              'Không có'
            let strAfter =
              (valueAfter && date_format.DEFAULT_DATE(valueAfter)) ?? 'Không có'

            if (!isEqual(strBefore, strAfter)) pushDiff(strBefore, strAfter)

            break
          case 'status':
            if (valueBefore !== valueAfter) {
              pushDiff(
                work_status.toText(valueBefore),
                work_status.toText(valueAfter)
              )
            }
            break
          case 'important':
            if (valueBefore !== valueAfter)
              pushDiff(
                work_important.toText(valueBefore),
                work_important.toText(valueAfter)
              )
            break
          case 'workType':
            if (valueBefore !== valueAfter) {
              pushDiff(
                work_type.toText(valueBefore),
                work_type.toText(valueAfter)
              )
            }
            break
        }
      } else {
        switch (keyBefore) {
          case 'dueDate':
            let strBefore =
              (valueBefore && date_format.DEFAULT_DATE(valueBefore)) ??
              'Không có'
            let strAfter =
              (valueAfter && date_format.DEFAULT_DATE(valueAfter)) ?? 'Không có'
            pushDiff(strBefore, strAfter)
            break
        }
      }
    }

    //assignees
    let removePersons = (dataAfter?.remove_persons ?? [])
      .map(
        item =>
          `<p><s style='color:red'><b>- ${
            item.nameUppercase
          }</b>: ${assign_type.enumToText(item.permission)}</s></p>`
      )
      .join('')
    //
    let addPersons = (dataAfter?.add_persons ?? [])
      .map(
        item =>
          `<p style='color:blue'><b>+ ${
            item.nameUppercase
          }</b>: ${assign_type.enumToText(item.permission)}</p>`
      )
      .join('')
    //
    if (!isEmpty(removePersons) || !isEmpty(addPersons)) {
      diff.push({
        field: field_history_log.toText('assignees', type),
        fieldValueBefore: (dataBefore?.old_persons ?? [])
          .map(
            item =>
              `<p><b>• ${item.nameUppercase}</b>: ${assign_type.enumToText(
                item.permission
              )}</p>`
          )
          .join(''),
        fieldValueAfter: addPersons.concat(removePersons),
      })
    }

    //files
    let removeFiles = (dataAfter?.remove_files ?? [])
      .map(item => `<p><s style='color:red'><b>-</b> ${item.fileName}</s></p>`)
      .join('')
    let addFiles = (dataAfter?.add_files ?? [])
      .map(item => `<p style='color:blue'><b>+</b> ${item.fileName}</p>`)
      .join('')
    if (!isEmpty(removeFiles) || !isEmpty(addFiles)) {
      diff.push({
        field: field_history_log.toText('files', type),
        fieldValueBefore: (dataBefore?.old_files ?? [])

          .map(item => `<p><b>•</b> ${item.fileName}</p>`)
          .join(''),
        fieldValueAfter: addFiles.concat(removeFiles),
      })
    }

    //files vb
    let remove_file_vb_dinh_kem = (dataAfter?.remove_file_vb_dinh_kem ?? [])
      .map(item => `<p><s style='color:red'><b>-</b> ${item.fileName}</s></p>`)
      .join('')
    let add_file_vb_dinh_kem = (dataAfter?.add_file_vb_dinh_kem ?? [])
      .map(item => `<p style='color:blue'><b>+</b> ${item.fileName}</p>`)
      .join('')
    if (!isEmpty(remove_file_vb_dinh_kem) || !isEmpty(add_file_vb_dinh_kem)) {
      diff.push({
        field: field_history_log.toText('documentFiles', type),
        fieldValueBefore: (dataBefore?.old_file_vb_dinh_kem ?? [])
          .map(item => `<p><b>•</b> ${item.fileName}</p>`)
          .join(''),
        fieldValueAfter: addFiles.concat(remove_file_vb_dinh_kem),
      })
    }
  }
  const handlRisk = () => {
    for (const [keyBefore, valueBefore] of Object.entries(dataBefore)) {
      let valueAfter = dataAfter[keyBefore]

      const pushDiff = (before, after) => {
        diff.push({
          field: field_history_log.toText(keyBefore, type),
          fieldValueBefore: before ?? '',
          fieldValueAfter: after ?? '',
        })
      }

      if (!isUndefined(valueBefore) && !isUndefined(valueAfter)) {
        switch (keyBefore) {
          case 'content':
            if (valueBefore !== valueAfter) pushDiff(valueBefore, valueAfter)
            break
          case 'title':
            if (valueBefore !== valueAfter) pushDiff(valueBefore, valueAfter)
            break
          case 'riskUnitCode':
            if (valueBefore !== valueAfter)
              pushDiff(
                dataBefore?.old_unit?.value?.name,
                dataAfter?.new_unit?.value?.name
              )
            break
          case 'status':
            const level = riskDetail?.tiers?.find(tier =>
              tier?.approvals?.some(
                approval => approval.code === dataBefore.riskTierApprovalCode
              )
            )?.level
            console.log('result', level)
            if (valueBefore !== valueAfter)
              pushDiff(
                `<div>Tuyến phòng vệ thứ ${level}</div>${
                  dataBefore?.status === 'APPROVED'
                    ? "<div style='color:green'><b>Đã duyệt</b></div>"
                    : dataBefore?.status === 'PENDING'
                    ? "<div style='color:orange'><b>Chờ duyệt</b></div>"
                    : "<div style='color:red'><b>Từ chối</b></div>"
                }`,
                dataAfter?.status === 'APPROVED'
                  ? "<div style='color:green'><b>Đã duyệt</b></div>"
                  : dataBefore?.status === 'PENDING'
                  ? "<div style='color:orange'><b>Chờ duyệt</b></div>"
                  : "<div style='color:red'><b>Từ chối</b></div>"
              )
            break
          case 'level':
            pushDiff(
              `<div style='margin-bottom:10px'>${
                dataAfter.name
              }</div>${dataBefore.old_persons
                .map(
                  item =>
                    `<p><b>•&nbsp;${utils.getNameInCapitalize(
                      item.nameUppercase
                    )}</b></p>`
                )
                .join('')}`,
              `${
                dataAfter?.add_persons?.length > 0
                  ? (dataAfter?.add_persons ?? [])
                      .map(
                        item =>
                          `<p style='color:blue'><b>+ ${utils.getNameInCapitalize(
                            item.nameUppercase
                          )}</b></p>`
                      )
                      .join('')
                  : ''
              }${
                dataAfter?.remove_persons?.length > 0
                  ? (dataAfter?.remove_persons ?? [])
                      .map(
                        item =>
                          `<p><s style='color:red'><b>- ${utils.getNameInCapitalize(
                            item.nameUppercase
                          )}</b></s></p>`
                      )
                      .join('')
                  : ''
              }`
            )
            break
          case 'data':
            const formattedDataAfter = JSON.parse(dataAfter?.data)
            const formattedDataBefore = JSON.parse(dataBefore?.data)
            pushDiff(
              dataAfter.isDeleted
                ? displayActions(formattedDataBefore)
                : !formattedDataBefore
                ? ''
                : `<p style='margin-bottom:10px; color:blue'><b>${
                    dataAfter?.name
                  }</b></p>${displayActions(
                    compareObj(formattedDataBefore, formattedDataAfter)
                      .oldValues
                  )}`,
              dataAfter.isDeleted
                ? `<p style='margin-bottom:10px; color:red'><b>- ${dataAfter?.name}</b></p>`
                : !formattedDataBefore
                ? `<p style='margin-bottom:10px; color:blue'><b>+${
                    dataAfter?.name
                  }</b></p>${displayActions(formattedDataAfter)}`
                : `<p style='margin-bottom:10px; color:blue'><b>${
                    dataAfter?.name
                  }</b></p>${displayActions(
                    compareObj(formattedDataBefore, formattedDataAfter)
                      .newValues
                  )}`
            )
            break
        }
      }
    }
  }

  //find diff
  switch (type) {
    case 'TASK':
      handleTask()
      break
    case 'WORK':
      handleWork()
      break
    case 'RISK':
      handlRisk()
      break
  }

  return diff
}
