import React from 'react'
import { RiskHorizontalDisplay } from '../../components/Common'
import RiskActionDetails from '../../components/RiskActionDetails'
const RiskPdf2 = props => {
  const { dataActions } = props

  return (
    <div id={'riskDivToPrint'} style={{ fontSize: '10px !important ' }}>
      <RiskHorizontalDisplay
        labels={[
          {
            image: (
              <img
                id="logo"
                style={{ width: '250px' }}
                src={`${window.location.protocol}//${window.location.host}/assets/photos/logo_vimc.png`}
              />
            ),
            style: { margin: '10px 10px', border: '1px solid black' },
          },
          {
            value: (
              <div style={{ textAlign: 'center' }}>
                <h2>Phiếu kiểm soát rủi ro</h2>
                <div>Số:....... Ngày..../..../20....</div>
              </div>
            ),
            style: { margin: '10px 54px', border: '1px solid black' },
          },
        ]}
        tableStyle={{ margin: '0 0 20px 0', width: '100%' }}
      />
      <RiskHorizontalDisplay
        results={[
          {
            value: (
              <>
                {' '}
                <h5>A. Tuyến phòng vệ thứ nhất</h5>
                <RiskActionDetails
                  data={dataActions?.tier1}
                  tier={'1'}
                  isPartOfPdf
                />
              </>
            ),
            style: {
              textAlign: 'flex',
              border: '1px solid black',
              margin: '0 10px',
            },
          },
        ]}
        tableStyle={{ margin: 0 }}
      />
      <RiskHorizontalDisplay
        results={[
          {
            value: (
              <>
                <h5 style={{ marginTop: 16 }}>B. Tuyến phòng vệ thứ hai</h5>
                <RiskActionDetails
                  data={dataActions?.tier2}
                  tier={'2'}
                  isPartOfPdf
                />
              </>
            ),
            style: {
              textAlign: 'flex',
              border: '1px solid black',
              margin: '0 10px',
            },
          },
        ]}
        tableStyle={{ margin: 0 }}
      />
      <RiskHorizontalDisplay
        results={[
          {
            value: (
              <>
                <h5 style={{ marginTop: 16 }}>C. Tuyến phòng vệ thứ ba</h5>
                <RiskActionDetails
                  data={dataActions?.tier3}
                  tier={'3'}
                  isPartOfPdf
                />
              </>
            ),
            style: {
              textAlign: 'flex',
              border: '1px solid black',
              margin: '0 10px',
            },
          },
        ]}
        tableStyle={{ margin: 0 }}
      />
      <RiskHorizontalDisplay
        results={[
          {
            value: (
              <>
                <RiskActionDetails
                  data={dataActions?.tier4}
                  tier={'4'}
                  isPartOfPdf
                />
              </>
            ),
            style: {
              textAlign: 'flex',
              border: '1px solid black',
              margin: '0 10px',
            },
          },
        ]}
        tableStyle={{ margin: 0 }}
      />
    </div>
  )
}
RiskPdf2.propTypes = {}

export default RiskPdf2
