import { Button, Empty } from 'antd'
import { inject, observer } from 'mobx-react'
import React from 'react'
import TableComponent from '../Common/TableComponent'
import RichText from '../RichEditor/RichText'

const RiskAction = ({ data, tier, openEditPopUp = () => {}, actionStore }) => {
  const { setIndexDetailAction } = actionStore
  const columns1 = [
    {
      title: 'Rủi ro nhận diện',
      dataIndex: 'rui_ro_nhan_dien',
      width: 150,
      render: text => <p style={{ marginTop: 0 }}>{text}</p>,
    },
    {
      title: 'Ban CMNV/DN HTPT',
      dataIndex: 'ban_cmnvdn_htpt',
      width: 150,
      render: text => <p style={{ marginTop: 0 }}>{text}</p>,
    },
    {
      title: 'Lĩnh vực/Hoạt động',
      dataIndex: 'linh_vuc_hoat_dong',
      width: 150,
      render: text => <p style={{ marginTop: 0 }}>{text}</p>,
    },
    {
      title: 'Nguyên nhân',
      dataIndex: 'nguyen_nhan',
      render: text => <RichText htmlText={text}></RichText>,
      width: 250,
    },
    {
      title: 'Biện pháp phòng ngừa, giảm thiểu',
      dataIndex: 'bien_phap_phong_ngua',
      render: text => <RichText htmlText={text}></RichText>,
    },
  ]
  const columns2 = [
    {
      title: 'Rủi ro đã nhận diện',
      dataIndex: 'rui_ro_da_nhan_dien',
      width: 150,
      render: text => <p style={{ marginTop: 0 }}>{text}</p>,
    },
    {
      title: 'Đánh giá mức rủi ro R=PxS',
      render: (text, record) => (
        <p style={{ marginTop: 0 }}>{`${
          parseInt(record.s) * parseInt(record.p) || 0
        } (R)`}</p>
      ),
      width: 180,
    },
    {
      title: 'Biện pháp xử lý/ Giải pháp giảm thiểu',
      dataIndex: 'bien_phap_xu_ly_giam_thieu',
      render: text => <RichText htmlText={text}></RichText>,
    },
  ]
  const columns3 = [
    {
      title: 'Công việc thực hiện',
      dataIndex: 'cong_viec_thuc_hien',
      width: 150,
      render: text => <p style={{ marginTop: 0 }}>{text}</p>,
    },
    {
      title: 'Lộ trình thực hiện',
      dataIndex: 'lo_trinh_thuc_hien',
      render: text => <RichText htmlText={text}></RichText>,
      width: 150,
    },
    {
      title: 'Nhận diện rủi ro đối với công việc thực hiện',
      dataIndex: 'nhan_dien_rui_ro_doi_voi_cong_viec_thuc_hien',
      render: text => <RichText htmlText={text}></RichText>,
      width: 270,
    },
    {
      title: 'Đánh giá mức rủi ro R=PxS',
      render: (text, record) => (
        <p style={{ marginTop: 0 }}>{`${
          parseInt(record.s) * parseInt(record.p) || 0
        } (R)`}</p>
      ),
      width: 180,
    },
    {
      title: 'Biện pháp xử lý/ Giải pháp giảm thiểu',
      dataIndex: 'bien_phap_xu_ly_giam_thieu',
      render: text => <RichText htmlText={text}></RichText>,
    },
  ]
  return (
    <>
      <TableComponent
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              setIndexDetailAction({ tier, index: rowIndex })
              openEditPopUp()
            },
          }
        }}
        columns={tier === '1' ? columns1 : tier === '2' ? columns2 : columns3}
        dataSource={data}
        size="small"
        // columns={tableColumns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
        // loading={loadingAnimationStore.tableLoading}
      />

      <Button
        style={{ marginTop: 16 }}
        onClick={() => {
          if (data.length > 0) {
            setIndexDetailAction({ tier, index: 0 })
          }
          openEditPopUp()
        }}>
        Sửa
      </Button>
    </>
  )
}

export default inject('actionStore')(observer(RiskAction))
